import {useCallback, useEffect, useMemo, useState} from "react";
import {Modal, Button, Form, Space, InputNumber, Input, Tabs, notification} from "antd";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getProduct, updateProduct} from "./api.js";
import ProductSizesTable from "./ProductSizesTable.jsx";

const tabLabels = {
    ru: 'Русский',
    be: 'Белорусский',
    en: 'Английский'
};

const SharedForm = ({ product, lang }) => {
    return (
        <>
            <Form.Item label="Название" name={[lang, "name"]} initialValue={product?.name}>
                <Input />
            </Form.Item>
            <Form.Item label="Назначение" name={[lang, "purpose"]} initialValue={product?.purpose}>
                <Input.TextArea autoSize />
            </Form.Item>
            <Form.Item label="Конструкция" name={[lang, "construction"]} initialValue={product?.construction}>
                <Input.TextArea autoSize />
            </Form.Item>
            <Form.Item label="Тех. характеристики" name={[lang, "exploitation"]} initialValue={product?.exploitation}>
                <Input.TextArea autoSize />
            </Form.Item>
            <Form.Item label="Условия экспутации" name={[lang, "specification"]} initialValue={product?.specification}>
                <Input.TextArea autoSize />
            </Form.Item>
            <Form.Item label="Изоляция" name={[lang, "insulation"]} initialValue={product?.insulation}>
                <Input.TextArea autoSize />
            </Form.Item>
            <Form.Item label="Оболочка кабеля" name={[lang, "cableSheath"]} initialValue={product?.cableSheath}>
                <Input.TextArea autoSize />
            </Form.Item>

            <Form.Item label="Macro sizes" name={[lang, "macroSizes"]} initialValue={product?.macroSizes}>
                <Input.TextArea rows={6} />
            </Form.Item>
        </>
    );
};

const ProductForm = ({ productId, products }) => {
    const queryClient = useQueryClient();
    const { data, isLoading: isDataLoading} = useQuery({
        queryFn: () => getProduct(productId),
        queryKey: ['getProduct', productId],
        enabled: !!productId
    });

    const productSizes = useMemo(() => data?.sizes || [], [data]);
    console.log(productSizes);

    const { mutateAsync, isLoading } = useMutation({
        mutationFn: (product) => updateProduct(product)
    })
    const [api, contextHolder] = notification.useNotification();
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('ru');
    const [form] = Form.useForm();

    const selectedProducts = useMemo(() => products.filter(({ id }) => id === productId), [productId, products]);
    const ruProduct = useMemo(() => selectedProducts.find(({ ln }) => ln === 'ru'), [selectedProducts]);
    const modalTitle = useMemo(() => ruProduct?.name + ' - редактирование', [ruProduct]);
    const tabItems = useMemo(() => selectedProducts.map(p => ({
        key: p.ln,
        label: tabLabels[p.ln],
        children: <SharedForm product={p} lang={p.ln} />
    })), [selectedProducts]);

    useEffect(() => {
        if (selectedProducts.length) {
            form.setFieldsValue(selectedProducts.reduce((acc, current) => {
                acc[current.ln] = current;
                return acc;
            }, {}));
        }
    }, [form, selectedProducts]);

    const openModalHandler = useCallback(() => setIsOpen(true), []);
    const closeModalHandler = useCallback(() => setIsOpen(false), []);
    const changeTabHandler = useCallback((key) => {
        setActiveTab(key)
    }, []);

    const onFormSubmitHandler = useCallback(async (values) => {
        try {
            const sizes = values?.macro_sizes || '';

            const products = Object.keys(values).filter(k => !['price', 'sizes', 'macro_sizes'].includes(k));
            console.log(products, sizes);
            await Promise.all(products.map((lng) => mutateAsync({
                    ...(selectedProducts.find(({ ln }) => ln === lng) || {}),
                    ...values[lng],
                    price: values.price,
                    sizes: values?.sizes || [],
            })));
            await queryClient.invalidateQueries({ queryKey: ['products', 'all'] });
            api.success({ message: 'Товар успешно обновлен' });
            closeModalHandler();
        } catch (e) {
            console.log(e);
            api.error({  message: 'Произошла ошибка при сохранении товара' });
        }
    }, [api, closeModalHandler, mutateAsync, queryClient, selectedProducts]);

    return (
        <>
            {contextHolder}
            <Button type="primary" size="small" onClick={openModalHandler}>
                Редактировать
            </Button>
            <Modal width={1000} destroyOnClose title={modalTitle} open={isOpen} onCancel={closeModalHandler} footer={null}>
                <Form
                    form={form}
                    name={`productForm-${productId}`}
                    id={productId}
                    onFinish={onFormSubmitHandler}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    disabled={isLoading}
                >
                    <Tabs
                        activeKey={activeTab}
                        onChange={changeTabHandler}
                        items={tabItems}
                    />

                    <Form.Item label="Цена" name="price" initialValue={ruProduct?.price}>
                        <InputNumber min={0} />
                    </Form.Item>

                    <ProductSizesTable sizes={productSizes} isLoading={isDataLoading} />

                    <Form.Item>
                        <Space>
                            <Button onClick={closeModalHandler}>Отмена</Button>
                            <Button type="primary" htmlType="submit" loading={isLoading}>Сохранить</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
};

export default ProductForm;
