import {createSlice} from '@reduxjs/toolkit'
import { cabelSearch } from '../async/asyncSearch'

const initialState = {
  isLoading: true,
  product: [],
  data: [],
}

export const CabelSearchReducer = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetData(state, info) {
      state.data = []
    }
  },
  extraReducers: {
    [cabelSearch.pending.type]: state => {
      state.isLoading = true
    },
    [cabelSearch.fulfilled.type]: (state, action) => {
      state.isLoading = false
      state.data = action.payload.data.data
    },
    [cabelSearch.rejected.type]: state => {
      state.isLoading = false
    },
  },
})

// Action creators are generated for each case reducer function
export const {resetData} = CabelSearchReducer.actions

export default CabelSearchReducer.reducer
