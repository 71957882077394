import React from "react";
import { config } from "./config";
import { Wrapper } from "./styled";
import FaqNested from "components/FaqNested";

export default function ActivitiesResults() {
  return (
    <Wrapper>
      {config.map(({ id, question, answer }) => (
        <FaqNested key={id} question={question} answer={answer} />
      ))}
    </Wrapper>
  );
}
