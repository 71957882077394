import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;

  &:hover {
    color: red;
  }
`;

export const ListItem = styled.li``;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: row wrap;
  align-items: center;
  gap: 4px;
`;

export const IconImageWrapper = styled.div`
  width: 24px;
  display: flex;
  justify-content: end;
`;

export const IconImage = styled.img``;
