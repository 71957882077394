import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  dayOfWar: {
    title:
      'День всенародной памяти жертв Великой Отечественной Войны и геноцида белорусского народа',
    images: [
      '/photogallery/dayOfWar/01.jpg',
      '/photogallery/dayOfWar/02.jpg',
      '/photogallery/dayOfWar/03.jpg',
      '/photogallery/dayOfWar/04.jpg',
      '/photogallery/dayOfWar/05.jpg',
    ],
  },
  circus: {
    title: 'Поездка в цирк',
    images: [
      '/photogallery/circus/01.jpg',
      '/photogallery/circus/02.jpg',
      '/photogallery/circus/03.jpg',
      '/photogallery/circus/04.jpg',
      '/photogallery/circus/05.jpg',
      '/photogallery/circus/06.jpg',
      '/photogallery/circus/07.jpg',
      '/photogallery/circus/08.jpg',
      '/photogallery/circus/09.jpg',
    ],
  },
  learning: {
    images: [
      '/photogallery/learning/01.jpg',
      '/photogallery/learning/02.jpg',
      '/photogallery/learning/03.jpg',
      '/photogallery/learning/04.jpg',
      '/photogallery/learning/05.jpg',
      '/photogallery/learning/06.jpg',
    ],
    title: 'Командноштабные учения 2022',
  },
  memory: {
    images: [
      '/photogallery/memory/01.jpg',
      '/photogallery/memory/02.jpg',
      '/photogallery/memory/03.jpg',
      '/photogallery/memory/04.jpg',
      '/photogallery/memory/05.jpg',
      '/photogallery/memory/06.jpg',
      '/photogallery/memory/07.jpg',
    ],
    title: 'Чернобыль - мы обязаны знать и помнить',
  },
  proforientation: {
    images: [
      '/photogallery/proforientation/01.jpg',
      '/photogallery/proforientation/02.jpg',
      '/photogallery/proforientation/03.jpg',
      '/photogallery/proforientation/04.jpg',
      '/photogallery/proforientation/05.jpg',
      '/photogallery/proforientation/06.jpg',
    ],
    title: 'Профориентация экскурсия фото',
  },
  waterTrip: {
    images: [
      '/photogallery/waterTrip/01.jpg',
      '/photogallery/waterTrip/02.jpg',
      '/photogallery/waterTrip/03.jpg',
      '/photogallery/waterTrip/04.jpg',
      '/photogallery/waterTrip/05.jpg',
      '/photogallery/waterTrip/06.jpg',
      '/photogallery/waterTrip/07.jpg',
      '/photogallery/waterTrip/08.jpg',
      '/photogallery/waterTrip/09.jpg',
      '/photogallery/waterTrip/10.jpg',
      '/photogallery/waterTrip/11.jpg',
      '/photogallery/waterTrip/12.jpg',
      '/photogallery/waterTrip/13.jpg',
      '/photogallery/waterTrip/14.jpg',
    ],
    title: 'Водный поход на байдарках',
  },
  winnerDay: {
    images: [
      '/photogallery/the-win-day/01.jpg',
      '/photogallery/the-win-day/02.jpg',
      '/photogallery/the-win-day/03.jpg',
    ],
    title: 'День победы',
  },
  May: {
    images: [
      '/photogallery/9-may/01.jpg',
      '/photogallery/9-may/02.jpg',
      '/photogallery/9-may/03.jpg',
      '/photogallery/9-may/04.jpg',
      '/photogallery/9-may/05.jpg',
      '/photogallery/9-may/06.jpg',
      '/photogallery/9-may/07.jpg',
      '/photogallery/9-may/08.jpg',
      '/photogallery/9-may/09.jpg',
      '/photogallery/9-may/10.jpg',
      '/photogallery/9-may/11.jpg',
      '/photogallery/9-may/12.jpg',
      '/photogallery/9-may/13.jpg',
      '/photogallery/9-may/14.jpg',
      '/photogallery/9-may/15.jpg',
      '/photogallery/9-may/16.jpg',
    ],
    title: 'Белорускабель отметил 9 мая',
  },
}

export const PhotoReducer = createSlice({
  name: 'photoes',
  initialState,
  reducers: {},
})

// Action creators are generated for each case reducer function
// export const {increment, decrement, incrementByAmount} = PhotoReducer.actions

export default PhotoReducer.reducer
