import { createAsyncThunk } from "@reduxjs/toolkit";
import { https } from "../../config";

export const getProducts = createAsyncThunk(
  "products/getProducts",
  async (lang = "ru") => {
    return await https
      .get("/" + lang + "/products")
      .then((response) => response);
  }
);

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async (lang = "ru") => {
    return await https.get(`/${lang}/categories`).then((response) => response);
  }
);

export const getSubCategories = createAsyncThunk(
  "categories/sub_categories",
  async (lang = "ru") => {
    return await https
      .get(`/${lang}/sub_categories`)
      .then((response) => response);
  }
);

export const getIndustries = createAsyncThunk(
  "categories/get_industries",
  async (lang = "ru") => {
    return await https.get(`/${lang}/industries`).then((response) => response);
  }
);

export const loadSubCategoriesProducts = createAsyncThunk(
  "categories/load_subcategories",
  async ({ id, lang = "ru" }) => {
    return await https
      .get(`/${lang}/sub_category/` + id + "/products")
      .then((response) => response.data.data);
  }
);

export const loadCategoriesProducts = createAsyncThunk(
  "categories/load_categories",
  async ({ id, lang = "ru" }) => {
    return await https
      .get(`/${lang}/category/` + id + "/products")
      .then((response) => response.data.data);
  }
);

export const loadIndustryProducts = createAsyncThunk(
  "categories/load_industries",
  async ({ id, lang = "ru" }) => {
    return await https
      .get(`/${lang}/industry/` + id + "/products")
      .then((response) => response.data.data);
  }
);

export const loadMainProduct = createAsyncThunk(
  "categories/product",
  async ({ id, lang = "ru" }) => {
    return await https
      .get(`/${lang}/product/` + id)
      .then((response) => response.data.data);
  }
);

export const loadExactCategory = createAsyncThunk(
  "categories/load_exact_category",
  async ({ id, lang = "ru" }) => {
    return await https
      .get(`/${lang}/category/` + id)
      .then((response) => response.data.data);
  }
);

export const loadExactIndustry = createAsyncThunk(
  "categories/load_exact_industry",
  async ({ id, lang = "ru" }) => {
    return await https
      .get(`/${lang}/industry/` + id)
      .then((response) => response.data.data);
  }
);

export const getProductForSite = createAsyncThunk(
  "public_catalog/get_product_for_site",
  async ({ id, lang = "ru" }) => {
    return await https
      .get(`/${lang}/product/` + id)
      .then((response) => response.data.data);
  }
);

export const getAllProducts = createAsyncThunk(
  "public_catalog/get_all_products",
  async ({ lang = "ru" }) => {
    return await https
      .get(`/${lang}/products/`)
      .then((response) => response.data.data);
  }
);
