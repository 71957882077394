import {Breadcrumb} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import styled from "styled-components"
import {useEffect} from 'react'
import scrollIntoView from 'scroll-into-view'
import {useTranslation} from 'react-i18next'
import s from './Dillers.module.scss'
import TableWrapper from './TableWrapper'


export default function Dillers() {
  const {t} = useTranslation()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])

  const info = [
    {
      id: 1,
      title: `${t('Юридический адрес')}`,
      text: `${t(
        'Россия, 150044, Ярославская обл, г. Ярославль, ул. Полушкина роща, д. 16, строение 46а, помещение 4'
      )}`,
    },
    {id: 2, title: `${t('Генеральный директор')}`, text: `${t('Незговоров Денис Геннадьевич')}`},
    {
      id: 3,
      title: `${t('Заголовок Телефоны')}`,
      text: '+7(4852)64-46-47, +7(495)669-68-98, +7(812)309-76-86',
    },
    {id: 4, title: `${t('Электронная почта')}`, text: 'mail@gegroup.ru'},
    {id: 5, title: `${t('Адрес сайта')}`, text: 'www.gegroup.ru'},
  ]

  const info1 = [
    {
      id: 1,
      title: `${t('Юридический адрес')}`,
      text: `${t(
        'Россия, 192102, Санкт-Петербург г, вн. тер. г. муниципальный округ Волковское, пр-кт Волковский, д. 146, к. 1, литера А, помещ. 2Н, офис 26'
      )}`,
    },
    {id: 2, title: `${t('Генеральный директор')}`, text: `${t('Васильев Андрей Александрович')}`},
    {
      id: 3,
      title: 'Контактная  информация',
      text: 'timasheva_dd@pek-spb.ru',
    },
    {id: 4, title: `${t('Заголовок Телефоны')}`, text: '+8 (812) 643-72-20'},
    {id: 5, title: `${t('Электронная почта')}`, text: '6777220@pek-spb.ru'},
    {id: 6, title: `${t('Адрес сайта')}`, text: 'www.pek-spb.ru'},
  ]

  const info2 = [
    {
      id: 1,
      title: `${t('Юридический адрес')}`,
      text: `${t('Россия, 109341 г. Москва, ул. Люблинская, д. 151, помещение 316/2')}`,
    },
    {id: 2, title: `${t('Генеральный директор')}`, text: `${t('Топоров Игорь Валерьевич')}`},
    {id: 3, title: `${t('Заголовок Телефоны')}`, text: '(495) 921-34-48'},
    {id: 4, title: `${t('Электронная почта')}`, text: 'info@diada.su'},
    {id: 5, title: `${t('Адрес сайта')}`, text: 'www.diada-electro.ru'},
  ]

  const info3 = [
    {
      id: 1,
      title: `${t('Юридический адрес')}`,
      text: `${t(
        'Россия, 115409, г. Москва, Каширское шоссе, д. 33, корп. 6 эт. 2 пом. 1 ком 24'
      )}`,
    },
    {id: 2, title: `${t('Генеральный директор')}`, text: `${t('Сулягин Дмитрий Александрович')}`},
    {id: 3, title: `${t('Заголовок Телефоны')}`, text: '+ 7 (495) 223-44-08, + 7 (985) 825-31-81'},
    {id: 4, title: `${t('Электронная почта')}`, text: '2234408@mail.ru'},
    {id: 5, title: `${t('Адрес сайта')}`, text: 'www.amdcable.ru'},
  ]
  const info4 = [
    {
      id: 1,
      title: `${t('Юридический адрес')}`,
      text: `${t(
        'Российская Федерация, 109428, г. Москва, Рязанский пр-т, д.10, стр. 18, помещ. 11/8, офис 8.3/805'
      )}`,
    },
    {id: 2, title: `${t('Генеральный директор')}`, text: `${t('Топорова Ирина Викторовна')}`},
    {id: 3, title: `${t('Заголовок Телефоны')}`, text: '+7 (495) 921-34-48'},
    {id: 4, title: `${t('Электронная почта')}`, text: 'info@diada.su'},
    {id: 5, title: `${t('Адрес сайта')}`, text: 'www.diada-electro.ru'},
  ]
  const info5 = [
    {
      id: 1,
      title: `${t('Юридический адрес')}`,
      text: `${t(
        'Российская Федерация, 119034, г. Москва, Лопухинский переулок д.5, стр.4, каб.2, эт.2'
      )}`,
    },
    {id: 2, title: `${t('Генеральный директор')}`, text: `${t('Колосов Владимир Борисович')}`},
    {id: 3, title: `${t('Заголовок Телефоны')}`, text: '+7 (499) 160-98-00, +7 (499) 160-99-90'},
    {id: 4, title: `${t('Электронная почта')}`, text: 'mail@mscab.ru'},
    {id: 5, title: `${t('Адрес сайта')}`, text: 'www.mscab.ru'},
  ]
  const info6 = [
    {
      id: 1,
      title: `${t('Юридический адрес')}`,
      text: `${t(
        'Российская Федерация, 115088, г. Москва, Южнопортовая улица, дом 7, стр. 12'
      )}`,
    },
    {id: 2, title: `${t('Генеральный директор')}`, text: `${t('Юнев Александр Александрович')}`},
    {id: 3, title: `${t('Заголовок Телефоны')}`, text: '+7 (495) 225-76-32'},
    {id: 4, title: `${t('Электронная почта')}`, text: 'info@kvazar.ru'},
    {id: 5, title: `${t('Адрес сайта')}`, text: 'www.kvazar.ru'},
  ]  
  const info7 = [
    {
      id: 1,
      title: `${t('Юридический адрес')}`,
      text: `${t(
        'Российская Федерация, 614500, Пермский край, р-н Пермский, д. Хмели, ш. Космонавтов, 320А'
      )}`,
    },
    {id: 2, title: `${t('Генеральный директор')}`, text: `${t('Кузьмин Сергей Андреевич')}`},
    {id: 3, title: `${t('Заголовок Телефоны')}`, text: '+7 (342) 294-67-88, +7 (342) 294-67-87'},
    {id: 4, title: `${t('Электронная почта')}`, text: 'info@somes-groupp.ru'},
    {id: 5, title: `${t('Адрес сайта')}`, text: 'www.somes-groupp.ru'},
  ]  
  const info8 = [
    {
      id: 1,
      title: `${t('Юридический адрес')}`,
      text: `${t(
        'Республика Казахстан, г. Астана, ул. Желтоксан, 48/1, оф. 28'
      )}`,
    },
    {id: 2, title: `${t('Генеральный директор')}`, text: `${t('Ергалиев Ерлан Алшинович')}`},
    {id: 3, title: `${t('Заголовок Телефоны')}`, text: '+7 (701) 533-84-06'},
    {id: 4, title: `${t('Электронная почта')}`, text: 'kasik@mail.ru'},
    {id: 5, title: `${t('Адрес сайта')}`, text: ''},
  ]    
  return (
    <>
      <div className="clients-page-conteiner">
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Компания')}</Link>
          </span>
          <Breadcrumb.Item active>{t('Меню Дилеры')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t('Дилеры ОАО «Беларускабель»')}</div>
        <p className={s.dillersTitle}>{t('В Российской Федерации:')}</p>
        <TableWrapper
          text={`${t('Общество с ограниченной ответственностью «ДИАДА»')}`}
          info={info2}
        />  
        <TableWrapper
          text={`${t('Общество с ограниченной ответственностью «Диада-Электро»')}`}
          info={info4}
        />  
        <TableWrapper
          text={`${t('Общество с ограниченной ответственностью «АМД-Электро»')}`}
          info={info3}
        /> 
        <TableWrapper
          text={`${t('Акционерное общество «Москабелькомплект»')}`}
          info={info5}
        />   
        {/*<TableWrapper
          text={`${t('Общество с ограниченной ответственностью «Квазар»')}`}
          info={info6}
  /> */}                             
        <TableWrapper
          text={`${t('Общество с ограниченной ответственностью «ПетроЭнергоКомплект»')}`}
          info={info1}
        />             
        <TableWrapper
          text={`${t('Общество с ограниченной ответственностью «СОМЭС-групп»')}`}
          info={info7}
        />
        <p className={s.dillersTitle}>{t('В Республике Казахстан:')}</p>
        <TableWrapper
          text={`${t('ТОО «Agro Olymp Logistic»')}`}
          info={info8}
        />        
      </div>
    </>
  )
}
