export const industryDescription = {
    ru: {
        'Энергетика': 'Продукция для прокладки и реконструкции кабельных линий и распределительных сетей промышленных комплексов, атомных электростанций, строительных объектов и т.д.',
        'Строительство': 'Продукция для энергоснабжения объектов гражданского и промышленного строительства',
        'Нефтегазовая промышленность': 'Продукция для снабжения нефтедобывающих и нефтеперерабатывающих объектов, а также общепромышленного назначения',
        'Транспортная инфраструктура': 'Продукция для электрической установки железнодорожной сигнализации, централизации и блокировки, а также прокладки соответствующих кабелей контроля и связи',
        'Авиация': 'Продукция для монтажа электрических устройств и выводных концов бортовой электроаппаратуры авиационной техники',
        'Телекоммуникации': 'Продукция для прокладки связи, а также монтажа радиотехнических устройств, передающих и приемных антенн с радио- и телевизионных станций, в системах радиосвязи, радиолокации и т.д.',
    },
    en: {
        'Energy': 'Products for laying and reconstructing cable lines and distribution networks of industrial complexes, nuclear power plants, construction sites, etc.',
        'Building': 'Products for power supply of civil and industrial construction sites',
        'Oil and gas': 'Products for supplying oil production and oil refining facilities, as well as general industrial purposes',
        'Transport infrastructure': 'Products for the electrical installation of railway signaling, centralization and interlocking, as well as the installation of associated control and communication cables',
        'Aviation': 'Products for mounting electrical devices and output ends of on-board electrical equipment of aircraft',
        'Telecommunications': 'Products for laying communications, as well as installing radio devices, transmitting and receiving antennas from radio and television stations, in radio communication systems, radar, etc.'
    },
    be: {
        'Тэлекамунікацыі': 'Прадукцыя для пракладкі сувязі, а таксама мантажу радыётэхнічных прылад, якія перадаюць і прыёмных антэн з радыё- і тэлевізійных станцый, у сістэмах радыёсувязі, радыёлакацыі і т.д.',
        'Будаўніцтва': 'Прадукцыя для энергазабеспячэння аб\'ектаў грамадзянскага і прамысловага будаўніцтва',
        'Авіяцыя': 'Прадукцыя для мантажу электрычных прылад і вывадных канцоў бартавой электраапаратуры авіяцыйнай тэхнікі',
        'Транспартная інфраструктура': 'Прадукцыя для электрычнай усталёўкі чыгуначнай сігналізацыі, цэнтралізацыі і блакаванні, а таксама пракладкі адпаведных кабеляў кантролю і сувязі',
        'Энергетыка': 'Прадукцыя для пракладкі і рэканструкцыі кабельных ліній і размеркавальных сетак прамысловых комплексаў, атамных электрастанцый, будаўнічых аб\'ектаў і г.д.',
        'Нафтагазавая': 'Прадукцыя для забеспячэння нафтаздабываючых і нафтаперапрацоўчых аб\'ектаў, а таксама агульнапрамысловага прызначэння',
    },
}

