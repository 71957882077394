import {createSlice} from '@reduxjs/toolkit'
import {auth, registration, selfInfo} from '../async/asyncAuth'

const initialState = {
  name: '',
  email: '',
  password: '',
  id: '',
  isLoading: true,
  authenticated: false,
  showModal: false,
  showCommersModal: false,
}

export const AuthorizeReducer = createSlice({
  name: 'authorize',
  initialState,
  reducers: {
    changeModalShow: state => {
      state.showModal = !state.showModal
    },
    changeCommersModalShow: state => {
      state.showCommersModal = !state.showCommersModal
    },
  },
  extraReducers: builder => {
    builder
      .addCase(registration.pending, state => {
        state.isLoading = true
        state.authenticated = false
      })
      .addCase(registration.fulfilled, (state, action) => {
        state.isLoading = false
        state.name = action.payload.name
        state.email = action.payload.email
        state.password = action.payload.password
      })
      .addCase(registration.rejected, state => {
        state.isLoading = false
      })
      .addCase(auth.pending, state => {
        state.isLoading = true
        state.authenticated = false
      })
      .addCase(auth.fulfilled, (state, action) => {
        state.isLoading = false

        if (action.payload) {
          state.authenticated = true

          if (action.payload.email) state.email = action.payload.email
          if (action.payload._id) state.id = action.payload._id

          localStorage.setItem('jwt_token', action.payload.jwt_token)
        }
      })
      .addCase(auth.rejected, state => {
        state.isLoading = false
        state.authenticated = false
      })
      .addCase(selfInfo.pending, state => {
        state.isLoading = true
      })
      .addCase(selfInfo.fulfilled, (state, action) => {
        state.isLoading = false

        if (action.payload.data.status === 200) {
          state.email = action.payload.email
          state.password = action.payload.password
          state.id = action.payload._id
          state.authenticated = true
        }
      })
      .addCase(selfInfo.rejected, state => {
        state.isLoading = false
      })
  },
})

// Action creators are generated for each case reducer function
export const {changeModalShow, changeCommersModalShow} = AuthorizeReducer.actions

export default AuthorizeReducer.reducer
