import React from 'react'
import s from './Tab.module.scss'

const Tab = ({title, img, active, setActiveTab, activeImg, id}) => {
  return (
    <div className={`${s.flex} ${active ? s.active : ''}`} onClick={() => setActiveTab(id)}>
      {active ? (
        <span>
          <img src={activeImg} />
        </span>
      ) : (
        <span>
          <img src={img} />
        </span>
      )}
      <span className={`${s.text} ${active ? s.active : ''}`}>{title}</span>
    </div>
  )
}

export default Tab
