import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'
import OrderBody from '../OrderBody/OrderBody'
import s from './Offer.module.scss'

const OfferOfficial = props => {
  const {amount} = useSelector(store => store.shopping)
  const navigate = useNavigate()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])

  useEffect(() => {
    if (!amount) navigate('/products')
  }, [amount])

  return (
    <div className={s.body}>
      <div className={s.container}>
        <OrderBody />
      </div>
    </div>
  )
}

export default OfferOfficial
