export const innovate = {
  ru: {
    translation: {
      'ТУ BY 400083186.137-2012': `<p></p>
          ТУ BY 400083186.137-2012 Кабели контроля и передачи данных, огнестойкие, не
          распространяющие горение, с низким дымо- и газовыделением (МКЭШКрВнг(A)-FRLS,
          MKЭKШKpBнг(A)-FRLS и др. модификации).
          <p></p>
          ТУ BY 400083186.147-2014 Кабели управления и передачи данных, огнестойкие, не
          распространяющие горение (КУПДнг(А)-НF, КУПДнг(А)-FRHF различных модификаций).
          <p></p>
          ТУ BY 400083186.148-2014 Кабели и провода для электрических установок огнестойкие и не
          распространяющие горение (ПуГВнг(А)- НF, КуГППнг(А)- НF и др. модификации).
          <p></p>
          ТУ BY 400083186.144-2013 Кабели силовые огнестойкие и не распространяющие горение, с
          изоляцией и оболочкой из полимерных композиций, не содержащих галогенов (ППГнг(А)- НF,
          ППГнг(А)- FRHF и др. модификации).
          <p></p>
          ТУ 16.К71-304-2001 Кабели силовые, не распространяющие горение, с изоляцией и оболочкой из
          полимерных композиций, не содержащих галогенов (ППГнг(А)-НF, ППГЭнг(А)-НF).
          <p></p>
          ТУ 16.К71-338-2004 Кабели для систем управления и сигнализации не распространяющие
          горение, с изоляцией и оболочкой из полимерных композиций, не содержащих галогенов
          (КПЭПнг(А)-НF, КПЭПнг(А)-FRНF).
          <p></p>
          ТУ 16.К71-339-2004 Кабели огнестойкие силовые, не распространяющие горение, с изоляцией и
          оболочкой из полимерных композиций, не содержащих галогенов (ПвПГнг(А)-FRHF,
          ПвПГЭнг(А)-FRHF).
          <p></p>
          ТУ BY 400083186.132-2016 Кабели парной скрутки для структурированных кабельных систем
          связи категории 6 (КВП-6 различных исполнений и модификаций).
          <p></p>
          ТУ BY 400083186.153-2016 Кабели парной скрутки для структурированных кабельных систем
          связи, магистральные (КВПМ-5е различных исполнений и модификаций).
          <p></p>
          ТУ BY 400083186.156-2015 Кабели высокоскоростной передачи данных (КВПД различных
          модификаций и исполнений). «<p></p>
          ТУ 16.К71-339-2004 Кабели огнестойкие контрольные, не распространяющие горение, с
          изоляцией и оболочкой из полимерных композиций, не содержащих галогенов (КППГнг(А)-FRHF,
          КППГЭнг(А)-FRHF).
          <p></p>
          ТУ 16.К71-304-2001 Кабели контрольные, не распространяющие горение, с изоляцией и
          оболочкой из полимерных композиций, не содержащих галогенов (КППГнг(А)-HF, КППГЭнг(A)-HF).
          <p></p>
          ТУ 16.К71-310-2001 Кабели, не распространяющие горение, с низким дымо- и газовыделением
          (KMПBнг(A)-LS различных модификаций).
          <p></p>
          ТУ 16.К71-339-2004 Кабели огнестойкие силовые, не распространяющие горение, с изоляцией и
          оболочкой из полимерных композиций, не содержащих галогенов (ППГнг(А)-FRHF,
          ППГЭнг(A)-FRHF).
          <p></p>
          ТУ 16.К71-310-2001 Кабели, не распространяющие горение, с низким дымо- и газовыделением
          (КУГВВнг(А)-LS различных исполнений).
          <p></p>
          ТУ 16.К71-337-2004 Кабели огнестойкие, не распространяющие горение, с низким дымо- и
          газовыделением (КУГВВнг(А)-FRLS различных исполнений).
          <p></p>
          ТУ 16.К71-304-2001 Кабели силовые, не распространяющие горение, с изоляцией и оболочкой из
          полимерных композиций, не содержащих галогенов (ПвПГнг(A)-HF, ПвПГЭнг(А)-HF)`,
    },
  },
  en: {
    translation: {
      'ТУ BY 400083186.137-2012': `<p></p>
      TU BY 400083186.137-2012 Control and data transmission cables, fire-resistant, flame-retardant, with low smoke and gas emission 
      (MKEShKrVng(A)-FRLS, MKEKShKrVng(A)-FRLS and other modifications).
      <p></p>
      TU BY 400083186.147-2014 Control and data transmission cables, fire-resistant, flame - retardant (KUPDng(A)-HF, KUPDng(A)-FRHF of various modifications).
      <p></p>
      TU BY 400083186.148-2014 Fire-resistant and non-flammable cables and wires for electrical installations (PuGVng (A) - HF, 
      KuGPPng (A) - HF and other modifications).
      <p></p>
      TU BY 400083186.144-2013 Fire-resistant and flame-retardant power cables, with insulation and sheath made of halogen-free polymer composition 
      (PPGng(A)-HF, PPGng(A) - FRHF and other modifications).
      <p></p>
      TU 16.K71-304-2001 Flame-retardant power cables with insulation and sheath made of halogen-free polymer composition (PPGng(A)-HF, PPGEng(A)-HF).
      <p></p>
      TU 16.K71-338-2004 Flame-retardant control and alarm cables with insulation and sheath made of halogen-free polymer composition (KPEPng(A)-HF, KPEPng(A)-FRHF).
      <p></p>
      TU 16.K71-339-2004 Fire-resistant and flame-retardant power cables, with insulation and sheath made of halogen-free polymer composition (PvPGng(A)-FRHF, 
      PvPGEng(A)-FRHF).
      <p></p>
      TU BY 400083186.132-2016 Pair twisted cables for structured cable communication systems (KVP-6 of various designs and modifications).
      <p></p>
      TU BY 400083186.153-2016 Pair twisted cables for structured cable communication systems, trunk (KVPM-5e of various designs and modifications).
      <p></p>
      TU BY 400083186.156-2015 High-speed data transmission cables (KVPD of various modifications).
      <p></p>
      TU 16.K71-339-2004 Fire-resistant and flame-retardant control cables, with insulation and sheath made of halogen-free polymer composition (KPPGng(A)-FRHF, 
      KPPGEng(A)-FRHF).
      <p></p>
      TU 16.K71-304-2001 Flame-retardant control cables with insulation and sheath made of halogen-free polymer composition (KPPGng(A)-HF, KPPGEng(A)-HF).
      <p></p>
      TU 16.K71-310-2001 Flame-retardant cables with low smoke and gas emission (KMPVng(A)-LS of various modifications).
      <p></p>
      TU 16.K71-339-2004 Fire-resistant and flame-retardant power cables, with insulation and sheath made of halogen-free polymer composition (PPGng(A)-FRHF, 
      PPGEng(A)-FRHF).
      <p></p>
      TU 16.K71-310-2001 Flame-retardant cables with low smoke and gas emission (KUGVVng(A)-LS of various designs).
      <p></p>
      TU 16.K71-337-2004 Fire-resistant and flame-retardant cables with low smoke and gas emission (KUGVVng(A)-FRLS of various designs).
      <p></p>
      TU 16.K71-304-2001 Flame-retardant power cables with insulation and sheath made of halogen-free polymer composition (PvPGng(A)-HF, PvPGEng(A)-HF).
      `,
    },
  },
  bel: {
    translation: {
      'ТУ BY 400083186.137-2012': `<p></p>
          ТУ BY 400083186.137-2012 Кабелі кантролю і перадачы даных, вогнеўстойлівыя, не
          якія распаўсюджваюць гарэнне, з нізкім дыма- і газавылучэннем (МКЭШКрВнг(A)-FRLS,
          MKЭKШKpBнг(A)-FRLS і інш. мадыфікацыі).
          <p></p>
          ТУ BY 400083186.147-2014 Кабелі кіравання і перадачы даных, вогнеўстойлівыя, не
          якія распаўсюджваюць гарэнне (КУПДнг(А)-НF, КУПДнг(А)-FRHF розных мадыфікацый).
          <p></p>
          ТУ BY 400083186.148-2014 Кабелі і правады для электрычных установак вогнеўстойлівыя і не
          якія распаўсюджваюць гарэнне (ПуГВнг(А)- НF, КуГППнг(А)- НF і інш. Мадыфікацыі).
          <p></p>
          ТУ BY 400083186.144-2013 Кабелі сілавыя вогнеўстойлівыя і не якія распаўсюджваюць гарэнне, з
          ізаляцыяй і абалонкай з палімерных кампазіцый, якія не змяшчаюць галагенаў (ППГнг(А)- НF,
          ППГнг(А)- FRHF і інш. Мадыфікацыі).
          <p></p>
          ТУ 16.К71-304-2001 Кабелі сілавыя, якія не распаўсюджваюць гарэнне, з ізаляцыяй і абалонкай з
          палімерных кампазіцый, якія не змяшчаюць галагенаў (ППГнг(А)-НF, ППГЭнг(А)-НF).
          <p></p>
          ТУЮ 16.К71-338-2004 Кабелі для сістэм кіравання і сігналізацыі не распаўсюджваюць
          гарэнне, з ізаляцыяй і абалонкай з палімерных кампазіцый, якія не змяшчаюць галагенаў
          (КПЭПнг(А)-НF, КПЭПнг(А)-FRНF).
          <p></p>
          ТУ 16.К71-339-2004 Кабелі вогнеўстойлівыя сілавыя, якія не распаўсюджваюць гарэнне, з ізаляцыяй і
          абалонкай з палімерных кампазіцый, якія не змяшчаюць галагенаў (ПвПГнг(А)-FRHF,
          ПвПГЭнг(А)-FRHF).
          <p></p>
          ТУ BY 400083186.132-2016 Кабелі парнай скруткі для структураваных кабельных сістэм
          сувязі катэгорыі 6 (КВП-6 розных выкананняў і мадыфікацый).
          <p></p>
          ТУ BY 400083186.153-2016 Кабелі парнай скруткі для структураваных кабельных сістэм
          сувязі, магістральныя (КВПМ-5е розных выкананняў і мадыфікацый).
          <p></p>
          ТУ BY 400083186.156-2015 Кабелі высакахуткаснай перадачы даных (КВПД розных
          мадыфікацый і выкананняў). «<p></p>
          ТУЮ 16.К71-339-2004 Кабелі вогнеўстойлівыя кантрольныя, не якія распаўсюджваюць гарэнне, з
          ізаляцыяй і абалонкай з палімерных кампазіцый, якія не змяшчаюць галагенаў (КППГнг(А)-FRHF,
          КППГЭнг(А)-FRHF).
          <p></p>
          ТУ 16.К71-304-2001 Кабелі кантрольныя, якія не распаўсюджваюць гарэнне, з ізаляцыяй і
          абалонкай з палімерных кампазіцый, якія не змяшчаюць галагенаў (КППГнг(А)-HF, КППГЭнг(A)-HF).
          <p></p>
          ТУ 16.К71-310-2001 Кабелі, якія не распаўсюджваюць гарэнне, з нізкім дыма- і газавылучэннем
          (KMПBнг(A)-LS розных мадыфікацый).
          <p></p>
          ТУ 16.К71-339-2004 Кабелі вогнеўстойлівыя сілавыя, якія не распаўсюджваюць гарэнне, з ізаляцыяй і
          абалонкай з палімерных кампазіцый, якія не змяшчаюць галагенаў (ППГнг(А)-FRHF,
          ППГЭнг(A)-FRHF).
          <p></p>
          ТУ 16.К71-310-2001 Кабелі, якія не распаўсюджваюць гарэнне, з нізкім дыма- і газавылучэннем
          (КУГВВнг(А)-LS розных выкананняў).
          <p></p>
          ТУ 16.К71-337-2004 Кабелі вогнеўстойлівыя, якія не распаўсюджваюць гарэнне, з нізкім дыма- і
          газавылучэннем (КУГВВнг(А)-FRLS розных выкананняў).
          <p></p>
          ТУ 16.К71-304-2001 Кабелі сілавыя, якія не распаўсюджваюць гарэнне, з ізаляцыяй і абалонкай з
          палімерных кампазіцый, якія не змяшчаюць галагенаў (ПвПГнг(A)-HF, ПвПГЭнг(А)-HF)`,
    },
  },
}
