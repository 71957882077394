import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import s from './Footer.module.scss'
import whiteShoppingCart from '../../../media/main/shopping-card2.svg'
import {useSelector} from 'react-redux'
import mapPinGray from '../../../media/main/map-pin-gray.svg'

const Footer = () => {
  const cart = useSelector((state) => state.persistedReducer.cart)

  const getTotalQuantity = () => {
    let total = 0
    if(undefined!==cart){
      cart.forEach(item => {
        total += item.quantity
      })
    }

    return total
  }
  const navigate = useNavigate()

  return (
    <footer className={s.wrapperContainer}>
      <div className={s.footer}>
        <div className={s.footerContainer}>
          <div className={s.itemFooter}>
            <p className={s.title}>ОАО “Беларускабель”</p>
            <p className={s.paragraph}>
              Более 60 лет ОАО “Беларускабель” - одно из ведущих кабельных предприятий в Республике
              Беларусь, которое изготавливает и поставляет кабельную продукцию для отраслей и сфер
              потребления - таких как радиоэлектроника, приборостроение, связь, авиационная техника,
              строительство, телекоммуникация и электротехника.
            </p>
            <div className={`${s.infoFooter} ${s.mobile}`}>
              <div className={s.infoItem}>
                <p className={s.preName}>Приемная</p>
                <a href="tel:+375236247729" target="_blank">
                  +375 (236) 24-77-29
                </a>
              </div>
              <div className={`${s.infoItem} ${s.personal}`}>
                <span>Ваш заказ:</span>
                <div className={s.itemImageWrapper}>
                  <img src={whiteShoppingCart} alt="Корзина" />
                  <span className={s.counter}>{getTotalQuantity() || 0}</span>
                </div>
              </div>
              {/* <Link to="/cabinet">Личный кабинет</Link> */}
            </div>
          </div>
          <div className={`${s.itemFooter} ${s.flex}`}>
            <div className={s.menuFooter}>
              <nav>
                <ul>
                  <li>
                    <Link to="/">Главная</Link>
                  </li>
                  <li>
                    <Link to="/">Закупки</Link>
                  </li>
                  <li>
                    <Link to="/">Новости</Link>
                  </li>
                  <li>
                    <Link to="/">Вакансии</Link>
                  </li>
                  <li>
                    <Link to="/">О предприятии</Link>
                  </li>
                  <li>
                    <Link to="/">Контакты</Link>
                  </li>
                  <li>
                    <Link to="/">Административные процедуры</Link>
                  </li>
                  <li>
                    <Link to="/">Обращения граждан</Link>
                  </li>
                  <li>
                    <Link to="/">График приема граждан</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className={s.infoFooter}>
              <div className={s.infoItem}>
                <p className={s.preName}>Приемная</p>
                <a href="tel:+375291114093" target="_blank">
                  +375 (29) 111-40-93
                </a>
                <br/>
                <a href="tel:+375236372575" target="_blank">
                  +375 (236) 37-25-75
                </a>
              </div>
              <div className={s.infoItem}>
                <a href="https://instagram.com/belaruskabel_official" target="_blank">
                  Инстаграм: @belaruskabel_official
                </a>
                <br/>
                <a href="https://instagram.com/belaruskabel_shop" target="_blank">
                  Инстаграм фирменного магазина: @belaruskabel_shop
                </a>
                <br/>

                
                <a href="https://t.me/belaruskabel_mozyr" target="_blank">
                  Телеграм: @belaruskabel_mozyr
                </a>
              </div>




              <div
                className={`${s.infoItem} ${s.personal}`}
                onClick={() => navigate('/shop/backet')}
              >
                <span>Ваш заказ:</span>
                <div className={s.itemImageWrapper}>
                  <img src={whiteShoppingCart} alt="Корзина" />
                  <span className={s.counter}>{getTotalQuantity() || 0}</span>
                </div>
              </div>
              {/* <Link to="/cabinet">Личный кабинет</Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className={s.pastFooter}>
        <div className={s.pastFooterContainer}>
          <span>1959-2022 Беларускабель</span>

          <span className={s.address}>
            <img src={mapPinGray} alt="Метка" />
            247760 г. Мозырь, ул. Октябрьская, 14.
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
