import React from 'react'
import s from './Advantages.module.scss'
import storageStacks from '../../../media/main/storage-stacks.svg'
import verified from '../../../media/main/verified.svg'
import track from '../../../media/main/track.svg'
import credit from '../../../media/main/credit.svg'
import priceTag from '../../../media/main/price-tag.svg'

const Advantages = props => {
  return (
    <>
      <h2 className={s.title}>Наши преимущества</h2>
      <div className={s.flex}>
        <div className={s.flexItem}>
          <img src={storageStacks} alt="Иконка здания" />
          <p>Прямые поставки с завода</p>
        </div>
        <div className={s.flexItem}>
          <img src={verified} alt="Иконка здания" />
          <p>Товар 100% сертифицирован</p>
        </div>
        <div className={s.flexItem}>
          <img src={credit} alt="Иконка здания" />
          <p>Возможность оплаты онлайн</p>
        </div>
        <div className={s.flexItem}>
          <img src={track} alt="Иконка здания" />
          <p>Быстрая доставка или самовывоз</p>
        </div>
        <div className={s.flexItem}>
          <img src={priceTag} alt="Иконка здания" />
          <p>Выгодные цены</p>
        </div>
      </div>
    </>
  )
}

export default Advantages
