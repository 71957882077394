export const getMostPopularCategories = (t) => ([
  {
    id: 1,
    leadersCategory: t("Кабели силовые"),
    leaders: ["КУПД", "КВПД", "МКЭШв"].map(t),
    link: "bc3018d1-1848-4213-9cc5-49139db88721",
  },
  {
    id: 2,
    leadersCategory: t("Контрольный кабель"),
    leaders: ["КВВГ", "КВВГЭ"].map(t),
    link: "61cbbb4e-bf92-4111-a9fa-7d6c477eff8b",
  },
  {
    id: 3,
    leadersCategory: t("Кабели и провода связи"),
    leaders: ["ТППэп", "ТППШв", "КВП"].map(t),
    link: "3c46d789-b638-4a9a-be43-249e447f8f0c",
  },
  {
    id: 4,
    leadersCategory: t("Сигнально-блокировочный"),
    leaders: ["СБЗПУ", "КВПД", "МКЭШв"].map(t),
    link: "3c46d789-b638-4a9a-be43-249e447f8f0c",
  },
  {
    id: 5,
    leadersCategory: t("Провода теплостойкие"),
    leaders: ["БПДО", "МСТП", "МЛТП"].map(t),
    link: "2c102d2e-d34d-4cea-a063-09f760e3fc3e",
  },
  {
    id: 6,
    leadersCategory: t("Провода монтажные"),
    leaders: ["МНВ", "МГШВ", "НВ"].map(t),
    link: "678b7c3d-99da-4c94-a6d6-eb655464ea8c",
  },
]);
