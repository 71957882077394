import React from 'react'
import Header from '../PublicPage/Header/Header'
import Footer from '../PublicPage/Footer/Footer'
import Form from './Form/Form'
import s from './Registration.module.scss'
import {auth, registration} from '../../store/async/asyncAuth'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'

const Registration = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const registrate = ({name, email, password}) => {
    dispatch(
      registration({
        name,
        email,
        password,
      })
    )

    dispatch(auth({email, password}))
    navigate('/public')
  }

  return (
    <div className={s.container}>
      <Form registration={registrate} />
    </div>
  )
}

export default Registration
