import React, {useState} from 'react'
import s from './SimpleForm.module.scss'
import Button from '../../ui/Button/Button'
import {useDispatch} from 'react-redux'
import {setCategory} from '../../../../store/async/asyncAdmin'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {getCategories} from '../../../../store/async/asyncCategories'

const schema = yup
  .object({
    category: yup.string(),
  })
  .required()

const SimpleForm = ({jwt}) => {
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const onSubmit = data => {
    dispatch(setCategory({name: data.category, jwt})).then(() => dispatch(getCategories()))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
      <input {...register('category')} className={`${s.input} ${s.last}`} placeholder="Категория" />
      {errors.category && (
        <p>
          <span className={s.error}>{errors.category?.message}</span>
        </p>
      )}

      <Button text="Добавить" />
    </form>
  )
}

export default SimpleForm
