import React, {useEffect} from 'react'
import s from './Backet.module.scss'
import file from '../../../media/main/backet/file.svg'
import bin from '../../../media/main/backet/bin.svg'
import letters from '../../../media/main/backet/letters.svg'
import packet from '../../../media/main/backet/packet.svg'
import rub from '../../../media/main/backet/rub.svg'
import {Link, useOutletContext} from 'react-router-dom'
import provod from '../../../media/main/default.png'
import scrollIntoView from 'scroll-into-view'
import { useDispatch, useSelector } from 'react-redux'
import { incrementQuantity, decrementQuantity, removeItem } from 'store/reducers/ShoppingReducer'

const Backet = props => {
  const {setBreadCrumb} = useOutletContext()
  const cart = useSelector((state) => state.persistedReducer.cart)

  const getRowTotal = (quantity, price) => {
    let total = quantity * price;
    return total.toFixed(2);
  }

  const getTotal = () => {
    let totalQuantity = 0
    let totalPrice = 0
    cart.forEach(item => {
      totalQuantity += item.quantity
      totalPrice += item.price * item.quantity
    })
    totalPrice = totalPrice.toFixed(2);

    return {totalPrice, totalQuantity}
  }

  const dispatch = useDispatch()


  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })

    setBreadCrumb([
      {
        id: 1,
        title: 'Главная',
        href: '/shop',
      },
      {
        id: 2,
        title: 'Корзина',
      },
    ])
  }, [])

  return (
    <div className={s.body}>
      <div className={s.container}>
        <div className={s.flex}>
          <div className={s.flexItem}>
            <p className={s.title}>Корзина</p>

            <table className={s.table}>
              <thead>
              <tr>
                <th className={s.tableHeader}>
                  <img className={s.tableImg} src={file} alt="Наименование" />
                  <span>Наименование</span>
                </th>
                <th className={s.tableHeader}>
                  <img className={s.tableImg} src={rub} alt="Цена" />
                  <span>Цена</span>
                </th>
                <th className={s.tableHeader}>
                  <img className={s.tableImg} src={letters} alt="Количество" />
                  <span>Количество (м)</span>
                </th>
                <th className={s.tableHeader}>
                  <img className={s.tableImg} src={packet} alt="Стоимость" />
                  <span>Стоимость</span>
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              {cart && cart.map(item => (
                <tr key={item.id}>
                  <td className={s.firstCell}>
                    <div className={s.flexCard}>                 
                      <img className={s.img} src={item.img ? item.img : provod} alt="Иконка провода" />
                      <p className={s.cardTitle}>{item.title}</p>
                    </div>
                    <p className={s.paragraph}>
                      {item.purpose}
                    </p>
                  </td>
                  <td>
                    <p className={s.price}>{item.price ? item.price + 'р' : 'отсутс.'}</p>
                    <div className={s.flexDiscount}>
                      <span className={s.oldPrice}>
                        <s>отсутс.</s>
                      </span>
                      {/* <span className={s.discount}>отсутс.</span> */}
                    </div>
                  </td>
                  <td>
                    <div className="amount-row">
                      <button onClick={() => dispatch(decrementQuantity(item.id))}>-</button>
                      <span className={s.amount}>{item.quantity}</span>
                      <button onClick={() => dispatch(incrementQuantity(item.id))}>+</button>
                    </div>
                    

                  </td>
                  <td>
                    <span className={s.result}>{getRowTotal(item.quantity, item.price)}</span>
                  </td>
                  <td>
                    <img src={bin} alt="Удалить элемент" onClick={() => {
                      dispatch(removeItem(item.id))
                    }}/>
                  </td>
                </tr>
              ))}
              </tbody>

            </table>

          </div>
          <div className={s.flexItem}>
            <div className={s.itogo}>
              <span className={s.title}>Итого</span>
              <span className={s.title}>{getTotal().totalPrice}₽</span>
            </div>
            <div className={s.itogo}>
              <span className={s.itogoParagraph}>Товары</span>
              <span className={s.itogoParagraph}>{getTotal().totalPrice}₽</span>
            </div>
            <div className={s.itogo}>
              <span className={s.itogoParagraph}>Скидка</span>
              <span className={s.itogoParagraph}>0₽</span>
            </div>
            <Link to="/shop/order" className={`${s.link} ${s.first}`}>
              Оформить заказ
            </Link>
            <Link className={s.link} to="/shop/catalogue">
              Продолжить покупки
            </Link>
          </div>
        </div>
      </div>
    </div>
      

  )


  
}

export default Backet
