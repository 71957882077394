import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import s from './Dropdown/Dropdown.module.scss'

const LeadersDropdown = ({leaders}) => {
  const [activeDropdown, setActiveDropdown] = useState(null)
  const navigate = useNavigate()

  return (
    <>
      <div className={`${s.dropdownMenus}`}>
        {leaders.map(lead => (
          <div className={s.dropdown} key={lead.leadersCategory}>
            <div
              className={`${s.title} ${activeDropdown === lead.id ? s.active : ''}`}
              id="dropdown-basic"
              onClick={() => {
                if (activeDropdown !== lead.id) {
                  setActiveDropdown(lead.id)
                } else {
                  setActiveDropdown(null)
                }
              }}
            >
              <span className="product-type-link">{lead.leadersCategory}</span>
              <img src="vectorDown.png" alt="vector " />
            </div>
            <div className={`${s.drops} ${activeDropdown === lead.id ? s.active : ''}`}>
              <div className={s.dropdown}>
                {lead.leaders.map(elem => (
                  <p
                    key={elem}
                    className={`${s.itemDropdown}`}
                    onClick={() => {
                      navigate('/products/' + elem.link)
                    }}
                  >
                    {elem}
                  </p>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default LeadersDropdown
