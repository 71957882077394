import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import s from './Types.module.scss'

const Types = props => {
  const navigate = useNavigate()
  
  const initialState = [
    {
      id: 1,
      title: 'Кабель для жилых помещений в домах/квартирах',
      paragraph: 'Для проводки освещения и розеток в жилых помещениях',
      img: 'publicPages/types/01.png',
      link: '?industry=43907bbf-b35a-4920-9a03-fe393a36aea6&page=1'
    },
    {
      id: 2,
      title: 'Провода для бани/сауны (жаростойкие)',
      paragraph:
        'Для проведения освещения в помещениях с постоянной высокой температурой до 150° С',
      img: 'publicPages/types/02.png',
      link: '?industry=aab4345d-e27e-4afa-ad69-6c35d62ef123&page=1'
    },
    {
      id: 3,
      title: 'Кабель для гаражных помещений',
      paragraph: 'Для прокладки проводки в неотапливаемых и сырых подсобных помещениях',
      img: 'publicPages/types/03.png',
      link: '?industry=c7cbc938-e295-4a90-aad0-431eeb075786&page=1'
    },
    {
      id: 4,
      title: 'Автомобильная проводка и  магнитола',
      paragraph:
        'Для соединения автотранспортного электрооборудования и приборов с номинальным напряжением до 48 В постоянного тока',
      img: 'publicPages/types/04.png',
      link: '?industry=a551848b-646d-43e2-b6a5-56fa70a48b5e&page=1'
    },
    {
      id: 5,
      title: 'Кабель для открытых пространств',
      paragraph:
        'Для прокладки силового кабеля на улице от электрического столба или трансформаторной будки до помещения',
      img: 'publicPages/types/05.png',
      link: '?industry=9f138c54-e8b1-4156-b76a-43c26ba9be64&page=1'
    },
    {
      id: 6,
      title: 'Кабель для Интернета и телефонной связи',
      paragraph: 'Для подведения телефонной связи и сети Интернет к помещению',
      img: 'publicPages/types/06.png',
      link: '?industry=c78f783f-0fa8-4e1e-a497-c4fbe880513a&page=1'
    },
    {
      id: 7,
      title: 'Кабели и провода для сигнализации и видеонаблюдения',
      paragraph:
        'Для монтажа систем сигнализации и видеонаблюдения при температуре от минус 40° С до плюс 60° С',
      img: 'publicPages/types/07.png',
      link: '?industry=0711a42c-0fd0-4869-8dd4-eb4a73c283f7&page=1'
    },
    {
      id: 8,
      title: 'Для удлинителей, сварочных аппаратов',
      paragraph: '',
      img: 'publicPages/types/08.png',
      link: '?industry=f4bf5b71-064b-4fca-8dd2-1320b5d994fb&page=1'
    },
    {
      id: 9,
      title: 'Для ввода электричества в дом',
      paragraph: '',
      img: 'publicPages/types/09.png',
      link: '?industry=2cd894dc-1c45-437e-b554-9721b168d2d5&page=1'
    },
    {
      id: 10,
      title: 'Для светодиодных ламп и низковольтного освещения',
      paragraph: '',
      img: 'publicPages/types/10.png',
      link: '?industry=a46da514-be65-4ad6-a71b-010a1e2d86e7&page=1'
    },
    {
      id: 11,
      title: 'Кабель для телевизионных антенн',
      paragraph: '',
      img: 'publicPages/types/11.png',
      link: '?industry=4e1c2889-9826-475c-a349-7177782b543d&page=1'
    },
    {
      id: 12,
      title: 'Прочая продукция',
      paragraph: '',
      img: 'publicPages/types/12.png',
      link: '?category=5179c023-fea7-487b-99b7-be61acb61c07&page=1'
    },
  ]

  return (
    <>
      <h2 className={s.mainTitle}>Виды и назначения кабеля</h2>
      <div className={s.flex}>
        {initialState.map(state => (
          <div className={s.flexItem} key={state.id} onClick={() => navigate('/shop/catalogue' + state.link)}>
            <img src={state.img} alt="Изображение" />

            <div className={s.wrapper}>
              <p className={s.title}>{state.title}</p>
              {state.paragraph ? <p className={s.paragraph}>{state.paragraph}</p> : ''}
            </div>
          </div>
        ))}
      </div>
      <p className={s.button}>
        <Link to="/shop/catalogue">Перейти в каталог</Link>
      </p>
    </>
  )
}

export default Types
