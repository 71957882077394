import React, {useEffect} from 'react'
import s from './Import.module.scss'
import {Breadcrumb} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'
import {useTranslation} from 'react-i18next'

const ImportPage = () => {
  const {t} = useTranslation()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])

  const initialData = [
    {
      id: 1,
      name: `${t('Медная катанка КМ М001')}`,
      gost: t('Сл ГОСТ') + ' Р53803-2010',
      consumption: '800',
    },
    {
      id: 2,
      name: `${t('Алюминиевая катанка')}`,
      gost: t('Сл ГОСТ') + ' 13843-19',
      consumption: '15',
    },
    {
      id: 3,
      name: `${t(`ПВХ пластикат марки <br/><br/> И40-13А, рец.8/2`)}`,
      gost: t('Сл ГОСТ') + ' 5960-72',
      consumption: '200',
    },
    {
      id: 4,
      name: `${t('ПВХ пластикат марки <br/><br/> О-40, рец.ОМ-40')}`,
      gost: t('Сл ГОСТ') + ' 5960-72',
      consumption: '260',
    },
    {
      id: 5,
      name: `${t('ПВХ пластикат <br/><br/> ИТ-105')}`,
      gost: `${t('ТНПА изготовителя')}`,
      consumption: '20',
    },
    {
      id: 6,
      name: `${t('ПВХ пластикат <br/><br/> ППИ 20-28 ')}`,
      gost: `${t('ТНПА изготовителя')}`,
      consumption: '80',
    },
    {
      id: 7,
      name: `${t('ПВХ пластикат марки <br/><br/> НГП 30-32')}`,
      gost: `${t('ТНПА изготовителя')}`,
      consumption: '40',
    },
    {
      id: 8,
      name: `${t('ПВХ пластикат марки <br/><br/> НГП 50-32 ')}`,
      gost: `${t('ТНПА изготовителя')}`,
      consumption: '10',
    },
    {
      id: 9,
      name: `${t('ПВХ пластикат марки <br/><br/> НГП ППВ-28 ')}`,
      gost: `${t('ТНПА изготовителя')}`,
      consumption: '60',
    },
    {
      id: 10,
      name: `${t('ПВХ пластикат марки <br/><br/> ППО 20-35')}`,
      gost: `${t('ТНПА изготовителя')}`,
      consumption: '160',
    },
    {
      id: 11,
      name: `${t('Силиконовая резиновая смесь ')}`,
      gost: `${t('ТНПА изготовителя')}`,
      consumption: '3',
    },
    {
      id: 12,
      name: `${t('Композиция безгалогенная ')}`,
      gost: `${t('ТНПА изготовителя')}`,
      consumption: '40',
    },
    {
      id: 13,
      name: `${t('Полиэтилен 153-10К')}`,
      gost: t('Сл ГОСТ') + ' 16336-2013',
      consumption: '40',
    },
    {
      id: 14,
      name: `${t('Полиэтилен 153-02К')}`,
      gost: t('Сл ГОСТ') + ' 16336-2013',
      consumption: '40',
    },
    {
      id: 15,
      name: `${t('Полиэтилен <br/><br/> 271-274К; 273-81К')}`,
      gost: t('Сл ГОСТ') + ' 16336-2013',
      consumption: '20',
    },
    {
      id: 16,
      name: `${t('Полиэтилен термостабилизиро-ванный 102-57')}`,
      gost: 'ТУ 301-05-197-93',
      consumption: '10',
    },
    {
      id: 17,
      name: `${t('Фторопласт 2М <br/><br/> марки А ')}`,
      gost: 'ТУ 2213-012-13693708-2004',
      consumption: '1',
    },
    {
      id: 18,
      name: `${t(
        'Суперконцентрат пигментов для окраски полиолефинов (краситель для полиэтилена)'
      )}`,
      gost: `${t('ТНПА изготовителя')}`,
      consumption: '2',
    },
    {
      id: 19,
      name: `${t('ПВХ пластикат концентрированно-окрашенный <br/><br/> (краситель для ПВХ)')}`,
      gost: `${t('ТНПА изготовителя')}`,
      consumption: '5',
    },
    {
      id: 20,
      name: `${t('Сл Гидрофобинол-1')}`,
      gost: 'ТУ BY 600125053.104-2020',
      consumption: '5',
    },
    {
      id: 21,
      name: `${t('Нить стеклянная крученная комплексная <br/><br/> ЕС 6 6,8х2Z100')}`,
      gost: t('Сл ГОСТ') + ' 8325-2015',
      consumption: '1',
    },
    {
      id: 22,
      name: `${t('Пленка полиэтилентерефта-латная  марки ПЭТ-Э')}`,
      gost: t('Сл ГОСТ') + ' 24234-80',
      consumption: '5',
    },
    {
      id: 23,
      name: `${t('Фольга алюминиевая')}`,
      gost: t('Сл ГОСТ') + ' 618-2014',
      consumption: '1',
    },
    {
      id: 24,
      name: `${t('Сл Олово')}`,
      gost: t('Сл ГОСТ') + ' 860-75',
      consumption: '3',
    },
    {
      id: 25,
      name: `${t('Сл Алюмофлекс')}`,
      gost: 'ТУ 2358-017-50157149-2004',
      consumption: '4',
    },
    {
      id: 26,
      name: `${t('Проволока стальная оцинкованная диаметром 0,3 мм. ')}`,
      gost: t('Сл ГОСТ') + ' 1526-81',
      consumption: '50',
    },
    {
      id: 27,
      name: `${t('Лента для бронирования кабеля')}`,
      gost: t('Сл ГОСТ') + ' 3559-75',
      consumption: '10',
    },
    {
      id: 28,
      name: `${t('Лента <br/><br/> Элмикатекс 54509')}`,
      gost: 'ТУ 3492-023-50157149-2004',
      consumption: '',
    },
    {
      id: 29,
      name: `${t('Алюмополиэтиленовая лента АПЛ')}`,
      gost: 'ТУ 7417-001-00032300-2006',
      consumption: '10',
    },
    {
      id: 30,
      name: `${t('Водоблокирующая лента')}`,
      gost: 'ТУ 3491-001-49403133-2016 или ТУ 27.90.12-001-97452743-2018',
      consumption: '10',
    },
  ]
  return (
    <>
      <div className={s.container}>
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Компания')}</Link>
          </span>
          <Breadcrumb.Item active>{t('Предложения по импортозамещению')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t('Предложения по импортозамещению')}</div>
        <div className={s.tableWrapper}>
          <table className={s.table}>
            <tr>
              <th>№</th>
              <th>{t('Наименование сырья')}</th>
              <th>{t('ГОСТ или ТУ')}</th>
              <th>{t('Сл Объём')}</th>
            </tr>
            {initialData.map(data => (
              <tr key={data.id}>
                <td>{data.id}</td>
                <td dangerouslySetInnerHTML={{__html: data.name}} />
                <td>{data.gost}</td>
                <td>{data.consumption}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </>
  )
}

export default ImportPage
