import React from 'react'
import {useState} from 'react'
import { useSelector } from 'react-redux'
import { send } from 'emailjs-com';
import {Link, useNavigate} from 'react-router-dom'
import InputBlock from '../InputBlock/InputBlock'
import Payment from '../Payment/Payment'
import Production from '../Production/Production'
import s from './OrderBody.module.scss'
import { removeItem } from 'store/reducers/ShoppingReducer'
import {useDispatch} from 'react-redux'
import swal from 'sweetalert';



const OrderBody = props => {
  const [isActive, setIsActive] = useState(true)
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const cart = useSelector((state) => state.persistedReducer.cart)

    const dispatch = useDispatch()


  const onSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    let i=0;
    const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;    
    if(!e.target.fio.value && e.target.fio.value.length<3){      
      e.target.fio.classList.add(s.error);      
      i++;
    }
    if(!e.target.phone.value){      
      e.target.phone.classList.add(s.error);      
      i++;      
    }
    if(!e.target.email.value && !EMAIL_REGEXP.test(e.target.email.value)){      
      e.target.email.classList.add(s.error);      
      i++;
      
    }     
    if(!cart.length){
      i++;
    }    
    if(i>0){
      swal('Заполните обязательные поля');
      setDisabled(false);      
      return;      
    }
    if(!isActive){
      swal('Вы не приняли соглашение на обработку персональных данных');
      setDisabled(false);      
      return;
    }

    swal('Заказ обрабатывается');

    var products = '<ul>';
    cart.forEach((product) => {
      var sum = product.price * product.quantity;
      sum = sum.toFixed(2);
      products += '<li>'+product.title+'<br> '+product.price+' * '+product.quantity+' (метра) = '+sum+'р.</li>';
      dispatch(removeItem(product.id));
    });
    products += '</ul>';
    let toSend = {
      fio: e.target.fio.value,
      phone: e.target.phone.value,
      address: e.target.address.value,
      email: e.target.email.value,
      shipping: e.target.delivery.value,
      payment: e.target.method.value,
      products: products,
    };
    
    send(
      'service_q4it7eh',
      'template_vzug5sm',
      toSend,
      '6KTV5y85JxjV-D4F4'
    )
      .then((response) => {
        swal('Заказ успешно отправлен! Мы свяжемся с вами в ближайшее время.');
        navigate('/shop/');
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((err) => {
        swal('Ошибка заполнения полей.');
        console.log('FAILED...', err);
      });
  };

  if (cart.length) {
      return (
    <div className={s.block}>
      <h1 className={s.title}>Оформление заказа</h1>
        <form onSubmit={onSubmit}>
      <div className={s.inputBlock}>
        <InputBlock />
        <Payment />
        <Production />
        <span className={s.link}>
          <button type="submit" disabled={disabled}>Оформить заказ</button>

        </span>

        <p className={s.apply} onClick={() => setIsActive(!isActive)}>
          <span>
            <input type="checkbox" checked={isActive} />
          </span>
          <span className={isActive ? s.active : ''}></span>
          <span className={s.margin}>
            Я выражаю согласие на{' '}
            <span>
              <Link to="/">передачу и обработку персональных данных</Link>
            </span>{' '}
            в соответствии с{' '}
            <span>
              <Link to="/">Пользовательским соглашением</Link>
            </span>
            . <br /> Отправляя заказ, Вы соглашаетесь с{' '}
            <span>
              <Link to="/">
                Правилами приобретения продукции в интернет-магазине ОАО “Беларускабель”
              </Link>
            </span>
          </span>
        </p>
      </div>
      </form>
    </div>
  ) 
  } else {
    return (
      <div className={s.block}>
      <h1 className={s.title}>Корзина пуста</h1>
      </div>
    )
  }

}

export default OrderBody
