import {memo, useMemo} from "react";
import {Form, Input, Table} from "antd";

const ProductSizesTable = memo(({ sizes, isLoading }) => {
    const sizesTableColumns = useMemo(() => ([
        {
            dataIndex: 'id',
            render: (v, _, idx) => <Form.Item hidden name={['sizes', idx, 'id']} initialValue={v}><Input/></Form.Item>
        },
        {
            title: 'Номинальное сечение жил, мм²',
            dataIndex: 'nominalCrossSection',
            render: (v, _, idx) => <Form.Item name={['sizes', idx, 'nominalCrossSection']} initialValue={v}><Input /></Form.Item>
        },
        {
            title: 'Число жил',
            dataIndex: 'numberOfCores',
            render: (v, _, idx) => <Form.Item name={['sizes', idx, 'numberOfCores']} initialValue={v}><Input /></Form.Item>
        },
        {
            title: 'Максимальный диаметр, мм',
            dataIndex: 'maxDiameter',
            render: (v, _, idx) => <Form.Item name={['sizes', idx, 'maxDiameter']} initialValue={v}><Input /></Form.Item>
        },
        {
            title: 'Масса, кг/км',
            dataIndex: 'weight',
            render: (v, _, idx) => <Form.Item name={['sizes', idx, 'weight']} initialValue={v}><Input /></Form.Item>
        },
        {
            title: 'Конструкция жилы',
            dataIndex: 'coreConstruction',
            render: (v, _, idx) => <Form.Item name={['sizes', idx, 'coreConstruction']} initialValue={v}><Input /></Form.Item>
        }
    ]), []);

    return (
        <Form.Item label="Макроразмеры">
            <Table dataSource={sizes} columns={sizesTableColumns} loading={isLoading} rowKey="id" />
        </Form.Item>
    );
});

ProductSizesTable.displayName = 'ProductSizesTable';

export default ProductSizesTable;