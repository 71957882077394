import React from 'react'
import {useState} from 'react'
import s from './Button.module.scss'

const Button = ({text, active, img, activeImg, small, onClick, ...htmlProps}) => {
  const [act, setActive] = useState(false)

  return (
    <button
      className={`${s.button} ${active ? s.active : ''} ${small ? s.small : ''}`}
      onMouseOver={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      {...htmlProps}
    >
      {img && <span>{act ? <img src={activeImg} /> : <img src={img} />}</span>}
      <img src={activeImg} className={s.hidden} />
      <img src={img} className={s.hidden} />
      {text}
    </button>
  )
}

export default Button
