import React, {useState} from 'react'
import s from './NewsForm.module.scss'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup
  .object({
    name: yup.string(),
    text: yup.string(),
  })
  .required()

const NewsForm = props => {
  const [imgValue, setImgValue] = useState()

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const onSubmit = () => console.log()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input
        type="text"
        {...register('name')}
        className={s.input}
        placeholder="Наименование новости"
      />
      <textarea
        type="text"
        {...register('text')}
        className={s.textarea}
        placeholder="Текст новости"
      ></textarea>

      <div className={s.flex}>
        <div className={s.inputWrapper}>
          <input
            name="file"
            type="file"
            id="input__file"
            className={s.inputFile}
            accept="image/png,image/jpeg"
            value={imgValue}
            onChange={e => setImgValue(e.target.value)}
          />
          <label
            htmlFor="input__file"
            className={`${s.inputFileButton} ${imgValue ? s.active : ''}`}
          >
            <span className={`${s.inputFileWrapper} ${imgValue ? s.active : ''}`}>
              <svg
                className={`${s.inputFileIcon} ${imgValue ? s.active : ''}`}
                width="24"
                height="21"
                viewBox="0 0 24 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 2.625H5C3.89543 2.625 3 3.4085 3 4.375V16.625C3 17.5915 3.89543 18.375 5 18.375H19C20.1046 18.375 21 17.5915 21 16.625V4.375C21 3.4085 20.1046 2.625 19 2.625Z"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.5 8.75C9.32843 8.75 10 8.16237 10 7.4375C10 6.71263 9.32843 6.125 8.5 6.125C7.67157 6.125 7 6.71263 7 7.4375C7 8.16237 7.67157 8.75 8.5 8.75Z"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 13.125L16 8.75L5 18.375"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span className={`${s.inputIconText} ${imgValue ? s.active : ''}`}>
              {imgValue ? 'Изображение загружено' : 'Загрузите изображение'}
            </span>
          </label>
        </div>

        <button className={s.save} type="submit">
          Сохранить
        </button>
      </div>
    </form>
  )
}

export default NewsForm
