import { Link } from "react-router-dom";
import "./Footer.scss";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer
      className="text-center text-lg-start text-muted"
      style={{ background: "#343753" }}
    >
      <div className="d-flex justify-content-center justify-content-lg-between border-bottom" />

      <div className="main-footer-wrapper">
        <div
          className="container text-center text-md-start mt-5"
          style={{ color: "white" }}
        >
          <div className="row mt-3">
            <div className="col-md col-lg-4 col-xl-6 mx-auto mb-4 footer-first-child">
              <h6
                className="text-uppercase fw-bold mb-4"
                style={{ color: "red" }}
              >
                <img className="footer-logo" src="/lbl.png" alt="Logo" />
              </h6>
              <p className="belcable-history">{t("Описание снизу")}</p>

              <div className="footer-images" >
                <img src="/footer/AE_logo.png" height="40px" />
                <img src="/footer/belapp.png" height="40px" />
                <img src="/footer/2.png" height="40px" />
                <img src="/footer/3.png" height="40px" />
              </div>

            </div>

            

            <div
              className="col-md-3 col-lg-2 col-xl-1 mx-auto mb-4 footer-links"
              style={{ whiteSpace: "nowrap" }}
            >
              <p>
                <Link to="/production" className="text-reset">
                  {t("Меню производство")}
                </Link>
              </p>
              <p>
                <Link to="/products" className="text-reset">
                  {t("Меню каталог")}
                </Link>
              </p>
              <p>
                <Link to="/for-investors" className="text-reset">
                  {t("Меню Акционерам")}
                </Link>
              </p>
              <p>
                <Link to="/contacts" className="text-reset">
                  {t("Меню Контакты")}
                </Link>
              </p>
            </div>

            <div
              className="col-md-3 col-lg-2 col-xl-1 mx-auto mb-4 footer-links"
              style={{ whiteSpace: "nowrap" }}
            >
              <p>
                <Link to="/dillers" className="text-reset">
                  {t("Меню Дилеры")}
                </Link>
              </p>
              <p>
                <Link to="/history" className="text-reset">
                  {t("Меню История")}
                </Link>
              </p>
              <p>
                <Link to="/awards" className="text-reset">
                  {t("Наши достижения")}
                </Link>
              </p>
            </div>

            <div
              className="col-md-12 col-lg-2 col-xl-1 mx-auto mb-4 linkBlock footer-links"
              style={{ whiteSpace: "nowrap" }}
            >
              <p>{t("номер приёмной")}</p>
              <p>
                <a
                  className="unique-links"
                  href="tel:+375236247729"
                  target="_blank"
                >
                  +375 (236) 24-77-29
                </a>
              </p>
              <br />
              <p>{t("отдел продаж")}</p>
              <p>
                <a
                  className="unique-links"
                  href="tel:+375236254742"
                  target="_blank"
                >
                  +375 (236) 25-47-42
                </a>
              </p>

              <br />
              <div className="footer-social">
                <p><strong>{t("Мы в социальных сетях")}:</strong></p>
                <p>
                  <a href="https://instagram.com/belaruskabel_official" target="_blank">
                    <img src="/footer/instagram.png" />
                  </a>
                  <a href="https://t.me/belaruskabel_mozyr" target="_blank">
                    <img src="/footer/telegram.png" />
                  </a>                  
                </p>
                <p><strong>{t("Фирменный магазин")}:</strong></p>
                <p>
                  <a
                    href="https://instagram.com/belaruskabel_shop" target="_blank" >
                      <img src="/footer/instagram.png" />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div>
            <a
              className="policy-footer-link"
              href="/footer/politika-v-otnoshenii-obrabotki-personalnyh-dannyh.pdf"
              target="_blank"
            >
              {t('Политика в отношении обработки персональных данных')}
            </a>
          </div>
          <div>
            <a
              className="policy-footer-link"
              href="/footer/politika_cookie.pdf"
              target="_blank"
            >
              {t('Политика в отношении обработки файлов cookie')}
            </a>
          </div>          
          <div>
            <a
              className="policy-footer-link"
              href="/footer/politika-videonablyudeniya.pdf"
              target="_blank"
            >
              {t('Политика видеонаблюдения')}
            </a>
          </div>
          <div
            className="col-md col-lg-4 footer-links"
            style={{ color: "white", marginTop: "8px" }}
          >
            © 2024
          </div>
        </div>
      </div>
    </footer>
  );
}
