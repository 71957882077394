import React, {useEffect} from 'react'
import {useOutletContext} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'
import OrderBody from '../OrderBody/OrderBody'
import s from './Offer.module.scss'

const Offer = props => {
  const {setBreadCrumb} = useOutletContext()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
    setBreadCrumb([
      {
        id: 1,
        title: 'Главная',
        href: '/shop',
      },
      {
        id: 2,
        title: 'Заказ',
      },
    ])
  }, [])

  return (
    <div className={s.body}>
      <div className={s.container}>
        <OrderBody />
      </div>
    </div>
  )
}

export default Offer
