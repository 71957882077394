import './Documentation.css'
import s from './Documentation.module.scss'
import {Breadcrumb} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useEffect} from 'react'
import scrollIntoView from 'scroll-into-view'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

export default function Documentation() {
  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])
  return (
    <>
      <div className="documentation-page-container">
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">Главная</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">Компания</Link>
          </span>
          <Breadcrumb.Item active>Нормативная документация</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">Нормативная документация</div>
        <div className={s.wrapper}>
          <p>
            <Link to="#">Указания по прокладке и эксплуатации</Link>
          </p>
          <p>
            <Link to="#">Руководство по эксплуатации</Link>
          </p>
          <p>
            <Link to="#">Справочная документация по кабелям</Link>
          </p>
        </div>
      </div>
    </>
  )
}
