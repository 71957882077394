import React from 'react'
import AdminTitle from '../ui/AdminTitle/AdminTitle'
import Tab from './Tab/Tab'
import s from './Tabs.module.scss'

const Tabs = ({setActiveTab, activeTab, categories}) => {
  return (
    <div className={s.container}>
      <AdminTitle title="Меню" />

      <div className={s.tabs}>
        {categories.map(cat => (
          <Tab key={cat.id} {...cat} active={activeTab === cat.id} setActiveTab={setActiveTab} />
        ))}
      </div>
    </div>
  )
}

export default Tabs
