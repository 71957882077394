import React from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import s from './TableForm.module.scss'

const TableForm = ({id, setValues, values, activeValues}) => {
  const [first, setFirst] = useState(activeValues[0])
  const [second, setSecond] = useState(activeValues[1])
  const [third, setThird] = useState(activeValues[2])
  const [fourth, setFourth] = useState(activeValues[3])
  const [fifth, setFifth] = useState(activeValues[4])

  useEffect(() => {
    let mutableValues = values

    mutableValues[id] = [
      String(first).split(',').length === 1 ? first : String(first).split(',') || '',
      String(second).split(',').length === 1 ? second : String(second).split(',') || '',
      String(third).split(',').length === 1 ? third : String(third).split(',') || '',
      String(fourth).split(',').length === 1 ? fourth : String(fourth).split(',') || '',
      String(fifth).split(',').length === 1 ? fifth : String(fifth).split(',') || '',
    ]

    setValues(mutableValues)
  }, [first, second, third, fourth, fifth])

  return (
    <>
      <td>
        <input
          type="text"
          className={s.input}
          value={first}
          onChange={e => setFirst(e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className={s.input}
          value={second}
          onChange={e => setSecond(e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className={s.input}
          value={third}
          onChange={e => setThird(e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className={s.input}
          value={fourth}
          onChange={e => setFourth(e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className={s.input}
          value={fifth}
          onChange={e => setFifth(e.target.value)}
        />
      </td>
    </>
  )
}

export default TableForm
