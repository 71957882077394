import { createSlice } from "@reduxjs/toolkit";
import { publicSearch } from "store/async/asyncSearch";
import { getProducts, loadMainProduct } from "../async/asyncCategories";

const initialState = {
  isLoading: true,
  data: [],
  product: [],
};

export const ProductsReducer = createSlice({
  name: "products",
  initialState,
  reducers: {
    clearProduct(state, info) {
      state.product = [];
    },
  },
  extraReducers: {
    [getProducts.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getProducts.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      let d = action.payload.data.data;
      state.data = d;
      state.activeProduct = action.payload.data.data;
    },
    [getProducts.rejected.type]: (state) => {
      state.isLoading = false;
    },
    [loadMainProduct.pending]: (state) => {
      state.isLoading = true;
    },
    [loadMainProduct.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.product = action.payload;
    },
    [loadMainProduct.rejected]: (state) => {
      state.isLoading = false;
    },
    [publicSearch.pending]: (state) => {
      state.isLoading = true;
    },
    [publicSearch.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data.data;
    },
    [publicSearch.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { clearProduct } = ProductsReducer.actions;

export default ProductsReducer.reducer;
