export const config = [
  {
    id: 16,
    title: "Об актуализации данных акционеров ОАО Беларускабель",
    link:
      "/shareholders/Об актуализации данных акционеров ОАО Беларускабель.docx",
  },
  {
    id: 17,
    title:
      "Информация о выплате дивидендов по акциям ОАО Беларускабель за 2023",
    link:
      "/shareholders/Информация о выплате дивидендов по акциям ОАО Беларускабель за 2023.pdf",
  },
  {
    id: 1,
    title: "О годовом собрании акционеров 29.03.2024",
    link: "/shareholders/О годовом собрании акционеров 29.03.2024.pdf",
  },
  {
    id: 2,
    title: "О формировании реестра акционеров ОАО Беларускабель на 26.02.2024",
    link:
      "/shareholders/О формировании реестра акционеров ОАО Беларускабель на 26.02.2024.pdf",
  },
  {
    id: 3,
    title: "О годовом собрании акционеров 24.03.2023",
    link: "/shareholders/О годовом собрании акционеров 24.03.2023.pdf",
  },
  {
    id: 4,
    title: "О формировании реестра акционеров ОАО Беларускабель на 22.02.2023",
    link:
      "/shareholders/О формировании реестра акционеров ОАО Беларускабель на 22.02.2023.pdf",
  },
  {
    id: 5,
    title: "Крупная взаимосвязанная кредитная сделка (29.11.2022)",
    link:
      "/shareholders/Крупная взаимосвязанная кредитная сделка (29.11.2022).pdf",
  },
  {
    id: 6,
    title: "Крупная взаимосвязанная залоговая сделка (29.11.2022)",
    link:
      "/shareholders/Крупная взаимосвязанная залоговая сделка (29.11.2022).pdf",
  },
  {
    id: 7,
    title: "О годовом собрании акционеров 25.03.2022",
    link: "/shareholders/О годовом собрании акционеров 25.03.2022.pdf",
  },
  {
    id: 8,
    title: "О формировании реестра акционеров ОАО Беларускабель на 22.02.2022",
    link:
      "/shareholders/О формировании реестра акционеров ОАО Беларускабель на 22.02.2022.pdf",
  },
  {
    id: 9,
    title: "Крупная взаимосвязанная сделка (10.11.2021)",
    link: "/shareholders/Крупная взаимосвязанная сделка от 10.11.2021.pdf",
  },
  {
    id: 10,
    title: "Крупная сделка (15.06.2021)",
    link: "/shareholders/Крупная сделка (15.06.2021).pdf",
  },
  {
    id: 11,
    title: "Крупная взаимосвяз.залоговая сделка (04.05.2021)",
    link: "/shareholders/Крупная взаимосвяз.залоговая сделка (04.05.2021).pdf",
  },
  {
    id: 12,
    title: "О годовом собрании акционеров 26.03.2021",
    link: "/shareholders/О годовом собрании акционеров 26.03.2021.pdf",
  },
  {
    id: 13,
    title: "О форм. реестра ОАО Беларускабель на 26.02.2021",
    link: "/shareholders/О форм. реестра ОАО Беларускабель на 26.02.2021.pdf",
  },
  {
    id: 14,
    title: "О годовом собрании акционеров 27.03.2020",
    link: "/shareholders/О годовом собрании акционеров 27.03.2020.pdf",
  },
  {
    id: 15,
    title: "О формировании реестра акционеров на 26.02.2020",
    link: "/shareholders/О формировании реестра акционеров на 26.02.2020.pdf",
  },
];
