import React, {useState} from 'react'
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {t} from 'i18next'
import s from './Dropdown.module.scss'

const Dropdown = ({activeCategory, setActiveCategory, allCategories, filter}) => {
  const [activeDropdown, setActiveDropdown] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (activeCategory[0]) {
      setActiveDropdown(
        allCategories.filter(category => category.name === activeCategory[0])[0]?.id
      )
    }
  }, [activeCategory[0]])

  return (
    <>
      <div
        key={filter.id}
        className={s.dropdown}
        onClick={() => {
          setActiveCategory([null, null])
          navigate('/products')
        }}
      >
        <div className={`${s.title}`} id="dropdown-basic">
          <span className="product-type-link">{t('Все виды кабелей')}</span>
        </div>
      </div>
      <div className={`${s.dropdownMenus}`}>
        {allCategories.map(category => (
          <>
            {category.subcategories.length ? (
              <div className={s.dropdown} key={category.id}>
                <div
                  className={`${s.title} ${activeDropdown === category.id ? s.active : ''}`}
                  onClick={() => {
                    if (activeDropdown === category.id) {
                      setActiveDropdown(null)
                    } else setActiveDropdown(category.id)
                  }}
                  id="dropdown-basic"
                >
                  <span className="product-type-link">
                    {category.name.split('')[0].toUpperCase() +
                      category.name.split('').slice(1, category.name.split('').length).join('')}
                  </span>
                  <img src="vectorDown.png" alt="vector " />
                </div>
                <div className={`${s.drops} ${activeDropdown === category.id ? s.active : ''}`}>
                  {category.subcategories &&
                    category.subcategories.map(cat => (
                      <div className={s.dropdown} key={cat.id}>
                        <p
                          className={`${s.itemDropdown} ${
                            activeCategory[1] === cat.name ? s.active : ''
                          }`}
                          onClick={() => {
                            setActiveCategory([category.id, cat.id])
                            navigate(
                              '/products?categoryId=' + category.id + '&subcategoryId=' + cat.id
                            )
                          }}
                        >
                          {cat.name.split('')[0].toUpperCase() +
                            cat.name.split('').slice(1, cat.name.split('').length).join('')}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div
                className={s.dropdown}
                key={category.id}
                onClick={() => {
                  setActiveCategory([category.id, null, null])
                  navigate('/products?categoryId=' + category.id)
                }}
              >
                <div className={`${s.title}`} id="dropdown-basic">
                  <span className="product-type-link">
                    {category.name.split('')[0].toUpperCase() +
                      category.name.split('').slice(1, category.name.split('').length).join('')}
                  </span>
                </div>
              </div>
            )}
          </>
        ))}
      </div>
    </>
  )
}

export default Dropdown
