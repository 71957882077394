import React from 'react'
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

const ErrorPage = props => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/')
  }, [])

  return (
    <div>
      <p></p>
    </div>
  )
}

export default ErrorPage
