import React, {useState} from 'react'
import {Link, useSearchParams} from 'react-router-dom'
import {addToCart} from '../../../store/reducers/ShoppingReducer';
import s from './Actions.module.scss'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import {getProducts} from '../../../store/async/asyncCategories'
import provod from '../../../media/main/default.png'
import scrollIntoView from 'scroll-into-view'

const Actions = ({activeCategory, activeIndustry, activePage, setActivePage}) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(true)
  const {data} = useSelector(store => store.products)
  const {showProducts} = useSelector(store => store.categories)
  const [activePages, setActivePages] = useState([])
  const [allPages, setAllPages] = useState([])
  const {language} = useSelector(store => store.language)
  const [searchParams, setSearchParams] = useSearchParams()



  useEffect(() => {
    if (!searchParams.get('customSearch')) {
      dispatch(getProducts(language))
    }
  }, [language])

  useEffect(() => {
    setIsOpen(true)
  }, [activeCategory, activeIndustry])

    var IdFilter = [
      "e1cec32a-72da-43a8-ae61-18f30d134c84",
      "9ceeae0b-4ea6-4e91-b1c0-7f8638673ff4",
      "2db00861-6f58-4e88-9e86-86224ccd1097",
      "9265be42-6851-478b-9479-648c3b23f3c0",
      "434907d2-e3c8-446a-97f5-452b25e9b5bf",
      "ec6803eb-6567-409f-9429-feb6d1f9a200",
      "bb4715ad-f934-402f-91d0-c9255495c84b",
      "e1c45fac-c132-4821-b8d3-dfd9cdc5476b",
      "1d5c703f-da86-4d2e-bfff-83a89657372c",
      "fbf75b79-fba7-424d-ad2c-03f2df680ead"

    ];

  var filtered = data.filter(item => IdFilter.includes(item.id))

  return (
    <div className={s.container}>


      <h1 className={s.title}>Товары по скидке</h1>

      <div className={s.cards}>
        {!data &&
          <h1>Ничего не найдено</h1>
        }
        {data && 
         filtered.map((card, idx) => (
                <div className={s.card} key={idx}>
                  <div className={s.logoImage}>
                    <img
                      src={
                        card.image
                          ? card.image.includes('https://')
                            ? card.image
                            : process.env.REACT_APP_API + '/uploads/' + card.image
                          : provod
                      }
                      alt={card.name}
                      className={s.image}
                    />
                  </div>
                  {card.oldPrice &&
                    <p className={s.oldPrice}>
                    <s>{card.oldPrice}</s>
                    <span>{card.discount}</span>
                  </p>
                  }
                  {card.price &&
                    <p className={s.price}>{card.price}р</p>
                  }
                  {!card.price && 
                 
                    <p className={s.price}>По запросу</p>
                  }
                  <p className={s.name}>{card.name}</p>

                  <div className={s.flexCard}>
                    <Link to={'/shop/product/' + card.id}>Подробнее</Link>
                    <span className={s.img} onClick={() => 
                      dispatch(addToCart({id: card.id, title: card.name, img: card.img, price: card.price, quantity: 1}))
                    }>
                      <svg width="26" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g
                          clipPath="url(#prefix__clip0_131_103)"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M9.764 21.833c.596 0 1.079-.444 1.079-.992 0-.549-.483-.993-1.08-.993-.595 0-1.078.445-1.078.993s.483.992 1.079.992zM21.63 21.833c.596 0 1.079-.444 1.079-.992 0-.549-.483-.993-1.079-.993s-1.079.445-1.079.993.483.992 1.079.992zM1.133.992h4.315L8.34 14.281c.1.457.37.867.764 1.16a2.28 2.28 0 001.394.438h10.486c.506.008 1-.147 1.394-.439.394-.292.665-.702.763-1.16l1.726-8.326H6.527" />
                        </g>
                        <defs>
                          <clipPath id="prefix__clip0_131_103">
                            <path
                              fill="#fff"
                              transform="translate(.055)"
                              d="M0 0h25.89v23.818H0z"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>
                </div>
              ))}
      </div>


    </div>
  )
}

export default Actions
