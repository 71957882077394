import React, {useEffect} from 'react'
import {Breadcrumb} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import s from './AgainstCorruption.module.scss'
import {useTranslation} from 'react-i18next'

const AgainsCorruption = () => {
  const {t} = useTranslation()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])

  return (
    <>
      <div className={s.container}>
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Компания')}</Link>
          </span>
          <Breadcrumb.Item active>{t('Противодействие коррупции')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t('Противодействие коррупции')}</div>
        <div
          className={s.text}
          dangerouslySetInnerHTML={{__html: `${t('Описание противодействия коррупции')}`}}
        ></div>
      </div>
    </>
  )
}

export default AgainsCorruption
