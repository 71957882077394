const excelExtensions = ["xls", "xlsb", "xlsm", "xlsx"];
const pdfExtensions = ["pdf"];
const docExtensions = ["doc", "docx"];

export function getFileExtension(fileName) {
  const extension = fileName.split(".").pop();

  return extension;
}

export const filesExtensions = [
  ["excel-icon", excelExtensions],
  ["pdf-icon", pdfExtensions],
  ["doc-icon", docExtensions],
].reduce((obj, [value, keys]) => {
  for (const key of keys) {
    Object.defineProperty(obj, key, { value });
  }
  return obj;
}, {});
