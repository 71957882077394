import React, {useEffect} from 'react'
import Header from '../PublicPage/Header/Header'
import Footer from '../PublicPage/Footer/Footer'
import Form from './Form/Form'
import s from './Auth.module.scss'
import {useDispatch, useSelector} from 'react-redux'
import {auth} from '../../store/async/asyncAuth'
import {useNavigate} from 'react-router-dom'

const AuthPage = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {email, password} = useSelector(store => store.auth)

  const logIn = ({email, password}) => {
    dispatch(auth({email, password}))
    navigate('/public')
  }

  useEffect(() => {
    if (email && password) {
      dispatch(auth({email, password}))
      navigate('/public')
    }
  }, [email, password])

  return (
    <div className={s.container}>
      <Form logIn={logIn} />
    </div>
  )
}

export default AuthPage
