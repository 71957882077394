import {createSlice} from '@reduxjs/toolkit'
import {
  addGoods,
  getProduct,
  logInAdmin,
  removeCategory,
  updateCategory,
  removeGood,
  setCategory,
  setSubcategory,
} from '../async/asyncAdmin'
import {getProducts} from '../async/asyncCategories'

const initialState = {
  isLoading: true,
  role: '',
  entity: '',
  jwt_token: '',
  isAuthenticated: false,
  editProduct: false,
}

export const AdminReducer = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    removeEditProduct: state => {
      state.editProduct = false
    },
  },
  extraReducers: {
    [setCategory.pending]: state => {
      state.isLoading = true
    },
    [setCategory.fulfilled]: (state, action) => {
      state.isLoading = false
    },
    [setCategory.rejected]: state => {
      state.isLoading = false
    },
    [logInAdmin.pending]: state => {
      state.isLoading = true
    },
    [logInAdmin.fulfilled]: (state, action) => {
      state.isLoading = false
      if (action.payload) {
        state.isAuthenticated = true
        state.jwt_token = action.payload.jwt_token
        state.entity = action.payload.entity
        state.role = action.payload.role
      }
    },
    [logInAdmin.rejected]: state => {
      state.isLoading = false
    },
    [removeCategory.pending]: state => {
      state.isLoading = true
    },

    [removeCategory.fulfilled]: state => {
      state.isLoading = false
    },

    [removeCategory.rejected]: state => {
      state.isLoading = false
    },

    [updateCategory.pending]: state => {
      state.isLoading = true
    },

    [updateCategory.fulfilled]: state => {
      state.isLoading = false
    },

    [updateCategory.rejected]: state => {
      state.isLoading = false
    },

    [setSubcategory.pending]: state => {
      state.isLoading = true
    },

    [setSubcategory.fulfilled]: state => {
      state.isLoading = false
    },

    [setSubcategory.rejected]: state => {
      state.isLoading = false
    },

    [addGoods.pending]: state => {
      state.isLoading = true
    },

    [addGoods.fulfilled]: state => {
      state.isLoading = false
    },

    [addGoods.rejected]: state => {
      state.isLoading = false
    },

    [getProduct.pending]: state => {
      state.isLoading = true
    },

    [getProduct.fulfilled]: (state, action) => {
      state.isLoading = false
      state.editProduct = action.payload
    },

    [getProduct.rejected]: state => {
      state.isLoading = false
    },

    [removeGood.pending]: state => {
      state.isLoading = true
    },

    [removeGood.fulfilled]: state => {
      state.isLoading = false
    },

    [removeGood.rejected]: state => {
      state.isLoading = false
    },

    [getProducts.pending]: state => {
      state.isLoading = true
    },

    [getProducts.fulfilled]: state => {
      state.isLoading = false
    },

    [getProducts.rejected]: state => {
      state.isLoading = false
    },
  },
})

// Action creators are generated for each case reducer function
export const {removeEditProduct} = AdminReducer.actions

export default AdminReducer.reducer
