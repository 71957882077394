import React, {useState} from 'react'
import Form from '../Form/Form'
import s from './Tabs.module.scss'
import {useTranslation} from 'react-i18next'

const FormTabs = ({registrate, logIn}) => {
  const [activeTab, setActiveTab] = useState(0)
  const {t} = useTranslation()

  return (
    <div className={s.container}>
      <div className={s.wrapper}>
        <div className={s.names}>
          <div
            className={`${s.name} ${!activeTab ? s.active : ''}`}
            onClick={() => setActiveTab(0)}
          >
            {t('Таб Авторизация')}
          </div>
          <div className={`${s.name} ${activeTab ? s.active : ''}`} onClick={() => setActiveTab(1)}>
            {t('Таб Регистрация')}
          </div>
        </div>
        <Form activeTab={activeTab} registrate={registrate} logIn={logIn} />
      </div>
    </div>
  )
}

export default FormTabs
