import './Testing.css'
import {Breadcrumb, Row, Col, Image} from 'react-bootstrap'
import {Fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'
import {Link} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

// fancybox
Fancybox.bind('[data-fancybox="gallery"]', {
  dragToClose: false,

  Toolbar: false,
  closeButton: 'top',

  Image: {
    zoom: false,
  },

  on: {
    initCarousel: fancybox => {
      const slide = fancybox.Carousel.slides[fancybox.Carousel.page]

      fancybox.$container.style.setProperty('--bg-image', `url("${slide.$thumb.src}")`)
    },
    'Carousel.change': (fancybox, carousel, to, from) => {
      const slide = carousel.slides[to]

      fancybox.$container.style.setProperty('--bg-image', `url("${slide.$thumb.src}")`)
    },
  },
})

export default function Testing() {
  const {t} = useTranslation()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])

  return (
    <>
      <div className="testing-page-container">
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Компания')}</Link>
          </span>
          <Breadcrumb.Item active>{t('Центральная заводская лаборатория')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t('Центральная заводская лаборатория')}</div>
        <div className="quality-page-info">
          <Row className="quality-page-row">
            <Col md={3} className="quality-image-section">
              <Image className="testing-page-images" src="foto1.jpg" alt="testing page" />
            </Col>
            <Col md={9}>
              <Row>
                <div
                  className="quality-page-text"
                  dangerouslySetInnerHTML={{
                    __html: `${t('Центральная заводская лаборатория ОАО «Беларускабель»')}`,
                  }}
                ></div>
              </Row>
            </Col>
          </Row>
          <hr></hr>
          <div className="testing-wrapper">
            <a data-fancybox="gallery" href="testing01.jpg">
              <img src="testing01.jpg" alt="testing" />
            </a>
            <a data-fancybox="gallery" href="testing02.jpg">
              <img src="testing02.jpg" alt="testing" />
            </a>
            <a data-fancybox="gallery" href="testing03.jpg">
              <img src="testing03.jpg" alt="testing" />
            </a>
            <a data-fancybox="gallery" href="testing04.jpg">
              <img src="testing04.jpg" alt="testing" />
            </a>
          </div>
          <hr></hr>
          <br></br>
          <br></br>
          <Row className="quality-page-row">
            <div
              className="quality-page-text"
              dangerouslySetInnerHTML={{
                __html: `${t(
                  'Подробная информация об области аккредитации находится в реестре аккредитованных испытательных лабораторий'
                )}`,
              }}
            ></div>
          </Row>
        </div>
      </div>
    </>
  )
}
