import React from 'react'
import s from './Banner.module.scss'
import {Link} from 'react-router-dom'

const Banner = props => {
  return (
    <div className={s.banner}>
      <div className={s.flex}>
        <p className={s.title}>
          У нас можно преобрести <br /> продукцию в рассрочку
        </p>
        <p className={s.paragraph}>от 3 месяцев по картам более 8 банков.</p>
        <div className={s.link}>
          <Link to="/shop/rassrochka">Подробнее</Link>
        </div>
      </div>
    </div>
  )
}

export default Banner
