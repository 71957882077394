import { useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./componentsMain/PublicPage/Footer/Footer";
import Header from "./componentsMain/PublicPage/Header/Header";

const Layout2 = () => {
  const [breadcrumb, setBreadCrumb] = useState([
    {
      id: 1,
      title: "Главная",
      href: "/shop",
    },
    {
      id: 2,
      title: "Каталог",
      href: "/shop",
    },
  ]);

  return (
    <>
      <Header breadcrumb={breadcrumb} />
      <Outlet context={{ setBreadCrumb }} />
      <Footer />
    </>
  );
};

export default Layout2;
