import React, {useState} from 'react'
import s from './DefaultForm.module.scss'
import Button from '../../ui/Button/Button'
import {useDispatch} from 'react-redux'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Select from '../../ui/Select/Select'
import {setSubcategory} from '../../../../store/async/asyncAdmin'

const schema = yup
  .object({
    category: yup.string(),
    subCategory: yup.string(),
  })
  .required()

const DefaultForm = ({jwt, values}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const [activeCategory, setActiveCategory] = useState(false)
  const [activeDeleteElement, setActiveDeleteElement] = useState(0)
  const dispatch = useDispatch()

  const onSubmit = data => {
    dispatch(setSubcategory({name: data.subCategory, categoryId: activeDeleteElement, jwt}))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
      <Select
        activeValue={activeCategory}
        setActiveValue={setActiveCategory}
        values={values}
        placeholder={'Выберите категорию'}
        setIsSelectOpen={setIsSelectOpen}
        isSelectOpen={isSelectOpen}
        id={1}
        setActiveDeleteElement={setActiveDeleteElement}
      />
      <input
        {...register('subCategory')}
        className={`${s.input} ${s.last}`}
        placeholder="Подкатегория"
      />
      {errors.category && (
        <p>
          <span className={s.error}>{errors.category?.message}</span>
        </p>
      )}
      {errors.subCategory && (
        <p>
          <span className={s.error}>{errors.subCategory?.message}</span>
        </p>
      )}

      <Button text="Добавить" />
    </form>
  )
}

export default DefaultForm
