import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import Alert from 'react-bootstrap/Alert';
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {useTranslation} from 'react-i18next'
import s from './SendForm.module.scss'
import 'yup-phone'
import {useDispatch} from "react-redux";
import {sendFeedbackEmail} from "../../../store/async/asyncFeedback.js";

const SendForm = ({form, setForm,}) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const dispatch = useDispatch();
    const {t} = useTranslation()

    const schema = yup
        .object({
            email: yup
                .string()
                .email(`${t('Неправильный email')}`)
                .required(`${t('Необходимо ввести email')}`),
            company: yup
                .string('Поле должно быть заполнено')
                .required(`${t('Необходимо ввести пароль')}`),
            name: yup.string('Поле должно быть заполнено'),
            textarea: yup.string('Поле должно быть заполнено'),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
    })

    const onSubmitLogin = async data => {
      setIsSuccess(true);
      setTimeout(() => {
        dispatch(sendFeedbackEmail({
            data,
            lang: 'ru'
        }));
        setIsSuccess(false);
        setForm(false);
      }, 1500);
    }

    return (
        <div className={s.container}>
            <span className={s.cross} onClick={() => setForm(!form)}></span>
            <p className="commonLabels">Оставить заявку</p>
            <div className={s.wrapper}>
                {isSuccess && (
                    <Alert variant="success">
                        Заявка успешно отправлена!
                    </Alert>
                )}

                <form onSubmit={handleSubmit(onSubmitLogin)}>
                    <input
                        type="text"
                        className={s.defaultInput}
                        placeholder="Компания"
                        {...register('company', {required: true})}
                    />
                    {errors.email && <p>{errors.company?.message}</p>}
                    <input
                        type="text"
                        className={s.defaultInput}
                        placeholder={t('Слово Имя')}
                        {...register('name')}
                    />
                    {errors.password && <p>{errors.name?.message}</p>}
                    <input
                        type="text"
                        className={s.defaultInput}
                        placeholder={'Email'}
                        {...register('email', {required: true})}
                    />
                    {errors.email && <p>{errors.email?.message}</p>}
                    <input
                        type="text"
                        className={s.defaultInput}
                        placeholder={t('Слово Телефон')}
                        {...register('phone')}
                    />

                    <textarea
                        className={`${s.defaultInput} ${s.textarea}`}
                        placeholder={'Текст комментария'}
                        {...register('commit')}
                    ></textarea>

                    <p className={s.buttonWrapper}>
                        <button type="submit" className={s.button}>
                            Отправить заявку
                        </button>
                    </p>
                </form>
            </div>
        </div>
    )
}

export default SendForm
