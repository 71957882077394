export const shareholders = {
  ru: {
    translation: {
    },
  },
  en: {
    translation: {
    },
  },
  bel: {
    translation: {
  },
}
}
