import './IndustriesList.css'
import '../../../index.css'
import {useNavigate} from 'react-router-dom'
import s from '../../ProductPage/ProductPage.module.scss'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {useCallback, useEffect} from 'react'
import {getIndustries} from '../../../store/async/asyncCategories'
import {industryDescription} from './data.js';

export default function IndustriesList() {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {industries} = useSelector(store => store.categories)
  const {language} = useSelector(store => store.language)

  useEffect(() => {
    dispatch(getIndustries(language))
  }, [language, dispatch]);

  const findDescription = useCallback((name) => industryDescription[language]?.[name] || '', [language]);

  return (
    <>
      <div className="industry-list-container">
        <div className="industry-label">{t('Отрасли применения продукции')}</div>
        <div className={s.cards}>
          {industries.legal &&
            industries.legal.map(ind => (
              <div
                key={ind.id}
                onClick={() => navigate('/products?industriesId=' + ind.id)}
                className={`${s.card} ${s.default}`}
              >
                <div className={s.cardWrapper}>
                  <img src={ind.image} alt="img" className={s.itemImage} />
                </div>
                <div className={s.cardContent}>
                  <p className={s.cardTitle}>{ind.name}</p>
                  <p className={s.cardParagraph}>{findDescription(ind.name)}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
