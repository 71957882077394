import React, {useState} from 'react'
import s from './Tab.module.scss'
import arrow from '../../../../media/main/arrow.svg'
import {useEffect} from 'react'

const AccordionItem = ({activeIndex, title, paragraph, id, setActiveIndex}) => {
  const [activeHeight, setActiveHeight] = useState()

  const measureHeight = () => {
    const div = document.createElement('div')

    document.body.querySelector(`.${s.flex}`).append(div)
    div.innerHTML = paragraph

    setActiveHeight(div.clientHeight)

    div.remove()
  }

  useEffect(() => {
    measureHeight()
  }, [activeIndex])

  let style = {
    maxHeight: `${activeIndex === id ? activeHeight + 15 + 'px' : 0}`,
  }

  if (!title) return null

  return (
    <div
      className={`${activeIndex === id ? s.active : ''}`}
      onClick={() => {
        if (activeIndex === id) return setActiveIndex(0)
        setActiveIndex(id)
      }}
    >
      <div className={s.flex}>
        <div>
          <p className={s.title}>{title}</p>
          <img src={arrow} className={`${s.img} ${activeIndex === id ? s.active : ''}`} alt="" />
        </div>
        <p
          className={`${s.paragraph} ${activeIndex === id ? s.active : ''}`}
          style={style}
          dangerouslySetInnerHTML={{__html: paragraph}}
        ></p>
      </div>
    </div>
  )
}

export default AccordionItem
