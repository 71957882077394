import './SecondBanner.css'
import {Col} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'

export default function SecondBanner() {
  const {t} = useTranslation()

  return (
    <>
      <div className="second-banner-container">
        {/*<img className="second-banner-image" src="hpBanner2.jpeg" alt="banner" />*/}
        <div className={'second-banner-wrapper'}>
          <div className="second-banner-info">
            <div>
              <Col>
                <div className="commonLabels" style={{textAlign: 'left'}}>
                  {t('Заг Преимущества')}
                </div>
              </Col>
            </div>
            <div className="second-banner-common-grid">
              <div classname="second-banner-item">
                <div className="label-amount"><img src="/icon/icon-1.png" /></div>
                <div className="value-description">{t('Широкая производимая номенклатура кабельных изделий')}</div>
              </div>
              <div classname="second-banner-item">
                <div className="label-amount"><img src="/icon/icon-2.png" /></div>
                <div className="value-description">{t('Наличие складской программы')}</div>
              </div>  
              <div classname="second-banner-item">
                <div className="label-amount"><img src="/icon/icon-3.png" /></div>
                <div className="value-description">{t('Короткие сроки изготовления')}</div>
              </div>                 
              <div classname="second-banner-item">
                <div className="label-amount"><img src="/icon/icon-4.png" /></div>
                <div className="value-description">{t('Высококвалифицированный персонал')}</div>
              </div>   
              <div classname="second-banner-item">
                <div className="label-amount"><img src="/icon/icon-5.png" /></div>
                <div className="value-description">{t('Возможность доставки и самовывоза')}</div>
              </div>    
              <div classname="second-banner-item">
                <div className="label-amount"><img src="/icon/icon-6.png" /></div>
                <div className="value-description">{t('Выгодная стоимость')}</div>
              </div> 
              <div classname="second-banner-item">
                <div className="label-amount"><img src="/icon/icon-7.png" /></div>
                <div className="value-description">{t('Качество подтверждено сертификатами')}</div>
              </div>   
              <div classname="second-banner-item">
                <div className="label-amount"><img src="/icon/icon-8.png" /></div>
                <div className="value-description">{t('Сертифицированная система менеджмента качества')}</div>
              </div>  
              <div classname="second-banner-item">
                <div className="label-amount"><img src="/icon/icon-9.png" /></div>
                <div className="value-description">{t('Проведение испытаний 100% продукции')}</div>
              </div>     
              <div classname="second-banner-item">
                <div className="label-amount"><img src="/icon/icon-10.png" /></div>
                <div className="value-description">{t('Своевременная модернизация и внедрение инноваций на производстве')}</div>
              </div>   
              <div classname="second-banner-item">
                <div className="label-amount"><img src="/icon/icon-11.png" /></div>
                <div className="value-description">{t('История традиций по производству кабельной продукции с 1959 г')}</div>
              </div>                                                                                                            
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
