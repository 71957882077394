import React, {useEffect} from 'react'
import CardBody from './CardBody/CardBody'
import s from './Product.module.scss'
import Tabs from './Tabs/Tabs'
import SecondSlider from '../PublicPage/SecondSlider/SecondSlider'
import Slider from '../PublicPage/Slider/Slider'
import scrollIntoView from 'scroll-into-view'
import {useParams, useOutletContext} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {getProductForSite} from '../../store/async/asyncCategories'

const Product = props => {
  const {setBreadCrumb} = useOutletContext()
  const {language} = useSelector(store => store.language)
  const {id} = useParams()
  const dispatch = useDispatch()
  const {data} = useSelector(store => store.publicCatalog)

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })

    dispatch(getProductForSite({id, lang: language}))

    setBreadCrumb([
      {
        id: 1,
        title: 'Главная',
        href: '/shop',
      },
      {
        id: 2,
        title: 'Каталог',
        href: '/shop',
      },
      {id: 3, title: 'Кабель для систем видеонаблюдения', href: '/public'},
      {id: 4, title: 'КВК-1'},
    ])
  }, [])

  return (
    <div className={s.body}>
      <div className={s.container}>
        <CardBody data={data} />
        <Tabs data={data} />
        <Slider title={'Лидеры продаж'} />
        <SecondSlider title={'Товары по скидке'} />
      </div>
    </div>
  )
}

export default Product
