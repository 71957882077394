import React, {useState} from 'react'
import {Link, useSearchParams} from 'react-router-dom'
import {addToCart} from '../../../store/reducers/ShoppingReducer';
import s from './Bestsellers.module.scss'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import {getProducts} from '../../../store/async/asyncCategories'
import provod from '../../../media/main/default.png'
import scrollIntoView from 'scroll-into-view'

const Bestsellers = ({activeCategory, activeIndustry, activePage, setActivePage}) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(true)
  const {data} = useSelector(store => store.products)
  const {showProducts} = useSelector(store => store.categories)
  const [activePages, setActivePages] = useState([])
  const [allPages, setAllPages] = useState([])
  const {language} = useSelector(store => store.language)
  const [searchParams, setSearchParams] = useSearchParams()



  useEffect(() => {
    if (!searchParams.get('customSearch')) {
      dispatch(getProducts(language))
    }
  }, [language])

  useEffect(() => {
    setIsOpen(true)
  }, [activeCategory, activeIndustry])

    var IdFilter = [
    "d9006909-d576-4c75-9b61-76f2cf919819",
    "047acf05-da88-43d3-9277-f49b333ae6ec",
    "ce91fb6d-6145-43e7-832a-2f24d4b65c6f",
    "fba3acb1-bd16-4c73-9be9-1018e44e74bb",
    "f4bf5b71-064b-4fca-8dd2-1320b5d994fb",
    "61b13042-3c33-40e5-a13a-cee9eed7b749",
    "b8eb6f43-2e89-4f93-8328-74b978ddda1a",
    "d84d11e1-da24-46f2-aba4-a033d63beed9",
    "43147799-a192-4c68-ada6-06b590d23e98",
    "7d7e8930-14ab-4624-a770-f2feb5caafbe",
    "547bd06d-7690-48c5-815b-495b62bef907",
    "32f2dc59-7e6a-44a7-a7e3-90f5cf967031"
  ];

  var filtered = data.filter(item => IdFilter.includes(item.id))

  return (
    <div className={s.container}>


      <h1 className={s.title}>Лидеры продаж</h1>

      <div className={s.cards}>
        {!data &&
          <h1>Ничего не найдено</h1>
        }
        {data && 
         filtered.map((card, idx) => (
                <div className={s.card} key={idx}>
                  <div className={s.logoImage}>
                    <img
                      src={
                        card.image
                          ? card.image.includes('https://')
                            ? card.image
                            : process.env.REACT_APP_API + '/uploads/' + card.image
                          : provod
                      }
                      alt={card.name}
                      className={s.image}
                    />
                  </div>
                  {card.oldPrice &&
                    <p className={s.oldPrice}>
                    <s>{card.oldPrice}</s>
                    <span>{card.discount}</span>
                  </p>
                  }
                  {card.price &&
                    <p className={s.price}>{card.price}р</p>
                  }
                  {!card.price && 
                 
                    <p className={s.price}>По запросу</p>
                  }
                  <p className={s.name}>{card.name}</p>

                  <div className={s.flexCard}>
                    <Link to={'/shop/product/' + card.id}>Подробнее</Link>
                    <span className={s.img} onClick={() => 
                      dispatch(addToCart({id: card.id, title: card.name, img: card.img, price: card.price, quantity: 1}))
                    }>
                      <svg width="26" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g
                          clipPath="url(#prefix__clip0_131_103)"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M9.764 21.833c.596 0 1.079-.444 1.079-.992 0-.549-.483-.993-1.08-.993-.595 0-1.078.445-1.078.993s.483.992 1.079.992zM21.63 21.833c.596 0 1.079-.444 1.079-.992 0-.549-.483-.993-1.079-.993s-1.079.445-1.079.993.483.992 1.079.992zM1.133.992h4.315L8.34 14.281c.1.457.37.867.764 1.16a2.28 2.28 0 001.394.438h10.486c.506.008 1-.147 1.394-.439.394-.292.665-.702.763-1.16l1.726-8.326H6.527" />
                        </g>
                        <defs>
                          <clipPath id="prefix__clip0_131_103">
                            <path
                              fill="#fff"
                              transform="translate(.055)"
                              d="M0 0h25.89v23.818H0z"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>
                </div>
              ))}
      </div>


    </div>
  )
}

export default Bestsellers
