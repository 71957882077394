import React, {useEffect, useState} from 'react'
import s from './Topic.module.scss'
import scrollIntoView from 'scroll-into-view'
import Content from './Content'
import {useOutletContext, useSearchParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {getCategories, getIndustries} from '../../../store/async/asyncCategories'

const Topic = props => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeIndustry, setActiveIndustry] = useState([])
  const {setBreadCrumb} = useOutletContext()
  const {industries} = useSelector(store => store.categories)
  const {language} = useSelector(store => store.language)
  const dispatch = useDispatch()

  useEffect(() => {

    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })

    dispatch(getIndustries(language))
    dispatch(getCategories(language))
  }, [language])

  return (
    <div className={s.body}>
      <Content
        setActiveIndustry={setActiveIndustry}
        activeIndustry={activeIndustry}
        industries={industries.physical}
        setBreadCrumb={setBreadCrumb}
      />
    </div>
  )
}

export default Topic
