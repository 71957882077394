import React from "react";
import {
  Layout,
  FaqQuestion,
  FaqAnswer,
  QuestionWrapper,
  ActiveBar,
  Wrapper,
  ArrowIcon,
} from "./styled";
import { useToggle } from "hooks/useToggle";
import AnimateHeight from "react-animate-height";

export default function FaqNested({ question, answer }) {
  const [isOpen, setIsOpen] = useToggle();

  return (
    <Layout isOpen={isOpen}>
      <ActiveBar className="faq-active-bar" />
      <Wrapper>
        <QuestionWrapper>
          <ArrowIcon
            className="faq-arrow-icon"
            src="svg/arrow-right.svg"
            alt="arrow-right"
          />
          <FaqQuestion className="faq-question" onClick={setIsOpen}>
            {question}
          </FaqQuestion>
        </QuestionWrapper>
        <AnimateHeight duration={500} height={isOpen ? "auto" : 0}>
          <FaqAnswer className="faq-answer">{answer}</FaqAnswer>
        </AnimateHeight>
      </Wrapper>
    </Layout>
  );
}
