export const leaders = {
  ru: {
    translation: {
      'КУПД, КВПД, МКЭШВ': 'КУПД, КВПД, МКЭШВ',
      'КАБЕЛИ УПРАВЛЕНИЯ': 'КАБЕЛИ УПРАВЛЕНИЯ',
      'ВВГ, КВВГ, КВВГЭ': 'ВВГ, КВВГ, КВВГЭ',
      'СИЛОВОЙ И КОНТРОЛЬНЫЙ КАБЕЛЬ': 'СИЛОВОЙ И КОНТРОЛЬНЫЙ КАБЕЛЬ',
      'ТППэп, ТППШв, КВП': 'ТППэп, ТППШв, КВП',
      'КАБЕЛИ И ПРОВОДА СВЯЗИ': 'КАБЕЛИ И ПРОВОДА СВЯЗИ',
      'СБЗПу (для железной дороги)': 'СБЗПу (для железной дороги)',
      'СИГНАЛЬНО-БЛОКИРОВАЧНЫЙ': 'СИГНАЛЬНО-БЛОКИРОВАЧНЫЙ',
      'БПДО, МСТП, МЛТП': 'БПДО, МСТП, МЛТП',
      'ПРОВОДА ТЕПЛОСТОЙКИЕ': 'ПРОВОДА ТЕПЛОСТОЙКИЕ',
      'МНВ, МГШВ, НВ': 'МНВ, МГШВ, НВ',
      'ПРОВОДА МОНТАЖНЫЕ': 'ПРОВОДА МОНТАЖНЫЕ',
      'Лидеры продаж': 'Лидеры продаж',
      'По категориям': 'По категориям',
      'По отраслям': 'По отраслям',
      'Сл ПРОДУКЦИЯ': 'ПРОДУКЦИЯ',
      'Все виды кабелей': 'Все виды кабелей',
    },
  },
  en: {
    translation: {
      'КУПД, КВПД, МКЭШВ': 'KUPD, KVPD, MKEShV',
      'КАБЕЛИ УПРАВЛЕНИЯ': 'CONTROL CABLES',
      'ВВГ, КВВГ, КВВГЭ': 'VVG, KVVG, KVVGE',
      'СИЛОВОЙ И КОНТРОЛЬНЫЙ КАБЕЛЬ': 'POWER AND CONTROL CABLES',
      'ТППэп, ТППШв, КВП': 'TPPep, TPPSHv, KVP',
      'КАБЕЛИ И ПРОВОДА СВЯЗИ': 'COMMUNICATION CABLES AND WIRES',
      'СБЗПу (для железной дороги)': 'SBZPu (for the railway)',
      'СИГНАЛЬНО-БЛОКИРОВАЧНЫЙ': 'SIGNAL-BLOCKING CABLES',
      'БПДО, МСТП, МЛТП': 'BPDO, MSTP, MLTP',
      'ПРОВОДА ТЕПЛОСТОЙКИЕ': 'HEAT-RESISTANT WIRES',
      'МНВ, МГШВ, НВ': 'MNV, MGSHV, NV',
      'ПРОВОДА МОНТАЖНЫЕ': 'INSTALLATION WIRES',
      'Лидеры продаж': 'Sales leaders',
      'По категориям': 'By Category',
      'По отраслям': 'By industry',
      'Сл ПРОДУКЦИЯ': 'PRODUCTS',
      'Все виды кабелей': 'All types of cables',
    },
  },
  bel: {
    translation: {
      'КУПД, КВПД, МКЭШВ': 'КУПД, КВПД, МКЭШВ',
      'КАБЕЛИ УПРАВЛЕНИЯ': 'КАБЕЛІ КІРАВАННЯ',
      'ВВГ, КВВГ, КВВГЭ': 'ВВГ, КВВГ, КВВГЭ',
      'СИЛОВОЙ И КОНТРОЛЬНЫЙ КАБЕЛЬ': 'СІЛАВЫ І КАНТРОЛЬНЫ КАБЕЛЬ',
      'ТППэп, ТППШв, КВП': 'ТППэп, ГППШв, КВП',
      'КАБЕЛИ И ПРОВОДА СВЯЗИ': 'КАБЕЛІ І ПРАВОДА СУВЯЗІ',
      'СБЗПу (для железной дороги)': 'СБЗПу (для чыгункі)',
      'СИГНАЛЬНО-БЛОКИРОВАЧНЫЙ': 'Сігнальна-блакавальны',
      'БПДО, МСТП, МЛТП': 'БПДА, МСТП, МЛТП',
      'ПРОВОДА ТЕПЛОСТОЙКИЕ': 'Правады цепластойкія',
      'МНВ, МГШВ, НВ': 'МНВ, МДШВ, НВ',
      'ПРОВОДА МОНТАЖНЫЕ': 'ПРАВОДЫ МАНТАЖНЫЯ',
      'Лидеры продаж': 'Лідэры продажаў',
      'По категориям': 'Па катэгорыях',
      'По отраслям': 'Па галінах',
      'Сл ПРОДУКЦИЯ': 'ПРАДУКЦЫЯ',
      'Все виды кабелей': 'Усе віды кабеляў',
    },
  },
}
