import {useDispatch} from 'react-redux'
import {auth, registration} from '../../store/async/asyncAuth'
import FormTabs from './Tabs/Tabs'
import s from './extra-styles.module.scss'
import {useTranslation} from 'react-i18next'
import SendForm from './SendForm/SendForm'
import {changeModalShow} from '../../store/reducers/AuthorizeReducer'

export const MyVerticallyCenteredModal = ({show, setModalShow}) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  // const {email, password} = useSelector(store => store.auth)

  const logIn = ({email, password}) => {
    dispatch(auth({email, password}))
  }

  const registrate = ({name, email, password}) => {
    dispatch(
      registration({
        name,
        email,
        password,
      })
    )

    dispatch(auth({email, password}))
  }

  return (
    <div className={`${s.modal} ${show && s.active}`}>
      <div
        className={`${s.modalWrapper} ${show && s.active}`}
        onClick={() => {
          dispatch(changeModalShow())
        }}
      ></div>
      <div className={s.modalWindow}>
        <FormTabs registrate={registrate} logIn={logIn} />
      </div>
    </div>
  )
}

export const MyForm = ({form, setForm}) => {
  return (
    <div className={`${s.modal} ${form && s.active}`}>
      <div className={`${s.modalWrapper} ${form && s.active}`} onClick={() => setForm(!form)}></div>
      <div className={s.modalWindow}>
        <SendForm form={form} setForm={setForm} />
      </div>
    </div>
  )
}
