import './PurchasePlace.css'
import {Row, Col, Breadcrumb} from 'react-bootstrap'
import {Map, Placemark, YMaps} from 'react-yandex-maps'
import {Link} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'
import {useEffect} from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

export default function PurchasePlace() {
  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])
  return (
    <>
      <div className="place-page-container">
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">Главная</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">Компания</Link>
          </span>
          <Breadcrumb.Item active>Где купить</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">Где купить</div>
        <Row className="place-page-row">
          <Col>
            <Row>
              <div className="place-page-info">
                <span style={{color: '#E74137', textTransform: 'uppercase', fontSize: '24px'}}>
                  ОАО «БЕЛАРУСКАБЕЛЬ»
                </span>
                <p>Республика Беларусь, г. Мозырь, 247760, ул. Октябрьская 14</p>

                <span style={{color: '#343753', fontWeight: '600', fontSize: '24px'}}>
                  Фирменный магазин
                </span>
                <p>
                  время работы: <br /> вторник-пятница c 9:00 до 18:00 (обед 14:30-15:30) суббота c
                  9:00 до 15:00 <br /> воскресенье, понедельник - выходной <br />{' '}
                  <b>+375 (236) 37-25-75</b>
                </p>
              </div>
            </Row>
          </Col>
          <Col>
            <Row>
              <YMaps>
                <div className="map">
                  <Map height={323} defaultState={{center: [52.053654, 29.241555], zoom: 9}}>
                    <Placemark geometry={[52.053654, 29.241555]} />
                  </Map>
                </div>
              </YMaps>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  )
}
