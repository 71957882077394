export const modal = {
  ru: {
    translation: {
      'Таб Авторизация': 'Авторизация',
      'Таб Регистрация': 'Регистрация',
      'Необходимо ввести имя': 'Необходимо ввести имя',
      'Неправильный email': 'Неправильный email',
      'Необходимо ввести email': 'Необходимо ввести email',
      'Необходимо ввести название компании': 'Необходимо ввести название компании',
      'Неправильный номер телефон': 'Неправильный номер телефон',
      'Необходимо ввести номер телефона': 'Необходимо ввести номер телефона',
      'Слишком слабый пароль': 'Слишком слабый пароль',
      'Необходимо ввести пароль': 'Необходимо ввести пароль',
      'Слишком слабый пароль': 'Слишком слабый пароль',
      'Необходимо ввести пароль': 'Необходимо ввести пароль',
      'Слово Имя': 'Имя',
      'Название компании': 'Название компании',
      'Слово Телефон': 'Телефон',
      'Слово Пароль': 'Пароль',
      'Повторите пароль': 'Повторите пароль',
      'Таб Зарегистрироваться': 'Зарегистрироваться',
      'Слово Авторизоваться': 'Авторизоваться',
    },
  },
  en: {
    translation: {
      'Таб Авторизация': 'Authorization',
      'Таб Регистрация': 'Registration',
      'Необходимо ввести имя': 'Name required',
      'Неправильный email': 'Wrong email',
      'Необходимо ввести email': 'Email required',
      'Необходимо ввести название компании': 'Company name required',
      'Неправильный номер телефон': 'Wrong phone number',
      'Необходимо ввести номер телефона': 'Phone number required',
      'Слишком слабый пароль': 'Too weak password',
      'Необходимо ввести пароль': 'Password required',
      'Слишком слабый пароль': 'Too weak password',
      'Необходимо ввести пароль': 'Password required',
      'Слово Имя': 'Name',
      'Название компании': 'Company name',
      'Слово Телефон': 'Telephone',
      'Слово Пароль': 'Password',
      'Повторите пароль': 'Repeat password',
      'Таб Зарегистрироваться': 'Register',
      'Слово Авторизоваться': 'Login',
    },
  },
  bel: {
    translation: {
      'Таб Авторизация': 'Аўтарызацыя',
      'Таб Регистрация': 'Рэгістрацыя',
      'Необходимо ввести имя': 'Неабходна ўвесці імя',
      'Неправильный email': 'Няправільны email',
      'Необходимо ввести email': 'Неабходна ўвесці email',
      'Необходимо ввести название компании': 'Неабходна ўвесці назву кампаніі',
      'Неправильный номер телефон': 'Няправільны нумар тэлефон',
      'Необходимо ввести номер телефона': 'Неабходна ўвесці нумар тэлефона',
      'Слишком слабый пароль': 'Занадта слабы пароль',
      'Необходимо ввести пароль': 'Неабходна ўвесці пароль',
      'Слишком слабый пароль': 'Занадта слабы пароль',
      'Необходимо ввести пароль': 'Неабходна ўвесці пароль',
      'Слово Имя': 'Імя',
      'Название компании': 'Назва кампаніі',
      'Слово Телефон': 'Тэлефон',
      'Слово Пароль': 'Пароль',
      'Повторите пароль': 'Паўтарыце пароль',
      'Таб Зарегистрироваться': 'Зарэгістравацца',
      'Слово Авторизоваться': 'Аўтарызавацца',
    },
  },
}
