export const dillers = {
  ru: {
    translation: {
      'Юридический адрес': 'Юридический адрес',
      'Генеральный директор': 'Генеральный директор',
      'Заголовок Телефоны': 'Телефоны',
      'Электронная почта': 'Электронная почта',
      'Адрес сайта': 'Адрес сайта',
      'Россия, 150044, Ярославская обл, г. Ярославль, ул. Полушкина роща, д. 16, строение 46а, помещение 4':
        'Россия, 150044, Ярославская обл, г. Ярославль, ул. Полушкина роща, д. 16, строение 46а, помещение 4',
      'Россия, 192102, Санкт-Петербург г, вн. тер. г. муниципальный округ Волковское, пр-кт Волковский, д. 146, к. 1, литера А, помещ. 2Н, офис 26':
        'Россия, 192102, Санкт-Петербург г, вн. тер. г. муниципальный округ Волковское, пр-кт Волковский, д. 146, к. 1, литера А, помещ. 2Н, офис 26',
      'Россия, 109341 г. Москва, ул. Люблинская, д. 151, помещение 316/2':
        'Россия, 109341 г. Москва, ул. Люблинская, д. 151, помещение 316/2',
      'Россия, 115409, г. Москва, Каширское шоссе, д. 33, корп. 6 эт. 2 пом. 1 ком 24':
        'Россия, 115409, г. Москва, Каширское шоссе, д. 33, корп. 6 эт. 2 пом. 1 ком 24',
      'Российская Федерация, 109428, г. Москва, Рязанский пр-т, д.10, стр. 18, помещ. 11/8, офис 8.3/805':
        'Российская Федерация, 109428, г. Москва, Рязанский пр-т, д.10, стр. 18, помещ. 11/8, офис 8.3/805',  
      'Российская Федерация, 119034, г. Москва, Лопухинский переулок д.5, стр.4, каб.2, эт.2':
        'Российская Федерация, 119034, г. Москва, Лопухинский переулок д.5, стр.4, каб.2, эт.2',
      'Российская Федерация, 115088, г. Москва, Южнопортовая улица, дом 7, стр. 12':
        'Российская Федерация, 115088, г. Москва, Южнопортовая улица, дом 7, стр. 12',
      'Российская Федерация, 614500, Пермский край, р-н Пермский, д. Хмели, ш. Космонавтов, 320А':
        'Российская Федерация, 614500, Пермский край, р-н Пермский, д. Хмели, ш. Космонавтов, 320А',
      'Республика Казахстан, г. Астана, ул. Желтоксан, 48/1, оф. 28':
        'Республика Казахстан, г. Астана, ул. Желтоксан, 48/1, оф. 28',        
      'Незговоров Денис Геннадьевич': 'Незговоров Денис Геннадьевич',
      'Васильев Андрей Александрович': 'Васильев Андрей Александрович',
      'Топоров Игорь Валерьевич': 'Топоров Игорь Валерьевич',
      'Топорова Ирина Викторовна': 'Топорова Ирина Викторовна',
      'Сулягин Дмитрий Александрович': 'Сулягин Дмитрий Александрович',
      'Колосов Владимир Борисович': 'Колосов Владимир Борисович',
      'Юнев Александр Александрович': 'Юнев Александр Александрович',
      'Кузьмин Сергей Андреевич': 'Кузьмин Сергей Андреевич',
      'Ергалиев Ерлан Алшинович': 'Ергалиев Ерлан Алшинович',
      'Общество с ограниченной ответственностью «Центр обеспечения»':
        'Общество с ограниченной ответственностью «Центр обеспечения»',
      'Общество с ограниченной ответственностью «ПетроЭнергоКомплект»':
        'Общество с ограниченной ответственностью «ПетроЭнергоКомплект»',
      'Общество с ограниченной ответственностью «ДИАДА»':
        'Общество с ограниченной ответственностью «ДИАДА»',
      'Общество с ограниченной ответственностью «АМД-Электро»':
        'Общество с ограниченной ответственностью «АМД-Электро»',
      'Общество с ограниченной ответственностью «Диада-Электро»':
        'Общество с ограниченной ответственностью «Диада-Электро»',
      'Акционерное общество «Москабелькомплект»':
        'Акционерное общество «Москабелькомплект»',
      'Общество с ограниченной ответственностью «Квазар»':
        'Общество с ограниченной ответственностью «Квазар»',
      'Общество с ограниченной ответственностью «СОМЭС-групп»':
        'Общество с ограниченной ответственностью «СОМЭС-групп»',
      'ТОО «Agro Olymp Logistic»':
        'ТОО «Agro Olymp Logistic»',        
      'Дилеры ОАО «Беларускабель»': 'Дилеры ОАО «Беларускабель»',
      'В Российской Федерации:': 'В Российской Федерации:',
      'В Республике Казахстан:': 'В Республике Казахстан:',
    },
  },
  en: {
    translation: {
      'Юридический адрес': 'Legal address',
      'Генеральный директор': 'CEO',
      'Заголовок Телефоны': 'Phones',
      'Электронная почта': 'Email',
      'Адрес сайта': 'Website address',
      'Россия, 150044, Ярославская обл, г. Ярославль, ул. Полушкина роща, д. 16, строение 46а, помещение 4':
        'Russia, 150044, Yaroslavl region, Yaroslavl, st. Polushkina grove, 16, building 46a, room 4',
      'Россия, 192102, Санкт-Петербург г, вн. тер. г. муниципальный округ Волковское, пр-кт Волковский, д. 146, к. 1, литера А, помещ. 2Н, офис 26':
        'Russia, 192102, St. Petersburg, ext. ter. Volkovskoye municipal district, Volkovskiy avenue, 146, building 1, letter A, premises 2N, office 26',
      'Россия, 109341 г. Москва, ул. Люблинская, д. 151, помещение 316/2':
        'Russia, 109341 Moscow, st. Lublinskaya, 151, room 316/2',
      'Россия, 115409, г. Москва, Каширское шоссе, д. 33, корп. 6 эт. 2 пом. 1 ком 24':
        'Russia, 115409, Moscow, Kashirskoe shosse, 33, bldg. 6 floor 2 rooms 1 room 24',
      'Российская Федерация, 109428, г. Москва, Рязанский пр-т, д.10, стр. 18, помещ. 11/8, офис 8.3/805':
        'Российская Федерация, 109428, г. Москва, Рязанский пр-т, д.10, стр. 18, помещ. 11/8, офис 8.3/805',
      'Российская Федерация, 119034, г. Москва, Лопухинский переулок д.5, стр.4, каб.2, эт.2':
        'Российская Федерация, 119034, г. Москва, Лопухинский переулок д.5, стр.4, каб.2, эт.2',
      'Российская Федерация, 115088, г. Москва, Южнопортовая улица, дом 7, стр. 12':
        'Российская Федерация, 115088, г. Москва, Южнопортовая улица, дом 7, стр. 12', 
      'Российская Федерация, 614500, Пермский край, р-н Пермский, д. Хмели, ш. Космонавтов, 320А':
        'Российская Федерация, 614500, Пермский край, р-н Пермский, д. Хмели, ш. Космонавтов, 320А',  
      'Республика Казахстан, г. Астана, ул. Желтоксан, 48/1, оф. 28':
        'Республика Казахстан, г. Астана, ул. Желтоксан, 48/1, оф. 28',                                        
      'Незговоров Денис Геннадьевич': 'Nezgovorov Denis Gennadievich',
      'Васильев Андрей Александрович': 'Vasiliev Andrey Alexandrovich',
      'Топоров Игорь Валерьевич': 'Toporov Igor Valerievich',
      'Топорова Ирина Викторовна': 'Топорова Ирина Викторовна',
      'Сулягин Дмитрий Александрович': 'Sulyagin Dmitry Alexandrovich',
      'Колосов Владимир Борисович': 'Колосов Владимир Борисович',
      'Юнев Александр Александрович': 'Юнев Александр Александрович',
      'Кузьмин Сергей Андреевич': 'Кузьмин Сергей Андреевич',
      'Ергалиев Ерлан Алшинович': 'Ергалиев Ерлан Алшинович',
      'Общество с ограниченной ответственностью «Центр обеспечения»':
        'Limited Liability Company "Provision Center"',
      'Общество с ограниченной ответственностью «ПетроЭнергоКомплект»':
        'PetroEnergoKomplekt Limited Liability Company',
      'Общество с ограниченной ответственностью «ДИАДА»': 'Limited Liability Company "DIADA"',
      'Общество с ограниченной ответственностью «АМД-Электро»':
        'AMD-Electro Limited Liability Company',
      'Общество с ограниченной ответственностью «Диада-Электро»':
        'Limited Liability Company «DIADA-Elektro»',
      'Акционерное общество «Москабелькомплект»':
        'Joint-Stock Company «Moskabelkomplekt»',  
      'Общество с ограниченной ответственностью «Квазар»':
        'Общество с ограниченной ответственностью «Квазар»',
      'Общество с ограниченной ответственностью «СОМЭС-групп»':
        'Limited Liability Company «SOMES-group»',  
      'ТОО «Agro Olymp Logistic»':
        'LLP «Agro Olymp Logistic»',                                          
      'Дилеры ОАО «Беларускабель»': 'Dealers of JSC "Belaruskabel"',
      'В Российской Федерации:': 'In Russian Federation:',
      'В Республике Казахстан:': 'In the Republic of Kazakhstan:',
    },
  },
  bel: {
    translation: {
      'Юридический адрес': 'Юрыдычны адрас',
      'Генеральный директор': 'Генеральны дырэктар',
      'Заголовок Телефоны': 'Тэлефоны',
      'Электронная почта': 'Электронная пошта',
      'Адрес сайта': 'Адрас сайта',
      'Россия, 150044, Ярославская обл, г. Ярославль, ул. Полушкина роща, д. 16, строение 46а, помещение 4':
        'Расія, 150044, Яраслаўская вобл, г. Яраслаўль, вул. Полушкіна гай, д. 16, будынак 46а, памяшканне 4',
      'Россия, 192102, Санкт-Петербург г, вн. тер. г. муниципальный округ Волковское, пр-кт Волковский, д. 146, к. 1, литера А, помещ. 2Н, офис 26':
        'Расія, 192102, Санкт-Пецярбург г, вн. цёр. г. муніцыпальная акруга Волкаўскае, пр-кт Валкоўскі, д. 146, к. 1, літара А, памяшч. 2Н, офіс 26',
      'Россия, 109341 г. Москва, ул. Люблинская, д. 151, помещение 316/2':
        'Расія, 109341 г. Масква, вул. Люблінская, д. 151, памяшканне 316/2',
      'Россия, 115409, г. Москва, Каширское шоссе, д. 33, корп. 6 эт. 2 пом. 1 ком 24':
        'Расія, 115409, г. Масква, Кашырская шаша, д. 33, корп. 6 эт. 2 пам. 1 ком 24',
      'Российская Федерация, 109428, г. Москва, Рязанский пр-т, д.10, стр. 18, помещ. 11/8, офис 8.3/805':
        'Российская Федерация, 109428, г. Москва, Рязанский пр-т, д.10, стр. 18, помещ. 11/8, офис 8.3/805',
      'Российская Федерация, 119034, г. Москва, Лопухинский переулок д.5, стр.4, каб.2, эт.2':
        'Российская Федерация, 119034, г. Москва, Лопухинский переулок д.5, стр.4, каб.2, эт.2',
      'Российская Федерация, 115088, г. Москва, Южнопортовая улица, дом 7, стр. 12':
        'Российская Федерация, 115088, г. Москва, Южнопортовая улица, дом 7, стр. 12',    
      'Российская Федерация, 614500, Пермский край, р-н Пермский, д. Хмели, ш. Космонавтов, 320А':
        'Российская Федерация, 614500, Пермский край, р-н Пермский, д. Хмели, ш. Космонавтов, 320А',   
      'Республика Казахстан, г. Астана, ул. Желтоксан, 48/1, оф. 28':
        'Республика Казахстан, г. Астана, ул. Желтоксан, 48/1, оф. 28',                                   
      'Незговоров Денис Геннадьевич': 'Нязмоваў Дзяніс Генадзевіч',
      'Васильев Андрей Александрович': 'Васільеў Андрэй Аляксандравіч',
      'Топоров Игорь Валерьевич': 'Сякёр Ігар Валерьевіч',
      'Топорова Ирина Викторовна': 'Топорова Ирина Викторовна',
      'Сулягин Дмитрий Александрович': 'Сулягін Дзмітрый Аляксандравіч',
      'Колосов Владимир Борисович': 'Колосов Владимир Борисович',
      'Юнев Александр Александрович': 'Юнев Александр Александрович',
      'Кузьмин Сергей Андреевич': 'Кузьмин Сергей Андреевич',
      'Ергалиев Ерлан Алшинович': 'Ергалиев Ерлан Алшинович',
      'Общество с ограниченной ответственностью «Центр обеспечения»':
        'Таварыства з абмежаванай адказнасцю «Цэнтр забеспячэння»',
      'Общество с ограниченной ответственностью «ПетроЭнергоКомплект»':
        'Таварыства з абмежаванай адказнасцю «ПетраЭнергаКамплект»',
      'Общество с ограниченной ответственностью «ДИАДА»':
        'Таварыства з абмежаванай адказнасцю «ДЫЯДА»',
      'Общество с ограниченной ответственностью «АМД-Электро»':
        'Таварыства з абмежаванай адказнасцю «АМД-Электра»',
      'Общество с ограниченной ответственностью «Диада-Электро»':
        'Общество с ограниченной ответственностью «Диада-Электро»',   
      'Акционерное общество «Москабелькомплект»':
        'Акционерное общество «Москабелькомплект»', 
      'Общество с ограниченной ответственностью «Квазар»':
        'Общество с ограниченной ответственностью «Квазар»',
      'Общество с ограниченной ответственностью «СОМЭС-групп»':
        'Общество с ограниченной ответственностью «СОМЭС-групп»', 
      'ТОО «Agro Olymp Logistic»':
        'ТОО «Agro Olymp Logistic»',                                    
      'Дилеры ОАО «Беларускабель»': 'Дылеры ААТ "Беларускабель"',
      'В Российской Федерации:': 'У Расійскай Федэрацыі:',
      'В Республике Казахстан:': 'У Рэспубліцы Казахстан:',      
    },
  },
}
