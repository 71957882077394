import React from 'react'
import {Link} from 'react-router-dom'
import mapPin from '../../../media/main/map-pin.svg'
import clock from '../../../media/main/clock.svg'
import phone from '../../../media/main/phone.svg'
import s from './Header.module.scss'

const MobileHeader = ({isActive, setIsActive, changeLanguage, activeLanguage}) => {
  return (
    <div className={`${s.mobileMenu} ${isActive && s.active}`}>
      <nav>
        <ul className={s.menuMobile}>
          <li>
            <Link to="/shop/catalogue">Каталог</Link>
          </li>
          <li>
            <Link to="/shop/actions">Товары по акции</Link>
          </li>
          <li>
            <Link to="/shop/bestsellers">Лидеры продаж</Link>
          </li>
          <li>
            <Link to="/shop/payment">Оплата и доставка</Link>
          </li>
          <li>
            <Link to="/shop/rassrochka">Рассрочка</Link>
          </li>
          <li>
            <Link to="/shop/about">О нас</Link>
          </li>
        </ul>
      </nav>
      <div className={s.itemWrapper}>
        <div className={s.item}>
          <img className={s.img} src={mapPin} alt="map pin" />
          <div className={s.wrapper}>
            <p className={s.preTitle}>Наш адрес:</p>
            <p className={s.title}>г. Мозырь, ул. Октябрьская, 14.</p>
          </div>
        </div>

        <div className={s.item}>
          <img className={s.img} src={clock} alt="map pin" />
          <div className={s.wrapper}>
            <p className={s.preTitle}>График работы:</p>
            <p className={s.title}>С 8:00 до 22:00</p>
          </div>
        </div>

        <div className={s.item}>
          <img className={s.img} src={phone} alt="map pin" />
          <div className={s.wrapper}>
            <p className={s.title}>
              <a href="tel::80913458818" target="_blank">
                +7 (091) 345 88-18
              </a>
            </p>
            <p className={s.title}>
              <a href="tel::80913458818" target="_blank">
                +7 (091) 345 88-18
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className={`${s.secondItem} ${s.mobile}`}>
        {/* <button className={s.button}>Оставить заявку</button> */}
        <div
          className={`${!activeLanguage ? s.left : activeLanguage === 1 ? s.center : s.right} ${
            s.changeLanguage
          }`}
        >
          <span
            onClick={() => {
              changeLanguage(0)
            }}
          >
            <div>РУС</div>
          </span>
          <span
            onClick={() => {
              changeLanguage(1)
            }}
          >
            <div>ENG</div>
          </span>
          <span
            onClick={() => {
              changeLanguage(2)
            }}
          >
            <div>БЕЛ</div>
          </span>
        </div>
      </div>

      <button
        className={`${s.hamburgerLines} ${s.active} ${s.modify}`}
        onClick={() => setIsActive(!isActive)}
      >
        <span className={`${s.line} ${s.line1}`}></span>
        <span className={`${s.line} ${s.line2}`}></span>
        <span className={`${s.line} ${s.line3}`}></span>
      </button>
    </div>
  )
}

export default MobileHeader
