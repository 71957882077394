import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";

// all routes
import Admin from "./components/Admin/Admin";
import MainHomePage from "./componentsMain/PublicPage/HomePage/HomePage";
import Topic from "./componentsMain/TopicPage/TopicPage/Topic";
import Backet from "./componentsMain/BacketPage/Backet/Backet";
import Offer from "./componentsMain/OfferPage/Offer/Offer";
import Product from "./componentsMain/ProductPage/Product";
import Payment from "./componentsMain/Payment/Payment";
import About from "./componentsMain/About/About";
import Actions from "./componentsMain/TopicPage/Actions/Actions";
import Bestsellers from "./componentsMain/TopicPage/Bestsellers/Bestsellers";
import { selfInfo } from "./store/async/asyncAuth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
//import {loadAllItems, loadMainItems} from './store/reducers/ShoppingReducer'
import ErrorPage from "./ui/ErrorPage/ErrorPage";
import AuthPage from "./componentsMain/AuthPage/Auth";
import Registration from "./componentsMain/RegistrationPage/Registration";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import HomePage from "./components/HomePage/HomePage";
import ProductPage from "./components/ProductPage/ProductPage";
import CardPage from "./components/CardPage/CardPage";
import Calculator from "./components/Calculator/Calculator";
import HistoryPage from "./components/HistoryPage/HistoryPage";
import Management from "./components/Management/Management";
import Production from "./components/Production/Production";
import QualityPolicy from "./components/QualityPolicy/QualityPolicy";
import Documentation from "./components/Documentation/Documentation";
import Certificates from "./components/Certificates/Certificates";
import PurchasePlace from "./components/PurchasePlace/PurchasePlace";
import Projects from "./components/Projects/Projects";
import Clients from "./components/Clients/Clients";
import PressCenter from "./components/PressCenter/PressCenter";
import PressCard from "./components/PressCard/PressCard";
import Contacts from "./components/Contacts/Contacts";
import Awards from "./components/Awards/Awards";
import IndustryPurpose from "./components/IndustryPurpose/IndustryPurpose";
import Wires from "./components/Wires/Wires";
import InnovativeProduction from "./components/InnovativeProduction/InnovativeProduction";
import Testing from "./components/Testing/Testing";
import SchedulePage from "./components/Schedule/Schedule";
import NewsPage from "./components/News/News";
import VacanciesPage from "./components/VacanciesPage";
import PhotoGalleryPage from "./components/Photogallery/Photogallery";
import ThemeGallery from "./components/ThemeGalleryPage/ThemeGallery";
import ImportPage from "./components/ImportPage/Import";
import AgainsCorruption from "./components/AgainstCorruption/AgainstCorruption";
import ForInvestorsPage from "./componentsMain/ForInvestorsPage";
import BacketOfficial from "./components/BacketPage/Backet/Backet";
import OfferOfficial from "./components/OfferPage/Offer/Offer";
import AdminProcedure from "./components/AdminProcedurePage/AdminProcedure";
import Layout from "./Layout";
import Layout2 from "./Layout2";
import i18next from "i18next";
import Dillers from "./components/Dillers/Dillers";
import NewPage from "./components/NewPage/NewPage";
import Rassrochka from "./componentsMain/Rassrochka/Rassrochka";
import DigitalCatalogPage from './components/DigitalCatalogPage'

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const jwt_token = localStorage.getItem("jwt_token");
    if (jwt_token) {
      dispatch(selfInfo({ jwt_token }));
    }

    // dispatch(loadAllItems(JSON.parse(localStorage.getItem("setAllItems"))));
    // dispatch(loadMainItems(JSON.parse(localStorage.getItem("setBacketItems"))));
  }, []);

  return (
    <BrowserRouter>
      <div id="containerWrapper">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route element={<HomePage />} path="" />
            <Route element={<ProductPage />} path="products" />
            <Route element={<ProductPage />} path="products/*" />
            <Route element={<CardPage />} path="products/:id" />
            <Route element={<Calculator />} path="calc" />
            <Route element={<HistoryPage />} path="history" />
            <Route element={<Management />} path="management" />
            <Route element={<Production />} path="production" />
            <Route element={<QualityPolicy />} path="qualitypolicy" />
            <Route element={<Documentation />} path="documentation" />
            <Route element={<Certificates />} path="certificates" />
            <Route element={<PurchasePlace />} path="places" />
            <Route element={<Projects />} path="projects" />
            <Route element={<Clients />} path="clients" />
            <Route element={<Dillers />} path="dillers" />
            <Route element={<PressCenter />} path="presscenter" />
            <Route element={<PressCard />} path="presscenter/:id" />
            <Route element={<Contacts />} path="contacts" />
            <Route element={<Awards />} path="awards" />
            <Route element={<BacketOfficial />} path="backet" />
            <Route element={<InnovativeProduction />} path="innovative" />
            <Route element={<Testing />} path="testing" />
            <Route element={<SchedulePage />} path="schedule" />
            <Route element={<NewsPage />} path="smi-about-us" />
            <Route element={<NewPage />} path="smi-about-us/:id" />
            <Route element={<VacanciesPage />} path="vacancies" />
            <Route element={<PhotoGalleryPage />} path="photogallery" />
            <Route element={<ThemeGallery />} path="photogallery/:slug" />
            <Route element={<ImportPage />} path="import" />
            <Route
              element={<AdminProcedure />}
              path="administration-procedures"
            />
            <Route element={<AgainsCorruption />} path="against-corruption" />
            <Route element={<ForInvestorsPage />} path="for-investors" />
            <Route element={<OfferOfficial />} path="order" />
            <Route element={<DigitalCatalogPage />} path="digital-catalog" />
          </Route>

          <Route path="shop" element={<Layout2 />}>
            <Route element={<MainHomePage />} path="" />
            <Route element={<Topic />} path="catalogue" />
            <Route element={<Backet />} path="backet" />
            <Route element={<Offer />} path="order" />
            <Route element={<Product />} path="product/:id" />
            <Route element={<Payment />} path="payment" />
            <Route element={<Rassrochka />} path="rassrochka" />
            <Route element={<AuthPage />} path="auth" />
            <Route element={<Registration />} path="registration" />
            <Route element={<About />} path="about" />
            <Route element={<Actions />} path="actions" />
            <Route element={<Bestsellers />} path="bestsellers" />
          </Route>

          <Route element={<Admin />} path="/admin" />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
