import React from 'react'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import InputBlock from '../InputBlock/InputBlock'
import Production from '../Production/Production'
import s from './OrderBody.module.scss'
import {useTranslation} from 'react-i18next'

const OrderBody = props => {
  const [isActive, setIsActive] = useState(false)
  const {t} = useTranslation()

  return (
    <div className={s.block}>
      <h1 className={s.title}>{t('Оформление заказа')}</h1>

      <div className={s.inputBlock}>
        <InputBlock />
        <Production />
        <span className={s.link}>
          <Link to="/?showOffer">{t('Оформить заказ')}</Link>
        </span>

        <p className={s.apply} onClick={() => setIsActive(!isActive)}>
          <span>
            <input type="checkbox" checked={isActive} onChange={() => console.clear()} />
          </span>
          <span className={isActive ? s.active : ''}></span>
          <span className={s.margin}>
            {t('Я выражаю согласие на')}{' '}
            <span>
              <Link to="/">{t('передачу и обработку персональных данных')}</Link>
            </span>{' '}
            {t('в соответствии с')}{' '}
            <span>
              <Link to="/">{t('Пользовательским соглашением')}</Link>
            </span>
            . <br /> {t('Отправляя заказ, Вы соглашаетесь с')}{' '}
            <span>
              <Link to="/">
                {t('Правилами приобретения продукции в интернет-магазине ОАО “Беларускабель”')}
              </Link>
            </span>
          </span>
        </p>
      </div>
    </div>
  )
}

export default OrderBody
