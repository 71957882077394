import React, {useState} from 'react'
import s from './InputBlock.module.scss'
import mark from '../../../media/main/order/exMark.svg'
import {Link} from 'react-router-dom'

const InputBlock = props => {
  const [name, setName] = useState('')
  const [tel, setTel] = useState('')
  const [address, setAddress] = useState('')
  const [email, setEmail] = useState('')



  return (
    
    <div className={s.flex}>
      <div className={s.flexItem}>
        <input
          className={s.input}
          type="text"
          name="fio"
          placeholder="ФИО*"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <input
          className={s.input}
          type="text"
          placeholder="Номер телефона*"
          name="phone"
          value={tel}
          onChange={e => setTel(e.target.value)}
        />
        <input
          className={s.input}
          type="text"
          placeholder="Адрес"
          name="address"
          value={address}
          onChange={e => setAddress(e.target.value)}
        />
        <input
          className={s.input}
          type="text"
          placeholder="E-mail*"
          name="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      {/*<div className={s.flexItem}>
        <img src={mark} alt="Замечание!" className={s.image} />
        <p className={s.paragraph}>
          Вы не сможете отслеживать быстрый заказ в личном кабинете. Для отслеживания заказа Вам
          необходимо{' '}
          <span>
            <Link to="/register">зарегистрироваться</Link>
          </span>
          , либо, если Вы уже зарегистрированы, авторизоваться и сделать заказ в личном кабинете.
        </p>
      </div>*/}
    </div>

  )
}

export default InputBlock
