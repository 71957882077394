import {Row, Col, Image, Breadcrumb} from 'react-bootstrap'
import './HistoryPage.css'
import {Link} from 'react-router-dom'
import {useEffect} from 'react'
import scrollIntoView from 'scroll-into-view'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import {useTranslation} from 'react-i18next'

export default function HistoryPage() {
  const {t} = useTranslation()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])
  return (
    <>
      <div className="history-page-container">
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Компания')}</Link>
          </span>
          <Breadcrumb.Item active>{t('Меню История')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t('Меню История')}</div>
        <div className="history-page-info">
          <Row className="history-page-row hpr-1">
            <Col>
              <Row className="hpr-1-row">
                <Col className="hpr-image-section">
                  <Image className="history-page-images" src="1.1.png" alt="1.1.png" />
                </Col>
                <Col className="hpr-image-section">
                  <Image className="history-page-images" src="1.2.png" alt="1.2.png" />
                </Col>
              </Row>
              <Row>
                <div
                  className="history-page-text"
                  dangerouslySetInnerHTML={{__html: `${t('Предприятие ОАО')}`}}
                ></div>
              </Row>
            </Col>
            <Col className="hpr-image-section">
              <Image className="history-page-images" src="2.png" alt="2.png" />
            </Col>
          </Row>
          <Row className="history-page-row hpr-2">
            <Col className="hpr-image-section">
              <Image className="history-page-images" src="3.png" alt="3.png" />
            </Col>
            <Col>
              <div
                className="history-page-text"
                dangerouslySetInnerHTML={{
                  __html: `${t('За годы деятельности ОАО «Беларускабель»')}`,
                }}
              ></div>
            </Col>
          </Row>
          <Row className="history-page-row hpr-3">
            <Col className="hpr-image-section">
              <Image className="history-page-images" src="4.1.png" alt="4.1.png" />
            </Col>
            <Col className="hpr-image-section">
              <Image className="history-page-images" src="4.2.png" alt="4.2.png" />
            </Col>
          </Row>
          <Row className="history-page-row hpr-4">
            <Col>
              <div
                className="history-page-text"
                dangerouslySetInnerHTML={{
                  __html: `${t('В настоящее время')}`,
                }}
              ></div>
            </Col>
            <Col className="hpr-image-section">
              <Image className="history-page-images" src="5.png" alt="5.png" />
            </Col>
          </Row>
          <Row className="history-page-row hpr-5">
            <Col className="hpr-image-section">
              <Image className="history-page-images" src="6.1.png" alt="6.1.png" />
            </Col>
            <Col className="hpr-image-section">
              <Image className="history-page-images" src="6.2.png" alt="6.2.png" />
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
