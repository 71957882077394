import {Image, NavLink} from 'react-bootstrap'
import './LogoLinks.css'
import {useTranslation} from 'react-i18next'

export default function LogoLinks() {
  const {t} = useTranslation()

  return (
    <>
      <div className="logo-links-container">
        <div>
          <div className={'logo-link-wrapper'}>
            <NavLink className="logo-links" href="https://president.gov.by/ru">
              <Image src="herb1.png" alt="herb1.jpg" />
              <br />
              <br />
              {t('Интернет-портал Президента Республики Беларусь')}
            </NavLink>
          </div>
          <div className={'logo-link-wrapper'}>
            <NavLink className="logo-links" href="https://pravo.by/">
              <Image src="herb1.png" alt="herb1.jpg" />
              <br />
              <br />
              {t('Национальный правовой интернет-портал Республики Беларусь')}
            </NavLink>
          </div>
          <div className={'logo-link-wrapper'}>
            <NavLink className="logo-links" href="http://www.mozyrisp.gov.by/ru">
              <Image src="herb2.png" alt="herb1.jpg" />
              <br />
              <br />
              {t('Мозырский районный исполнительный комитет')}
            </NavLink>
          </div>
          <div className={'logo-link-wrapper'}>
            <NavLink className="logo-links" href="https://minprom.gov.by/">
              <Image src="herb1.png" alt="herb1.jpg" />
              <br />
              <br />
              {t('Министерство промышленности Республики Беларусь')}
            </NavLink>
          </div>
        </div>
      </div>
      <hr></hr>
    </>
  )
}
