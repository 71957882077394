import React, {useState} from 'react'
import s from './Payment.module.scss'
import cube from '../../../media/main/order/cube.svg'
import car from '../../../media/main/order/car.svg'

const Payment = props => {
  const [activeMethod, setActiveMethod] = useState(1)
  const [activeDelivery, setActiveDelivery] = useState(1)
  const [activeTypeDelivery, setActiveTypeDelivery] = useState(3)

  const initialMethods = [
    {id: 1, title: 'Оплата наличными при получении', prefix: 'give'},
    {id: 2, title: 'Оплата банковской картой или картой рассрочки в магазине', prefix: 'card'},
    {id: 3, title: 'Оплата через ЕРИП', prefix: 'erip'},
  ]

  const initialDelivery = [
    {id: 1, title: 'Самовывоз', img: cube, prefix: 'pickup', extra: []},
    {
      id: 2,
      title: 'Доставка',
      img: car,
      prefix: 'delivery',
      extra: [
        {id: 3, title: 'Белпочта (доставка до отделения, подробности уточняйте у менеджера)', prefix: 'pochta'},
        {id: 4, title: 'Европочта (доставка до отделения, подробности уточняйте у менеджера) ', prefix: 'euro'},
      ],
    },
    {id: 5, title: 'Курьером', img: cube, prefix: 'courier', extra: []},
  ]

  return (
    <div className={s.flex}>
      <div className={s.flexItem}>
        <p className={s.title}>Выбор способа оплаты</p>

        {initialMethods.map(method => (
          <div key={method.id} onClick={() => setActiveMethod(method.id)} className={s.inputRadio}>
            <input
              type="radio"
              id={method.prefix}
              name="method"
              value={method.title}
              className={s.input}
              checked={method.id === activeMethod ? true : false}
            />
            <span></span>
            <label for={method.prefix} className={s.label}>
              {method.title}
            </label>
          </div>
        ))}

        <p className={s.paragraph}>
          После оформления заказа, с вами свяжется менеджер для подтверждения и выставит счёт.
          Оплатить можно только картами белорусских банков
        </p>
      </div>
      <div className={s.flexItem}>
        <p className={s.title}>Способ получения заказа</p>

        {initialDelivery.map(method => (
          <div>
            <div
              key={method.id}
              onClick={() => setActiveDelivery(method.id)}
              className={s.inputRadio}
            >
              <input
                type="radio"
                id={method.prefix}
                name="delivery"
                value={method.title}
                className={s.input}
                checked={method.id === activeDelivery ? true : false}
              />
              <span></span>
              <label for={method.prefix} className={s.label}>
                {method.title}
              </label>
            </div>
            {method.id === activeDelivery ||
            method.extra.filter(extra => extra.id === activeDelivery).length ? (
              <div className={s.extraTypes}>
                {method.extra.length
                  ? method.extra.map(extra => (
                      <div
                        key={extra.id}
                        onClick={() => setActiveTypeDelivery(extra.id)}
                        className={s.inputRadio}
                      >
                        <input
                          type="radio"
                          id={extra.prefix}
                          name="extra-delivery"
                          value="1"
                          className={s.input}
                          checked={extra.id === activeTypeDelivery ? true : false}
                        />
                        <span></span>
                        <label for={extra.prefix} className={s.label}>
                          {extra.title}
                        </label>
                      </div>
                    ))
                  : ''}
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Payment
