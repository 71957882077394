import React, {useState} from 'react'
import s from './IndustryForm.module.scss'
import Button from '../../ui/Button/Button'
import {useDispatch} from 'react-redux'
import {setCategory, setIndustries} from '../../../../store/async/asyncAdmin'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {getIndustries} from '../../../../store/async/asyncCategories'

const schema = yup
  .object({
    industry: yup.string(),
  })
  .required()

const IndustryForm = ({jwt, part}) => {
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const onSubmit = data => {
    dispatch(setIndustries({name: data.industry, entity: !part ? 'legal' : 'physical', jwt})).then(
      () => dispatch(getIndustries())
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
      <input {...register('industry')} className={`${s.input} ${s.last}`} placeholder="Отрасль" />
      {errors.industry && (
        <p>
          <span className={s.error}>{errors.industry?.message}</span>
        </p>
      )}

      <Button text="Добавить" />
    </form>
  )
}

export default IndustryForm
