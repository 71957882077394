export const work = {
  ru: {
    translation: {
      "наладчик контрольно-измерительных приборов и автоматики 5 разряда": `<p className={s.vacancy} style="margin-top: 24px" >
      <u>«ОАО «Беларускабель» приглашает на постоянную работу:</u>
          <ul style="padding: 0">          
            <li>- опрессовщиков кабелей и проводов пластикатами и резиной (мужчина, возможно обучение на производстве);</li>
            <li>- волочильщика проволоки (мужчина, возможно обучение на производстве);</li>
            <li>- оплетчика проводов и кабелей (женщина, возможно обучение на производстве);</li>         
            <li>- скрутчиков изделий кабельного производства (мужчина, возможно обучение на производстве);</li>        
            <li>- слесаря-инструментальщика 4-5 разряда;</li>       
            <li>-  электромонтера по ремонту и обслуживанию электрооборудования 4-5 разряда;</li>         
            <li>- контролера кабельных изделий (возможно обучение на производстве);</li>            
            <li>- повара.</li>
          </ul>
          </p>

          <div>
            <b>Обращаться по адресу:</b>
            <p>г. Мозырь, ул. Октябрьская, 14.</p>
            <b>Контактные телефоны:</b>
            <p>тел. 372566, 240803, 8(029)3702405, 8(029)1108162</p>
          </div>
          <div>
            <b>Работа в ОАО "БЕЛАРУСКАБЕЛЬ" это:</b>
            <p style="width: 100%; max-width: 670px;">хорошие условия труда, полный соцпакет, возможность обучения и карьерного роста, стабильная заработная плата и все социальные гарантии в соответствии с ТК РБ.<br/> 
            Работа в дружном коллективе. Наличие соц. сферы.</p>
          </div>`,
    },
  },
  en: {
    translation: {
      "наладчик контрольно-измерительных приборов и автоматики 5 разряда": `<p className={s.vacancy} style="margin-top: 24px" >
      <u>«ОАО «Беларускабель» приглашает на постоянную работу:</u>
          <ul style="padding: 0">          
            <li>- опрессовщиков кабелей и проводов пластикатами и резиной (мужчина, возможно обучение на производстве);</li>
            <li>- волочильщика проволоки (мужчина, возможно обучение на производстве);</li>
            <li>- оплетчика проводов и кабелей (женщина, возможно обучение на производстве);</li>         
            <li>- скрутчиков изделий кабельного производства (мужчина, возможно обучение на производстве);</li>        
            <li>- слесаря-инструментальщика 4-5 разряда;</li>       
            <li>-  электромонтера по ремонту и обслуживанию электрооборудования 4-5 разряда;</li>         
            <li>- контролера кабельных изделий (возможно обучение на производстве);</li>            
            <li>- повара.</li>
          </ul>
          </p>

          <div>
            <b>Обращаться по адресу:</b>
            <p>г. Мозырь, ул. Октябрьская, 14.</p>
            <b>Контактные телефоны:</b>
            <p>тел. 372566, 240803, 8(029)3702405, 8(029)1108162</p>
          </div>
          <div>
            <b>Работа в ОАО "БЕЛАРУСКАБЕЛЬ" это:</b>
            <p style="width: 100%; max-width: 670px;">хорошие условия труда, полный соцпакет, возможность обучения и карьерного роста, стабильная заработная плата и все социальные гарантии в соответствии с ТК РБ.<br/> 
            Работа в дружном коллективе. Наличие соц. сферы.</p>
          </div>`,
    },
  },
  bel: {
    translation: {
      "наладчик контрольно-измерительных приборов и автоматики 5 разряда": `<p className={s.vacancy} style="margin-top: 24px" >
      <u>«ОАО «Беларускабель» приглашает на постоянную работу:</u>
          <ul style="padding: 0">          
            <li>- опрессовщиков кабелей и проводов пластикатами и резиной (мужчина, возможно обучение на производстве);</li>
            <li>- волочильщика проволоки (мужчина, возможно обучение на производстве);</li>
            <li>- оплетчика проводов и кабелей (женщина, возможно обучение на производстве);</li>         
            <li>- скрутчиков изделий кабельного производства (мужчина, возможно обучение на производстве);</li>        
            <li>- слесаря-инструментальщика 4-5 разряда;</li>       
            <li>-  электромонтера по ремонту и обслуживанию электрооборудования 4-5 разряда;</li>         
            <li>- контролера кабельных изделий (возможно обучение на производстве);</li>            
            <li>- повара.</li>
          </ul>
          </p>

          <div>
            <b>Обращаться по адресу:</b>
            <p>г. Мозырь, ул. Октябрьская, 14.</p>
            <b>Контактные телефоны:</b>
            <p>тел. 372566, 240803, 8(029)3702405, 8(029)1108162</p>
          </div>
          <div>
            <b>Работа в ОАО "БЕЛАРУСКАБЕЛЬ" это:</b>
            <p style="width: 100%; max-width: 670px;">хорошие условия труда, полный соцпакет, возможность обучения и карьерного роста, стабильная заработная плата и все социальные гарантии в соответствии с ТК РБ.<br/> 
            Работа в дружном коллективе. Наличие соц. сферы.</p>
          </div>`,
    },
  },
};
