import React from 'react'
import s from './Pagination.module.scss'

const PaginationModule = ({links, setActivePage, activeLinks, activePage, computeActiveLinks}) => {
  return (
    <div className={s.pagination}>
      {links.length - 1 === activePage && (
        <div
          className={`${s.paginationLastItem} ${s.reverse}`}
          onClick={() => {
            setActivePage(0)
            computeActiveLinks(0)
          }}
        ></div>
      )}

      {activeLinks.map((link, idx) => (
        <div
          key={link.href}
          className={`${s.paginationItem} ${link.text - 1 === activePage ? s.active : ''}`}
          onClick={() => {
            setActivePage(link.text - 1)
            computeActiveLinks(link.text - 1)
          }}
        >
          {link.text}
        </div>
      ))}

      {links.length - 1 !== activePage && (
        <div
          className={`${s.paginationLastItem}`}
          onClick={() => {
            setActivePage(links.length - 1)
            computeActiveLinks(links.length - 1)
          }}
        ></div>
      )}
    </div>
  )
}

export default PaginationModule
