import React, {useEffect, useState} from 'react'
import s from './Rassrochka.module.scss'
import car from '../../media/main/car.jpg'
import scrollIntoView from 'scroll-into-view'
import {useOutletContext} from 'react-router-dom'

const Rassrochka = props => {
  const [activeLanguage, setActiveLanguage] = useState(0)
  const {setBreadCrumb} = useOutletContext()

  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })

    setBreadCrumb([
      {
        id: 1,
        title: 'Главная',
        href: '/shop',
      },
      {
        id: 2,
        title: 'Рассрочка',
      },
    ])
  }, [])

  return (
    <div>
      <div className={s.body}>
        <div className={s.container}>
          <p className={s.paragraph}>В фирменном магазине «Беларускабель» вы сможете приобрести продукцию по карте рассрочки следующих банков:</p>

          <table className={s.table}>
            <tr>
              <th>Банк</th>
              <th>Вид карты</th>
              <th>Срок рассрочки</th>
            </tr>
            <tr>
              <td>МТБанк</td>
              <td>«Халва MIX» и «Халва MAX»</td>
              <td>2 месяца</td>
            </tr>
            <tr>
              <td>Беларусбанк</td>
              <td>«Карта Магнит»</td>
              <td>4 месяца</td>
            </tr>
            <tr>
              <td>Банк ВТБ Беларусь</td>
              <td>Карта «Черепаха»</td>
              <td>8 месяцев</td>
            </tr>
            <tr>
              <td>Приорбанк</td>
              <td>«Карточка для ежедневных покупок»</td>
              <td>6 месяцев</td>
            </tr>
            <tr>
              <td>Сбер Банк</td>
              <td>«Карта FUN»</td>
              <td>3 месяца</td>
            </tr>
            <tr>
              <td>Банк Дабрабыт</td>
              <td>«СМАРТ КАРТА»</td>
              <td>3 месяца</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Rassrochka
