export const production = {
  ru: {
    translation: {
      'Уже более 60 лет ОАО': `Уже более 60 лет ОАО "Беларускабель" – является ведущим кабельным предприятием в Республике Беларусь,`,
      'которое изготавливает и поставляет кабельную продукцию':
        'которое изготавливает и поставляет кабельную продукцию для отраслей и сфер потребления - таких как радиоэлектроника, приборостроение, связь, авиационная техника, строительство, телекоммуникация и электротехника.',
      'Сегодня - это современное, универсальное предприятие, располагающее оборудованием для производства': `Сегодня - это современное, универсальное предприятие, располагающее
                    оборудованием для производства монтажных терморадиационностойких и теплостойких
                    проводов, радиочастотных кабелей, силовых и контрольных кабелей без брони и в
                    броне, кабелей управления, кабелей контроля и передачи данных, кабелей
                    радиофикации и связи, проводов установочных и проводов различного специального
                    назначения с жилой из медной и алюминиевой проволоки, огнестойкие кабеля и
                    провода с индексом FRLS, FRHF.`,
      'Мы единственная организация в Республике Беларусь специализирующаяся на выпуске импортозамещающей кабельно-проводниковой продукции': `Мы единственная организация в Республике Беларусь специализирующаяся на выпуске
                    импортозамещающей кабельно-проводниковой продукции и предоставляющая услуги по
                    технической приемке продукции. На ОАО «Беларускабель» дислоцируется Техническая
                    приемка №43 АО «РТ-Техприемка» специализированная организация Госкорпорации
                    «Ростех» (ранее АО «Авиатехприемка») по оценке соответствия продукции в форме
                    контроля качества и технической приемке, согласно Положению от 22.10.2007 «О
                    порядке проведения контроля качества материалов и полуфабрикатов, используемых в
                    производстве вооружения, военной, авиационной и специальной техники». ОАО
                    «Беларускабель» занимается разработкой и постановкой на серийное производство
                    кабельных изделий для предприятий нефтегазовой, энергетической, строительной;
                    телекоммуникационной; радиотехнической; атомной АЭС; автотракторной;
                    железнодорожной; авиационной отраслей, а также предприятий военно-промышленного
                    комплекса.`,
      'производственными мощностями и площадями, высококвалифицированными кадрами': `
                <span>Предприятие ОАО «Беларускабель» обладает достаточным потенциалом:</span>{' '}
                производственными мощностями и площадями, высококвалифицированными кадрами, как ИТР,
                так и рабочих. С самого начала функционирования, завод имеет имидж надежного
                партнера и качественного производителя. Наша организация имеет зарегистрированный в
                Западной Европе товарный знак, и внесен в перечень деловых партнеров`,
    },
  },
  en: {
    translation: {
      'Уже более 60 лет ОАО': `For more than 60 years Belaruskabel JSC has been the leading cable enterprise in the Republic of Belarus, `,
      'которое изготавливает и поставляет кабельную продукцию':
      'which manufactures and supplies cable products for industries and consumer areas, such as radio electronics, instrumentation, communications, aviation technology, construction, telecommunications, electrical engineering, oil and gas, energy, radio engineering; nuclear power plant; automotive industry; railway industry, as well as military- industrial complex enterprises.',
      'Сегодня - это современное, универсальное предприятие, располагающее оборудованием для производства': `Today it is a modern, universal enterprise equipped 
      for production of mounting heat-and-radiation resistant and heat-resistant wires, 
      radiofrequency cables, power and control cables, armoured and unarmoured, control and data cables, 
      radio and communication cables, installation wires and various special purpose wires with copper and aluminium core, 
      fire-resistant cables and wires with FRLS, FRHF index.`,
      'Мы единственная организация в Республике Беларусь специализирующаяся на выпуске импортозамещающей кабельно-проводниковой продукции': 
      `We are the only organisation within the Republic of Belarus specialising in the production of import-substituting cable and wire 
      products and providing technical acceptance services.`,
      'производственными мощностями и площадями, высококвалифицированными кадрами': `
                <span>Belaruskabel JSC has sufficient potential: </span>
                production facilities and premises, highly qualified personnel, 
                both engineering manpower and labourers. From the very beginning of its functioning, 
                the plant has the image of a reliable partner and quality manufacturer. 
                Our organisation has a trademark registered in Western Europe and is included in the list of business partners.`,
    },
  },
  bel: {
    translation: {
      'Уже более 60 лет ОАО': `Ужо больш за 60 гадоў ААТ "Беларускабель" - з'яўляецца вядучым кабельным прадпрыемствам у Рэспубліцы Беларусь,`,
      'которое изготавливает и поставляет кабельную продукцию':
        'якое вырабляе і пастаўляе кабельную прадукцыю для галін і сфер спажывання - такіх як радыёэлектроніка, прыборабудаванне, сувязь, авіяцыйная тэхніка, будаўніцтва, тэлекамунікацыя і электратэхніка.',
      'Сегодня - это современное, универсальное предприятие, располагающее оборудованием для производства': `Сёння - гэта сучаснае, універсальнае прадпрыемства, якое выклікае прыхільнасць
                    абсталяваннем для вытворчасці мантажных тэрмарадыяцыйнастойкіх і цеплаўстойлівых
                    правадоў, радыёчастотных кабеляў, сілавых і кантрольных кабеляў без броні і ў
                    брані, кабеляў кіравання, кабеляў кантролю і перадачы даных, кабеляў
                    радыёфікацыі і сувязі, правадоў установачных і правадоў рознага спецыяльнага
                    прызначэння з жылой з меднага і алюмініевага дроту, вогнеўстойлівыя кабеля і
                    правады з індэксам FRLS, FRHF.`,
      'Мы единственная организация в Республике Беларусь специализирующаяся на выпуске импортозамещающей кабельно-проводниковой продукции': `Мы адзіная арганізацыя ў Рэспубліцы Беларусь, якая спецыялізуецца на выпуску
                    імпартазамяшчальнай кабельна-правадніковай прадукцыі і якая прадстаўляе паслугі па
                    тэхнічнай прыёмцы прадукцыі. На ААТ "Беларускабель" дыслакуецца Тэхнічная
                    прыёмка №43 АТ "РТ-Техприемка" спецыялізаваная арганізацыя Дзяржкарпарацыі
                    "Ростех" (раней АТ "Авіятэхпрыёмка") па ацэнцы адпаведнасці прадукцыі ў форме
                    кантролю якасці і тэхнічнай прыёмцы, згодна з Палажэннем ад 22.10.2007 «Аб
                    парадку правядзення кантролю якасці матэрыялаў і паўфабрыкатаў, якія выкарыстоўваюцца ў
                    вытворчасці ўзбраення, ваеннай, авіяцыйнай і спецыяльнай тэхнікі». ААТ
                    "Беларускабель" займаецца распрацоўкай і пастаноўкай на серыйную вытворчасць
                    кабельных вырабаў для прадпрыемстваў нафтагазавай, энергетычнай, будаўнічай;
                    тэлекамунікацыйнай; радыётэхнічнай; атамнай АЭС; аўтатрактарнай;
                    чыгуначнай; авіяцыйнай галін, а таксама прадпрыемстваў ваенна-прамысловага
                    комплексу.`,
      'производственными мощностями и площадями, высококвалифицированными кадрами': `
                <span>Прадпрыемства ААТ «Беларускабель» валодае дастатковым патэнцыялам:</span>{''}
                 вытворчымі магутнасцямі і плошчамі, высокакваліфікаванымі кадрамі, як ІТР,
                 так і працоўных. З самага пачатку функцыянавання, завод мае імідж надзейнага
                 партнёра і якаснага вытворцы. Наша арганізацыя мае зарэгістраваны ў
                 Заходняй Еўропе таварны знак, і ўнесены ў пералік дзелавых партнёраў`,
    },
  },
}
