import React, {useState} from 'react'
import s from './InputBlock.module.scss'
import mark from '../../../media/main/order/exMarkRed.svg'
import {Link} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const InputBlock = props => {
  const {authenticated} = useSelector(store => store.auth)
  const {t} = useTranslation()

  const schema = yup
    .object({
      name: yup.string(),
      tel: yup
        .string()
        .matches(phoneRegExp, `${t('Неправильный номер телефона')}`)
        .required(`${t('Необходимо ввести номер телефона')}`),
      company: yup.string(),
      email: yup
        .string()
        .email(`${t('Неправильный email')}`)
        .required(`${t('Необходимо ввести email')}`),
    })
    .required()

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const onSubmit = data => {
    console.log(data)
  }

  return (
    <div>
      {authenticated ? (
        <div className={s.flexWrapper}>
          <form className={s.flexItem} onSubmit={handleSubmit(onSubmit)}>
            <input
              className={s.input}
              type="text"
              placeholder={t('Оформление ФИО')}
              {...register('name')}
            />
            <input
              className={s.input}
              type="text"
              placeholder={t('Номер телефона')}
              {...register('tel')}
            />
            <input
              className={s.input}
              type="text"
              placeholder={t('Наименование организации')}
              {...register('company')}
            />
            <input className={s.input} type="text" placeholder="E-mail" {...register('email')} />
          </form>
        </div>
      ) : (
        <div className={s.flex}>
          <form className={s.flexItem} onSubmit={handleSubmit(onSubmit)}>
            <input
              className={s.input}
              type="text"
              placeholder={t('Оформление ФИО')}
              {...register('name')}
            />
            <input
              className={s.input}
              type="text"
              placeholder={t('Номер телефона')}
              {...register('tel')}
            />
            <input
              className={s.input}
              type="text"
              placeholder={t('Наименование организации')}
              {...register('company')}
            />
            <input className={s.input} type="text" placeholder="E-mail" {...register('email')} />
          </form>
          <div className={s.flexItem}>
            <img src={mark} alt="Замечание!" className={s.image} />
            <p className={s.paragraph}>
              {t(
                'Вы не сможете отслеживать быстрый заказ в личном кабинете. Для отслеживания заказа Вам необходимо'
              )}{' '}
              <span>
                <Link to="/register">{t('кнопка Зарегистрироваться')}</Link>
              </span>
              {t(
                ', либо, если Вы уже зарегистрированы, авторизоваться и сделать заказ в личном кабинете.'
              )}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default InputBlock
