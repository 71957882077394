import {Swiper, SwiperSlide, useSwiper} from 'swiper/react/swiper-react.js'
import {Navigation} from 'swiper'
import CustomCarousel from './Carousel/CustomCarousel'
import FirstBanner from './FirstBanner/FirstBanner'
import IndustriesList from './IndustriesList/IndustriesList'
import ProductCarousel from './ProductCarousel/ProductCarousel'
import SecondBanner from './SecondBanner/SecondBanner'
import Certificates from './Сertificates/Certificates'
import LogoLinks from './LogoLinks/LogoLinks'
import './HomePage.css'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import {useEffect, useState} from 'react'
import scrollIntoView from 'scroll-into-view'
import Advantages from './Advantages/Advantages'
import Map from './Map/Map'
import Banner from './Banner/Banner'
import NewProduction from './NewProduction/NewProduction'
import Search from '../../ui/Search/Search'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'
import Offer from './Offer/Offer'

export default function HomePage() {
  const {t} = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [enableOffer, setEnableOffer] = useState(false)
  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })

    if (searchParams.get('showOffer') !== null) {
      setEnableOffer(true)

      setTimeout(() => {
        setSearchParams({})
        setEnableOffer(false)
      }, 5000)
    }
  }, [])

  const [activeSlide, setActiveSlide] = useState(-1)

  const SwiperButtonNext = ({children}) => {
    const swiper = useSwiper()
    return (
      <div
        className={`swiper-button-next inside ${
          swiper.activeIndex === 0 && 'swiper-button-disabled'
        }`}
        style={{display: 'none'}}
        onClick={() => swiper.slideNext()}
      ></div>
    )
  }

  const SwiperButtonPrev = ({children}) => {
    const swiper = useSwiper()
    return (
      <div
        className={`swiper-button-prev inside ${
          swiper.activeIndex === 0 && 'swiper-button-disabled'
        }`}
        style={{display: 'none'}}
        onClick={() => swiper.slidePrev()}
      ></div>
    )
  }

  return (
    <>
      <div className="HomePage">
        <CustomCarousel />
        <Advantages />
        <Search />
        <ProductCarousel />
        <FirstBanner />
        <IndustriesList />
        <Banner />
        <NewProduction />
        <Map />
        <Certificates />
        <SecondBanner />
        <LogoLinks />
        <div className="trust-carousel-contaier">
          <div className="trust-label">{t('Нам доверяют')}</div>
          <Swiper
            spaceBetween={20}
            slidesPerView={5}
            modules={[Navigation]}
            onSlideChange={slide => {
              if (slide.activeIndex === 0) {
                setActiveSlide(-1)
              } else if (slide.isEnd) {
                setActiveSlide(1)
              } else {
                setActiveSlide(0)
              }
            }}
            navigation={true}
            breakpoints={{
              1200: {
                slidesPerView: 4,
                spaceBetween: 20,
              },

              700: {
                slidesPerView: 3,
              },

              500: {
                slidesPerView: 2,
              },

              100: {
                slidesPerView: 1,
              },
            }}
          >
            <SwiperSlide>
              <img src={'/clients/trust3.png'} className="image" alt="placeholder" />
            </SwiperSlide> 
            <SwiperSlide>
              <img src={'/clients/rosneft.png'} className="image" alt="placeholder" />
            </SwiperSlide>  
            <SwiperSlide>
              <img src={'/clients/novatek.png'} className="image" alt="placeholder" />
            </SwiperSlide>   
            <SwiperSlide>
              <img src={'/clients/trust4.png'} className="image" alt="placeholder" />
            </SwiperSlide>  
            <SwiperSlide>
              <img src={'/clients/trust5.png'} className="image" alt="placeholder" />
            </SwiperSlide>  
            <SwiperSlide>
              <img src={'/clients/client09.png'} className="image" alt="placeholder" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={'/clients/client06.png'} className="image" alt="placeholder" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={'/clients/trust6.png'} className="image" alt="placeholder" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={'/clients/client03.png'} className="image" alt="placeholder" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={'/clients/trust1.png'} className="image modify" alt="placeholder" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={'/clients/trust2.png'} className="image modify" alt="placeholder" />
            </SwiperSlide>
            <SwiperButtonNext></SwiperButtonNext>
            <SwiperButtonPrev></SwiperButtonPrev>
          </Swiper>
          <div className={'swiper-arrows'}>
            <div
              className={`swiper-button-prev ${activeSlide === -1 && 'swiper-button-disabled'}`}
              onClick={() => document.querySelector('.swiper-button-prev.inside').click()}
            ></div>
            <div
              className={`swiper-button-next ${activeSlide === 1 && 'swiper-button-disabled'}`}
              onClick={() => document.querySelector('.swiper-button-next.inside').click()}
            ></div>
          </div>
        </div>
        {enableOffer && <Offer enableOffer={enableOffer} setEnableOffer={setEnableOffer} />}
      </div>
    </>
  )
}
