import {createAsyncThunk} from '@reduxjs/toolkit'
import {https} from '../../config'

export const cabelSearch = createAsyncThunk('search/cabel', async info => {
    return await https
        .get('/ru/products/search?search=' + info)
        .then(response => {
            return response
        })
})

export const publicSearch = createAsyncThunk('products/cabel', async info => {
    return await https
        .get('/ru/products/search?search=' + info)
        .then(response => {
            return response
        })
})