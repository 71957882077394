import call from "../media/main/telephone-svgrepo-com.svg";
import place from "../media/main/place-point-svgrepo-com.svg";

export const contacts = {
  ru: {
    translation: {
      "ОАО «БЕЛАРУСКАБЕЛЬ»": "ОАО «БЕЛАРУСКАБЕЛЬ»",
      "Офис контакты": `<div style="margin-top: 30px;">
                  <p>
                    <span style="color: #343753; font-weight: 700; font-size: 24px">
                      Офис
                    </span>
                  </p>
                  <p>
                   <span class='svg__call-icon'><img src="${place}"/></span>
                    Республика Беларусь, г. Мозырь, 247760, ул. Октябрьская 14 <br/><br/> <span class='svg__call-icon'><img src="${call}"/></span> +375 (236) 37-25-75
                  </p>
                </div>
                <div style="margin-top: 50px;">
                  <p>
                    <span style="color: #343753; font-weight: 700; font-size: 24px">
                      Приёмная
                    </span>
                  </p>
                  <p>
                    <span style="color: black; font-weight: 700; font-size: 18px">
                    Мартинович Богдана Руслановна
                    </span>
                  </p>
                  <p><span class='svg__call-icon'><img src="${call}"/></span> +375 (236) 24-77-29 (приемная)</p>
                  <p><span class='svg__call-icon'><img src="${call}"/></span> +375 (236) 25-42-80 (факс)</p>
                  <p style="margin-top: 30px;">
                    <span style="font-weight: 700; color: #343753">Email:</span>
                    <span style="font-weight: 700; font-size: 18px"> vc@belaruskabel.by</span>
                  </p>
                  <p>
                    Возникли какие-то вопросы, не можете решить проблему или просто хотите
                    поделиться мнением о нас — можете написать нам.
                  </p>
                </div>
                <div style="margin-top: 30px;">
                  <p>
                    <span style="color: #343753; font-weight: 700; font-size: 24px">
                      Время работы
                    </span>
                  </p>
                  <p>Пн – Чт: 08:00–17:00, обед 12:45–13:30</p>
                  <p>Пт: 08:00–15:45, обед 12:45–13:30</p>
                  <p>Сб, Вс: выходной</p>
                </div>
                <div style="margin-top: 30px;">
                  <p>
                    <span style="color: #343753; font-weight: 700; font-size: 24px">
                      Фирменный магазин
                    </span>
                  </p>
                  <p>
                    <p>Время работы: </p>
                  </p>
                  <p>Вторник-Пятница c 9:00 до 18:00 (обед 14:30-15:30)</p>
                  <p>Суббота c 9:00 до 15:00</p>
                  <p>Воскресенье, Понедельник - выходной</p>
                  <p><a href="tel:+375236372575">+375 (236) 37-25-75</a></p>
                  <p><a href="tel:+375291114093">+375 (29) 111-40-93</a></p>
                  <p><a href="mailto:shop.belaruskabel@gmail.com">shop.belaruskabel@gmail.com</a></p>

                </div>`,
      "Написать нам": "Написать нам",
      'Руководство': "РУКОВОДСТВО",
      Директор: "Директор",
      "ОТДЕЛ ПРОДАЖ": "ОТДЕЛ ПРОДАЖ",
      "Заместитель начальника отдела продаж":
        "Заместитель начальника отдела продаж",
      "Сигай Татьяна Сергеевна": "Сигай Татьяна Сергеевна",
      "Специалист по продаже": "Специалист по продаже",
      "Черданцева Наталья Вячеславовна": "Черданцева Наталья Вячеславовна",
      "(группа кабелей КИПиА)": "(группа кабелей КИПиА)",
      "Падалинский Андрей Леонидович": "Падалинский Андрей Леонидович",
      "(авиамонтажная группа)": "(авиамонтажная группа)",
      "Колос Ирина Валентиновна": "Колос Ирина Валентиновна",
      "(автотракторная группа и шнуры)": "(автотракторная группа и шнуры)",
      "Ющенко Татьяна Владимировна": "Ющенко Татьяна Владимировна",
      "(группа кабелей КИПиА)": "(группа кабелей КИПиА)",
      "Ляшенко Светлана Николаевна": "Ляшенко Светлана Николаевна",
      "(группа силовой и контрольный кабель)":
        "(группа силовой и контрольный кабель)",
      "Дульская Татьяна Михайловна": "Дульская Татьяна Михайловна",
      "(авиамонтажная группа)": "(авиамонтажная группа)",
      "Гончаревич Наталья Владимировна": "Гончаревич Наталья Владимировна",
      "(по кабелю связи и сигнально-блокировочная группа)":
        "(по кабелю связи и сигнально-блокировочная группа)",
      "Кадол Ольга Александровна": "Кадол Ольга Александровна",
      "(группа кабелей КИПиА)": "(группа кабелей КИПиА)",
      "Щипер Нина Михайловна": "Щипер Нина Михайловна",
      "(группа силовой и контрольный кабель)":
        "(группа силовой и контрольный кабель)",
      "Северина Наталья Сергеевна": "Северина Наталья Сергеевна",
      "(авиамонтажная группа и шнуры)": "(авиамонтажная группа и шнуры)",
      "ОТДЕЛ МАТЕРИАЛЬНО-ТЕХНИЧЕСКОГО СНАБЖЕНИЯ":
        "ОТДЕЛ МАТЕРИАЛЬНО-ТЕХНИЧЕСКОГО СНАБЖЕНИЯ",
      "Начальник отдела": "Начальник отдела",
      "Гололобов Юрий Сергеевич": "Гололобов Юрий Сергеевич",
      "+375 (236) 25-41-59 (факс)": "+375 (236) 25-41-59 (факс)",
      "Группа химии": "Группа химии",
      "Леоненко Нина Григорьевна": "Леоненко Нина Григорьевна",
      "Группа комплектации": "Группа комплектации",
      "Бородина Татьяна Анатольевна": "Бородина Татьяна Анатольевна",
      "Заместитель начальника": "Заместитель начальника",
      "Ивахно Тамара Яковлевна": "Ивахно Тамара Яковлевна",
      "Группа металлов": "Группа металлов",
      "Тимошков Алексей Владимирович": "Тимошков Алексей Владимирович",
      "ОТДЕЛ КАДРОВ": "ОТДЕЛ КАДРОВ",
      "Начальник отдела кадров": "Начальник отдела кадров",
      "Шилова Татьяна Анатольевна": "Шилова Татьяна Анатольевна",
      "Слово Общий": "Общий",
      "Щербин Светлана Ивановна": "Щербин Светлана Ивановна",
      "БЮРО МАРКЕТИНГА": "БЮРО МАРКЕТИНГА",
      "Начальник бюро маркетинга": "Начальник бюро маркетинга",
      "Мартинович Роман Валерьевич": "Мартинович Роман Валерьевич",
      "ЦЕНТРАЛЬНАЯ ЗАВОДСКАЯ ЛАБОРАТОРИЯ": "ЦЕНТРАЛЬНАЯ ЗАВОДСКАЯ ЛАБОРАТОРИЯ",
      "Начальник отдела": "Начальник отдела",
      "Шульга Алексей Васильевич": "Шульга Алексей Васильевич",
      "Слово КАНЦЕЛЯРИЯ": "КАНЦЕЛЯРИЯ",
      "Начальник отдела": "Начальник отдела",
      "Дубинина Людмила Сергеевна": "Дубинина Людмила Сергеевна",
      "Слово ПРОФКОМ": "ПРОФКОМ",
      "Начальник отдела": "Начальник отдела",
      "Председатель профкома": "Председатель профкома",
      "Чурило Николай Леонидович": "Чурило Николай Леонидович",
      "ВЫШЕСТОЯЩАЯ ОРГАНИЗАЦИЯ": "ВЫШЕСТОЯЩАЯ ОРГАНИЗАЦИЯ",
      "МИНИСТЕРСТВО ПРОМЫШЛЕННОСТИ РЕСПУБЛИКИ БЕЛАРУСЬ":
        "МИНИСТЕРСТВО ПРОМЫШЛЕННОСТИ РЕСПУБЛИКИ БЕЛАРУСЬ",
      "Адрес: Партизанский проспект, 2, корп. 4. 220033, Республика Беларусь, г. Минск":
        "Адрес: Партизанский проспект, 2, корп. 4. 220033, Республика Беларусь, г. Минск",
      "тел.: +375 (17) 223-93-96": "тел.: +375 (17) 223-93-96",
      "Канцелярия: +375 (17) 223-64-96": "Канцелярия: +375 (17) 223-64-96",
      "факс: +375 (17) 328-30-48": "факс: +375 (17) 328-30-48",
      "web-сайт: www.minprom.gov.by": "web-сайт: www.minprom.gov.by",
      "Режим работы: Ежедневно, кроме субботы и воскресенья с 9.00. до 18.00, обед с 13.00 до 14.00":
        "Режим работы: Ежедневно, кроме субботы и воскресенья с 9.00. до 18.00, обед с 13.00 до 14.00",
      "Кнопка Читать": "Читать",
    },
  },
  en: {
    translation: {
      "ОАО «БЕЛАРУСКАБЕЛЬ»": "JSC «BELARUSKABEL»",
      "Кнопка Читать": "Read more",
      "Офис контакты": `<div style="margin-top: 30px;">
        <p>
          <span style="color: #343753; font-weight: 700; font-size: 24px">
            Office
          </span>
        </p>
        <p>
        <span class='svg__call-icon'><img src="${place}"/></span>
        Republic of Belarus, Mozyr, 247760, Oktyabrskaya str.,14<br/><br/> <span class='svg__call-icon'><img src="${call}"/></span> +375 (236) 37-25-75
        </p>
      </div>
      <div style="margin-top: 50px;">
        <p>
          <span style="color: #343753; font-weight: 700; font-size: 24px">
            Reception
          </span>
        </p>
        <p>
          <span style="color: black; font-weight: 700; font-size: 18px">
              Martsinovich Bohdana Ruslanovna
          </span>
        </p>
        <p><span class='svg__call-icon'><img src="${call}"/></span> +375 (236) 24-77-29 (reception)</p>
        <p><span class='svg__call-icon'><img src="${call}"/></span> +375 (236) 25-42-80 (fax)</p>
        <p style="margin-top: 30px;">
          <span style="font-weight: 700; color: #343753">Email:</span>
          <span style="font-weight: 700; font-size: 18px"> vc@belaruskabel.by</span>
        </p>
        <p>
          Have some questions, can't solve the problem or just want to share your opinion about us — you can write us.
        </p>
      </div>
      <div style="margin-top: 30px;">
        <p>
          <span style="color: #343753; font-weight: 700; font-size: 24px">
          Working hours
          </span>
        </p>
        <p>Mon - Thu: 08:00 - 17:00, break 12:45 - 13:30</p>
        <p>Fri: 08:00-15:45, break 12:45-13:30</p>
        <p>Sat, Sun: day off</p>
      </div>
      <div style="margin-top: 30px;">
        <p>
          <span style="color: #343753; font-weight: 700; font-size: 24px">
            Brand store
          </span>
        </p>
        <p>
          <p>Opening hours: </p>
        </p>
        <p>Tuesday-Friday from 9:00 to 18:00 (break 14:30-15:30)</p>
        <p>Saturday from 9:00 to 15:00</p>
        <p>Sunday, Monday - day off</p>
        <p><a href="tel:+375236372575">+375 (236) 37-25-75</a></p>
        <p><a href="tel:+375291114093">+375 (29) 111-40-93</a></p>
        <p><a href="mailto:shop.belaruskabel@gmail.com">shop.belaruskabel@gmail.com</a></p>

      </div>`,


      "Написать нам": "Write to us",
      "Слово ДИРЕКЦИЯ": "Word DIRECTION",
      "Главный Директор": "Chief Director",
      "ОТДЕЛ ПРОДАЖ": "SALES DEPARTMENT",
      "Заместитель начальника отдела продаж": "Deputy Head of Sales Department",
      "Сигай Татьяна Сергеевна": "Sigay Tatyana Sergeevna",
      "Специалист по продаже": "Sales Specialist",
      "Черданцева Наталья Вячеславовна": "Cherdantseva Natalya Vyacheslavovna",
      "(группа кабелей КИПиА)": "(KIPiA cable group)",
      "Падалинский Андрей Леонидович": "Padalinsky Andrey Leonidovich",
      "(авиамонтажная группа)": "(aviation assembly group)",
      "Колос Ирина Валентиновна": "Kolos Irina Valentinovna",
      "(автотракторная группа и шнуры)": "(autotractor group and cords)",
      "Ющенко Татьяна Владимировна": "Yushchenko Tatyana Vladimirovna",
      "(группа кабелей КИПиА)": "(KIPiA cable group)",
      "Ляшенко Светлана Николаевна": "Lyashenko Svetlana Nikolaevna",
      "(группа силовой и контрольный кабель)":
        "(group power and control cable)",
      "Дульская Татьяна Михайловна": "Dulskaya Tatyana Mikhailovna",
      "(авиамонтажная группа)": "(aviation assembly group)",
      "Гончаревич Наталья Владимировна": "Goncharevich Natalya Vladimirovna",
      "(по кабелю связи и сигнально-блокировочная группа)":
        "(by communication cable and signal-blocking group)",
      "Кадол Ольга Александровна": "Kadol Olga Alexandrovna",
      "(группа кабелей КИПиА)": "(KIPiA cable group)",
      "Щипер Нина Михайловна": "Schipper Nina Mikhailovna",
      "(группа силовой и контрольный кабель)":
        "(group power and control cable)",
      "Северина Наталья Сергеевна": "Severina Natalya Sergeevna",
      "(авиамонтажная группа и шнуры)": "(aviation group and cords)",
      "ОТДЕЛ МАТЕРИАЛЬНО-ТЕХНИЧЕСКОГО СНАБЖЕНИЯ":
        "DEPARTMENT OF MATERIAL AND TECHNICAL SUPPLY",
      "Начальник отдела": "Head of Department",
      "Гололобов Юрий Сергеевич": "Gololobov Yury Sergeevich",
      "+375 (236) 25-41-59 (факс)": "+375 (236) 25-41-59 (fax)",
      "Группа химии": "Chemistry group",
      "Леоненко Нина Григорьевна": "Leonenko Nina Grigorievna",
      "Группа комплектации": "Procurement Department",
      "Бородина Татьяна Анатольевна": "Borodina Tatyana Anatolyevna",
      "Заместитель начальника": "Deputy Chief",
      "Ивахно Тамара Яковлевна": "Ivakhno Tamara Yakovlevna",
      "Группа металлов": "Metal group",
      "Тимошков Алексей Владимирович": "Timoshkov Alexey Vladimirovich",
      "ОТДЕЛ КАДРОВ": "HUMAN RESOURCES DEPARTMENT",
      "Начальник отдела кадров": "Head of Department",
      "Шилова Татьяна Анатольевна": "Shilova Tatyana Anatolyevna",
      "Слово Общий": "Specialist",
      "Щербин Светлана Ивановна": "Shcherbin Svetlana Ivanovna",
      "БЮРО МАРКЕТИНГА": "BUREAU OF MARKETING",
      "Начальник бюро маркетинга": "Head of Marketing Bureau",
      "Мартинович Роман Валерьевич": "Martinovich Roman Valerievich",
      "ЦЕНТРАЛЬНАЯ ЗАВОДСКАЯ ЛАБОРАТОРИЯ": "CENTRAL PLANT LABORATORY",
      "Начальник отдела": "Head of Department",
      "Шульга Алексей Васильевич": "Shulga Alexey Vasilievich",
      "Слово КАНЦЕЛЯРИЯ": "OFFICE",
      "Начальник отдела": "Head of Department",
      "Дубинина Людмила Сергеевна": "Dubinina Ludmila Sergeevna",
      "Слово ПРОФКОМ": "Trade Union Committee",
      "Начальник отдела": "Head of Department",
      "Председатель профкома": "Chairman of the trade union committee",
      "Чурило Николай Леонидович": "Churilo Nikolay Leonidovich",
      "ВЫШЕСТОЯЩАЯ ОРГАНИЗАЦИЯ": "SUPERIOR ORGANIZATION",
      "МИНИСТЕРСТВО ПРОМЫШЛЕННОСТИ РЕСПУБЛИКИ БЕЛАРУСЬ":
        "MINISTRY OF INDUSTRY OF THE REPUBLIC OF BELARUS",
      "Адрес: Партизанский проспект, 2, корп. 4. 220033, Республика Беларусь, г. Минск":
        "Address: Partizansky Avenue, 2, bldg. 4. 220033, Republic of Belarus, Minsk",
      "тел.: +375 (17) 223-93-96": "tel.: +375 (17) 223-93-96",
      "Канцелярия: +375 (17) 223-64-96": "Office: +375 (17) 223-64-96",
      "факс: +375 (17) 328-30-48": "fax: +375 (17) 328-30-48",
      "web-сайт: www.minprom.gov.by": "website: www.minprom.gov.by",
      "Режим работы: Ежедневно, кроме субботы и воскресенья с 9.00. до 18.00, обед с 13.00 до 14.00":
        "Working hours: Every day, except Saturday and Sunday, from 9.00. to 18.00, break from 13.00 to 14.00",
      "Руководство":"MANAGEMENT CONTACT PHONE NUMBERS",
      "Мартинович Богдана Руслановна": "Martsinovich Bohdana Ruslanovna",
      "Сидоренко Светлана Ивановна": "Sidorenko Svetlana Ivanovna",
      "ОТДЕЛ ТЕХНИЧЕСКОГО КОНТРОЛЯ":"DEPARTMENT OF TECHNICAL CONTROL",
      'Начальник отдела технического контроля':'Head of Technical Control Department',
      'Инженер по качеству':'Quality Engineer',
      'Инженер по стандартизации и сертификации':'Standardization and Certification Engineer',
      'Ведущий инженер по качеству':'Leading Quality Engineer',
      'Трояновская Олеся Александровна':'Troyanovskaya Olesya Alexandrovna',
      'Пинчук Светлана Владимировна':'Pinchuk Svetlana Vladimirovna',
      'Овада Наталья Ивановна':'Ovada Natalya Ivanovna',
      'Барановская Елена Алексеевна':'Baranovskaya Elena Alekseevna',
      'Начальник отдела продаж':'Head of Sales Department',
      'Cпециалисты по продаже':'Sales Department',
      'Специалисты по продаже':'Sales Specialists',
      'Специалисты по таможенному декларированию':'Customs declaration specialists',
      'Жилюк Михаил Александрович':'Zhilyuk Mikhail Alexandrovich',
      '(внешнеэкономическая деятельность, экспорт)':'(foreign economic activity, export)',
      '(продажи Беларусь)':'(sales in Belarus)',
      'Специалисты по маркетингу':'Marketing Specialists',
      'Заместитель директора по развитию':'Deputy Director for Development',
      'Главный экономист':'Chief economist',
      'Юницкая Инна Ивановна':'Yunitskaya Inna Ivanovna',

    },
  },
  bel: {
    translation: {
      "ОАО «БЕЛАРУСКАБЕЛЬ»": "ААТ «БЕЛАРУСКАБЕЛЬ»",
      "Кнопка Читать": "Чытаць",

      "Офис контакты": `<div style="margin-top: 30px;">
        <p>
          <span style="color: #343753; font-weight: 700; font-size: 24px">
            Офіс
          </span>
        </p>
        <p>
        <span class='svg__call-icon'><img src="${place}"/></span>
          Рэспубліка Беларусь, г. Мазыр, 247760, вул. Кастрычніцкая 14<br/><br/> <span class='svg__call-icon'><img src="${call}"/></span> +375 (236) 37-25-75
        </p>
      </div>
      <div style="margin-top: 50px;">
        <p>
          <span style="color: #343753; font-weight: 700; font-size: 24px">
            Прыёмная
          </span>
        </p>
        <p>
          <span style="color: black; font-weight: 700; font-size: 18px">
          Марцiновіч Багдана Русланаўна
          </span>
        </p>
        <p><span class='svg__call-icon'><img src="${call}"/></span> +375 (236) 24-77-29 (прыёмная)</p>
        <p><span class='svg__call-icon'><img src="${call}"/></span> +375 (236) 25-42-80 (факс)</p>
        <p style="margin-top: 30px;">
          <span style="font-weight: 700; color: #343753">Email:</span>
          <span style="font-weight: 700; font-size: 18px"> vc@belaruskabel.by</span>
        </p>
        <p>
          Узніклі нейкія пытанні, не можаце вырашыць праблему ці проста хочаце
          падзяліцца меркаваннем аб нас — можаце напісаць нам.
        </p>
      </div>
      <div style="margin-top: 30px;">
        <p>
          <span style="color: #343753; font-weight: 700; font-size: 24px">
            Час працы
          </span>
        </p>
        <p>Пн – Чц: 08:00–17:00, абед 12:45–13:30</p>
        <p>Пт: 08:00–15:45, абед 12:45–13:30</p>
        <p>Сб, Нд: выхадны дзень</p>
      </div>
      <div style="margin-top: 30px;">
        <p>
          <span style="color: #343753; font-weight: 700; font-size: 24px">
            Фірменны магазін
          </span>
        </p>
        <p>
          <p>Час працы:</p>
        </p>
        <p>Аўторак-пятніца з 9:00 да 18:00 (абед 14:30-15:30)</p>
        <p>Субота з 9:00 да 15:00</p>
        <p>Нядзеля, панядзелак - выхадны дзень</p>
        <p><a href="tel:+375236372575">+375 (236) 37-25-75</a></p>
        <p><a href="tel:+375291114093">+375 (29) 111-40-93</a></p>
        <p><a href="mailto:shop.belaruskabel@gmail.com">shop.belaruskabel@gmail.com</a></p>

      </div>`,

    
      "Написать нам": "Напісаць нам",
      "Слово ДИРЕКЦИЯ": "Слова ДЫРЭКЦЫЯ",
      "Главный Директор": "Галоўны Дырэктар",
      "ОТДЕЛ ПРОДАЖ": "АДДЗЕЛ ПРОДАЖУ",
      "Заместитель начальника отдела продаж": "Намеснік начальніка аддзела продажаў",
      "Сигай Татьяна Сергеевна": "Сігай Таццяна Сяргееўна",
      "Специалист по продаже": "Спецыяліст па продажы",
      "Черданцева Наталья Вячеславовна": "Чарданцава Наталля Вячаславаўна",
      "(группа кабелей КИПиА)": "(група кабеляў КИПиА)",
      "Падалинский Андрей Леонидович": "Падалінскі Андрэй Леанідавіч",
      "(авиамонтажная группа)": "(авіямантажная група)",
      "Колос Ирина Валентиновна": "Колас Ірына Валянцінаўна",
      "(автотракторная группа и шнуры)": "(аўтатрактарная група і шнуры)",
      "Ющенко Татьяна Владимировна": "Юшчанка Таццяна Уладзіміраўна",
      "(группа кабелей КИПиА)": "(група кабеляў КИПиА)",
      "Ляшенко Светлана Николаевна": "Ляшэнка Святлана Мікалаеўна",
      "(группа силовой и контрольный кабель)":
        "(група сілавы і кантрольны кабель)",
      "Дульская Татьяна Михайловна": "Дульская Таццяна Міхайлаўна",
      "(авиамонтажная группа)": "(авіямантажная група)",
      "Гончаревич Наталья Владимировна": "Ганчарэвіч Наталля Уладзіміраўна",
      "(по кабелю связи и сигнально-блокировочная группа)":
        "(па кабелі сувязі і сігнальна-блакіровачная група)",
      "Кадол Ольга Александровна": "Кадол Вольга Аляксандраўна",
      "(группа кабелей КИПиА)": "(група кабеляў КИПиА)",
      "Щипер Нина Михайловна": "Шчыпер Ніна Міхайлаўна",
      "(группа силовой и контрольный кабель)":
        "(група сілавы і кантрольны кабель)",
      "Северина Наталья Сергеевна": "Севярына Наталля Сяргееўна",
      "(авиамонтажная группа и шнуры)": "(авіямантажная група і шнуры)",
      "ОТДЕЛ МАТЕРИАЛЬНО-ТЕХНИЧЕСКОГО СНАБЖЕНИЯ":
        "АДДЗЕЛ МАТЭРЫЯЛЬНА-ТЭХНІЧНАГА ЗАБЕСПЯЧЭННЯ",
      "Начальник отдела": "Начальнік аддзела",
      "Гололобов Юрий Сергеевич": "Галалобаў Юрый Сяргеевіч",
      "+375 (236) 25-41-59 (факс)": "+375 (236) 25-41-59 (факс)",
      "Группа химии": "Група хіміі",
      "Леоненко Нина Григорьевна": "Леаненка Ніна Рыгораўна",
      "Группа комплектации": "Група камплектацыі",
      "Бородина Татьяна Анатольевна": "Барадзіна Таццяна Анатольеўна",
      "Заместитель начальника": "Намеснік начальніка",
      "Ивахно Тамара Яковлевна": "Івахна Тамара Якаўлеўна",
      "Группа металлов": "Група металаў",
      "Тимошков Алексей Владимирович": "Цімошкаў Аляксей Уладзіміравіч",
      "ОТДЕЛ КАДРОВ": "АДДЗЕЛ КАДРАЎ",
      "Начальник отдела кадров": "Начальнік аддзела кадраў",
      "Шилова Татьяна Анатольевна": "Шылава Таццяна Анатольеўна",
      "Слово Общий": "Агульны",
      "Щербин Светлана Ивановна": "Шчарбін Святлана Іванаўна",
      "БЮРО МАРКЕТИНГА": "БЮРО МАРКЕТЫНГА",
      "Начальник бюро маркетинга": "Начальнік бюро маркетынгу",
      "Мартинович Роман Валерьевич": "Марціновіч Раман Валерьевіч",
      "ЦЕНТРАЛЬНАЯ ЗАВОДСКАЯ ЛАБОРАТОРИЯ": "ЦЭНТРАЛЬНАЯ ЗАВОДСКАЯ ЛАБАРАТОРЫЯ",
      "Начальник отдела": "Начальнік аддзела",
      "Шульга Алексей Васильевич": "Шульга Аляксей Васільевіч",
      "Слово КАНЦЕЛЯРИЯ": "Канцэлярыя",
      "Начальник отдела": "Начальнік аддзела",
      "Дубинина Людмила Сергеевна": "Дубініна Людміла Сяргееўна",
      "Слово ПРОФКОМ": "ПРОФКАМ",
      "Начальник отдела": "Начальнік аддзела",
      "Председатель профкома": "Старшыня прафкама",
      "Чурило Николай Леонидович": "Чурыла Мікалай Леанідавіч",
      "ВЫШЕСТОЯЩАЯ ОРГАНИЗАЦИЯ": "ВЫШЭЙШАЯ АРГАНІЗАЦЫЯ",
      "МИНИСТЕРСТВО ПРОМЫШЛЕННОСТИ РЕСПУБЛИКИ БЕЛАРУСЬ":
        "МІНІСТЭРСТВА ПРАМЫСЛОВАСЦІ РЭСПУБЛІКІ БЕЛАРУСЬ",
      "Адрес: Партизанский проспект, 2, корп. 4. 220033, Республика Беларусь, г. Минск":
        "Адрас: Партызанскі праспект, 2, корп. 4. 220033, Рэспубліка Беларусь, г. Мінск",
      "тел.: +375 (17) 223-93-96": "тэл.: +375 (17) 223-93-96",
      "Канцелярия: +375 (17) 223-64-96": "Канцылярыя: +375 (17) 223-64-96",
      "факс: +375 (17) 328-30-48": "факс: +375 (17) 328-30-48",
      "web-сайт: www.minprom.gov.by": "web-сайт: www.minprom.gov.by",
      "Режим работы: Ежедневно, кроме субботы и воскресенья с 9.00. до 18.00, обед с 13.00 до 14.00":
        "Рэжым працы: Штодня, акрамя суботы і нядзелі з 9.00. да 18.00, абед з 13.00 да 14.00",
      'Руководство': 'КІРАЎНІЦТВА',
      'Директор':'Дырэктар',
      'Заместитель директора по развитию': 'Намеснік дырэктара па развіцці',
      'Заместитель директора по снабжению и организации перевозок':'Намеснік дырэктара па забеспячэнні і арганізацыі перавозак',
      'Главный экономист':'Галоўны эканаміст',
      'Юницкая Инна Ивановна':'Юніцкая Іна Іванаўна',
      'Cпециалисты по продаже':'Спецыялісты па продажы',
      '(внешнеэкономическая деятельность, экспорт)':'(знешнеэканамічная дзейнасць, экспарт)',
      '(продажи Беларусь)':'(продажу Беларусь)',
      'Специалисты по таможенному декларированию':'Спецыялісты па мытным дэклараванні',
      'Специалисты по маркетингу':'Спецыялісты па маркетынгу',
      'ОТДЕЛ ТЕХНИЧЕСКОГО КОНТРОЛЯ':'АДДЗЕЛ ТЭХНІЧНАГА КАНТРОЛЮ',
      'Начальник отдела технического контроля':'Начальнік аддзела тэхнічнага кантролю',
      'Трояновская Олеся Александровна':'Траяноўская Алеся Аляксандраўна',
      'Ведущий инженер по качеству':'Вядучы інжынер па якасці',
      'Барановская Елена Алексеевна':'Бараноўская Алена Аляксееўна',
      'Инженер по качеству':'Інжынер па якасці',
      'Пинчук Светлана Владимировна':'Пінчук Святлана Уладзіміраўна',
      'Инженер по стандартизации и сертификации':'Інжынер па стандартызацыі і сертыфікацыі',
      'Овада Наталья Ивановна':'Авада Наталля Іванаўна',
      'Сидоренко Светлана Ивановна':'Сідарэнка Святлана Іванаўна',
      'Специалисты по продаже':'Спецыялісты па продажы',
      'Жилюк Михаил Александрович':'Жылюк Міхаіл Аляксандравіч',
      'Начальник отдела продаж':'Начальнік аддзела продажаў',
      





    },
  },
};
