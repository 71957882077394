import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import s from './News.module.scss';

const NewsBlock = ({ id, title, date, preParagraph, img }) => {
  const navigate = useNavigate();

  return (
    <div
      className={s.newsItem}
      onClick={() => navigate(`/presscenter/${id}`)}
    >
      <img src={img} />
      <div className={s.wrapper}>
        
        <p className={s.title}>{title}</p>
        <p className={s.date}>{date}</p>
        <p className={s.paragraph}>{preParagraph}</p>
      </div>
    </div>
  );
};
export default NewsBlock;
