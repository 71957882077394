import React, {useState} from 'react'
import {useEffect} from 'react'
import s from './Select.module.scss'

const Select = ({values, selectOpen, setSelectOpen, idx}) => {
  const [activeValue, setActiveValue] = useState(values[0])
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (idx !== selectOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }, [selectOpen])

  return (
    <div className={s.select}>
      <p
        className={`${s.activeValue} ${isOpen ? s.clicked : ''}`}
        onClick={() => {
          setSelectOpen(idx)
          setIsOpen(!isOpen)
        }}
      >
        {activeValue}
      </p>
      <div className={`${s.values} ${isOpen ? s.active : ''}`}>
        {values &&
          values.map((value, idx) => (
            <p
              key={idx}
              className={`${s.value} ${activeValue === value ? s.active : ''}`}
              onClick={() => {
                setActiveValue(value)
                setSelectOpen(-1)
                setIsOpen(false)
              }}
            >
              {value}
            </p>
          ))}
      </div>
    </div>
  )
}

export default Select
