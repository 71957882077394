import React from 'react'
import s from './Offer.module.scss'

const Offer = ({enableOffer, setEnableOffer}) => {
  return (
    <div className={s.offer}>
      <div className={s.flex}>
        <span>
          <img
            alt=""
            className={s.icon}
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICB2aWV3Qm94PSIwIDAgNDggNDgiIHdpZHRoPSI0OHB4IiBoZWlnaHQ9IjQ4cHgiPjxwYXRoIGZpbGw9IiM0M0EwNDciIGQ9Ik00MC42IDEyLjFMMTcgMzUuNyA3LjQgMjYuMSA0LjYgMjkgMTcgNDEuMyA0My40IDE0Ljl6Ii8+PC9zdmc+"
          />
        </span>
        <span className={s.paragraph}>
          Ваша заявка принята. Ответ будет отправлен на указанную электронную почту
        </span>
      </div>
    </div>
  )
}

export default Offer
