import { React } from 'react'

import {
    CardImage,
    CardTitle,
    CardsList,
    CatalogCard,
    CatalogLink,
    PageTitle,
    Background,
    Content,
    Wrapper
} from './styled'
import { config } from './config'

export default function DigitalCatalogPage() {
    const { title, catalogs } = config

    return (
        <Wrapper>
            <Background />
            <Content>
                <PageTitle>{ title }</PageTitle>
                <CardsList>
                    {catalogs.map(({ id, image, link, title }) =>
                        <CatalogCard key={id} >
                            <CatalogLink to={link} target="_blank">
                                <CardImage {...image} />
                                <CardTitle>{title}</CardTitle>
                            </CatalogLink>
                        </CatalogCard>
                    )}
                </CardsList>
            </Content>
        </Wrapper>
    )
}

