import React, {useEffect} from 'react'
import {Breadcrumb} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import s from './AdminProcedure.module.scss'
import {useTranslation} from 'react-i18next'

const AdminProcedure = () => {
  const {t} = useTranslation()
  useEffect(() => {
    scrollIntoView(document.body.querySelector('#root'), {
      time: 0,
      align: {
        top: 0.1,
      },
    })
  }, [])

  return (
    <>
      <div className={s.container}>
        <Breadcrumb className="custom-breadcrumb" style={{marginTop: '38px'}}>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Главная')}</Link>
          </span>
          <span className={'breadcrumb-item'} onClick={e => e.preventDefault()}>
            <Link to="/">{t('Меню Компания')}</Link>
          </span>
          <Breadcrumb.Item active>{t('Административные процедуры')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="commonLabels">{t('Административные процедуры')}</div>
        <div className={s.text}>
          <p dangerouslySetInnerHTML={{__html: `${t('Делопроизводитель Дубинина Людмила')}`}}></p>
          <p className={s.schedules}>
            <b
              dangerouslySetInnerHTML={{
                __html: `${t('ПРИЕМ ЗАЯВЛЕНИЙ ОСУЩЕСТВЛЯЕТСЯ ПО РАБОЧИМ ДНЯМ')}`,
              }}
            ></b>
          </p>
          <p
            dangerouslySetInnerHTML={{
              __html: `${t('Основные принципы')}`,
            }}
          ></p>
          <p></p>
          <p
            dangerouslySetInnerHTML={{
              __html: `${t(
                `Административные процедуры, осуществляемые в отношении граждан, юридических лиц и индивидуальных предпринимателей, определяются следующими перечнями`
              )}`,
            }}
          ></p>
          <p></p>
          <p
            dangerouslySetInnerHTML={{
              __html: `${t('перечень административных процедур')}`,
            }}
          ></p>
          <p></p>
          <p
            dangerouslySetInnerHTML={{
              __html: `${t('Президента Республики Беларусь')}`,
            }}
          ></p>
          <p></p>
          <p
            dangerouslySetInnerHTML={{
              __html: `${t('единый перечень административных процедур')}`,
            }}
          ></p>
          <p></p>
          <div
            dangerouslySetInnerHTML={{
              __html: `${t('описание административных процедур')}`,
            }}
          ></div>
        </div>
      </div>
    </>
  )
}

export default AdminProcedure
