import s from "./NewPage.module.scss";
import { Breadcrumb } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import scrollIntoView from "scroll-into-view";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function NewPage() {
  const smi = useSelector((store) => store.smi);
  const params = useParams();
  const { date, img, paragraph, title } = smi.filter(
    (n) => n.id === +params.id
  )[0];
  const { t } = useTranslation();

  useEffect(() => {
    scrollIntoView(document.body.querySelector("#root"), {
      time: 0,
      align: {
        top: 0.1,
      },
    });
  }, []);

  return (
    <>
      <div className="press-center-page-conteiner">
        <Breadcrumb className="custom-breadcrumb" style={{ marginTop: "38px" }}>
          <span
            className={"breadcrumb-item"}
            onClick={(e) => e.preventDefault()}
          >
            <Link to="/">{t("Меню Главная")}</Link>
          </span>
          <span
            className={"breadcrumb-item"}
            onClick={(e) => e.preventDefault()}
          >
            <Link to="/">{t("Меню Компания")}</Link>
          </span>
          <Breadcrumb.Item active>{title}</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <p className={s.title}>{title}</p>
          <p>{paragraph}</p>
        </div>
      </div>
    </>
  );
}
